import React, { Component, Fragment } from "react";
import { withRouter } from 'react-router-dom';

import ReactDatatable from "@ashvin27/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import UserAddModal from "../partials/UserAddModal";
import UserUpdateModal from "../partials/UserUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import keys from "../../actions/config";
import { Modal, Button } from "react-bootstrap/";
import * as moment from "moment";
import { getAdminRevenue } from "../../actions/userActions"
const url = keys.baseUrl;
class Users extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "created_date",
        text: "Date",
        className: "date",
        align: "left",
        sortable: false,
        cell: record => {
          return (
            <Fragment>
              <p>{moment(record.created_date).format('MMMM,Do YYYY, hh:mm A')}</p>
            </Fragment>
          )
        }
      },

      {
        key: "_id",
        text: "ID #",
        className: "userId",
        align: "left",
        sortable: false,
      },
      {
        key: "tradeId",
        text: "Trade ID #",
        className: "userId",
        align: "left",
        sortable: false,
      },

      {
        key: "currency",
        text: "Currency",
        className: "userId",
        align: "left",
        sortable: false,
      },

      {
        key: "amount",
        text: "Price",
        className: "userId",
        align: "left",
        sortable: false,
      },

      {
        key: "fee",
        text: "Fee",
        className: "fee",
        align: "left",
        sortable: false,
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Buyhistory",
      no_data_text: "No Record found!",
      sort: { column: "Created date", order: "desc" },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
      defaultSortAsc: true,
    };

    this.state = {
      currentRecord: {
        records: [],
        responsive: true,
        first_currency: {},
        second_currency: {},
        date: "",
        totalfee: 0,
        page: 1,
        limit: 10,
        count: 0,
        tfeeon: false,
      },
    };
    this.handlePagination = this.handlePagination.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };
    this.getData(reqData);
    // this.getDatacurrency();
    //   console.log(this.state,'statezzzzzz');
  }

  componentWillReceiveProps(nextProps) {
    this.getData();
  }



  handlePagination(index) {
    let reqData = {
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    };
    console.log("reqDatareqDatareqData", reqData);
    this.getData(reqData);
    this.setState({
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    });
  }
  async getData(data) {
    try {
      this.setState({ loader: true });

      const { status, loading, result } = await getAdminRevenue(data);

      this.setState({ loader: loading });
      if (status == "success") {
        console.log("aaaaaaaaaaaaaaaa", result)
        this.setState({ count: result.count, records: result.data });
      }
    } catch (err) { }
  }


  render() {
    const { count } = this.state;
    return (
      <>
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <h3 className="mt-2 text-secondary">P2P - Commission History</h3>
            <div className="modal-body">
              <form
                noValidate
                onSubmit={this.onCurrencyAdd}
                id="add-currency"
              >

              </form>
            </div>

            <div style={{ overflow: "scroll" }}>
              <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                total_record={count}
                dynamic={true}
                onChange={this.handlePagination}
              />
            </div>
          </div>
        </div>
        <ToastContainer />
      </>

    );
  }
}

Users.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
});

export default connect(mapStateToProps)(withRouter(Users));
