// import package
import React, { Component, Fragment } from "react";
import ReactDatatable from '@ashvin27/react-datatable';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment'
// import component
import { withRouter } from 'react-router-dom';
import TradeBotModal from '../partials/TradeBot/TradeBotModal';
import ViewTradeBot from '../partials/TradeBot/ViewTradeBot';
import BotUserModal from '../partials/TradeBot/BotUserModal';

// import action
import { getPairDropdown } from "../../actions/commonActions";
import { botList, removeBot, botOrderCancel } from '../../actions/tradingBot'

// import lib
import { toastAlert } from '../../lib/toastAlert'

class Tradingbot extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "firstCurrencySymbol",
                text: "Pair",
                className: "firstCurrencySymbol",
                align: "left",
                sortable: false,
                width: 200,
                cell: records => {
                    return records.firstCoin + records.secondCoin
                }
            },
            {
                key: "createdAt",
                text: "Created Date",
                className: "secondCurrencySymbol",
                align: "left",
                sortable: false,
                width: 200,
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <p>{moment(record.createdAt).format('MMMM,Do YYYY, hh:mm A')}</p>
                        </Fragment>
                    )
                }
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: false,
                width: 200
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-spot-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.openViewBotModal(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-eye"></i>
                            </button>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.removeRecord(record._id)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-trash"></i>
                            </button>
                            <button
                                className="btn btn-primary btn-sm"
                                title="Cancel orders"
                                onClick={() => this.cancelOrder(record._id)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-close"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Perpetual",
            no_data_text: 'No Contracts found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            tradeBotModal: false,
            viewBotModal: false,
            botUserModal: false,
            pairList: [],
            records: [],
            viewRecord: {}
        };

        this.fetchSpotPair = this.fetchSpotPair.bind(this);
        this.openBotModal = this.openBotModal.bind(this);
        this.closeBotModal = this.closeBotModal.bind(this);
        this.openViewBotModal = this.openViewBotModal.bind(this);
        this.closeViewBotModal = this.closeViewBotModal.bind(this);
        this.fetchBot = this.fetchBot.bind(this);
        this.openBotUrsModal = this.openBotUrsModal.bind(this);
        this.closeBotUrsModal = this.closeBotUrsModal.bind(this);
    }
    async cancelOrder(id) {
        try {

            if (!window.confirm('Are you sure you want to cancel the all open orders ?')) {
                return
            }

            const { status, loading, message } = await botOrderCancel(id);
            if (status == 'success') {
                toastAlert('success', message, 'botOrderCancel')
                this.fetchBot()
            } else {
                toastAlert('error', message, 'botOrderCancel')
            }
        } catch (err) { }
    }

    componentDidMount() {
        this.fetchSpotPair()
        this.fetchBot()
    };

    async fetchSpotPair() {
        try {
            const { status, loading, result } = await getPairDropdown();
            if (status == 'success') {
                this.setState({ 'pairList': result })
            }
        } catch (err) { }
    }

    async fetchBot() {
        try {
            const { status, loading, result } = await botList();
            if (status == 'success') {
                this.setState({ 'records': result })
            }
        } catch (err) { }
    }

    async removeRecord(id) {
        if (window.confirm("Are you sure to delete?") == true) {
            try {
                const { status, loading, message } = await removeBot(id);
                if (status == 'success') {
                    toastAlert('success', message, 'removeRecord')
                    this.fetchBot()
                } else {
                    toastAlert('error', message, 'removeRecord')
                }
            } catch (err) { }
        }
    }

    openBotModal() {
        this.setState({ tradeBotModal: true });
    }

    closeBotModal() {
        this.setState({ tradeBotModal: false })
    }

    openViewBotModal(record) {
        this.setState({ viewBotModal: true, viewRecord: record })
    }

    closeViewBotModal() {
        this.setState({ viewBotModal: false, viewRecord: {} })
    }

    openBotUrsModal() {
        this.setState({ botUserModal: true })
    }

    closeBotUrsModal() {
        this.setState({ botUserModal: false })
    }


    render() {
        const { tradeBotModal, pairList, records, viewRecord, viewBotModal, botUserModal } = this.state;

        return (
            <>

                <TradeBotModal
                    isShow={tradeBotModal}
                    pairList={pairList}
                    refetchPair={this.fetchSpotPair}
                    onHide={this.closeBotModal}
                    refetch={this.fetchBot}
                />
                <ViewTradeBot
                    isShow={viewBotModal}
                    record={viewRecord}
                    onHide={this.closeViewBotModal}
                />

                <BotUserModal
                    isShow={botUserModal}
                    onHide={this.closeBotUrsModal}
                />

                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <button onClick={this.openBotModal}
                            className="btn btn-outline-primary float-right mt-3 mr-2"
                        ><FontAwesomeIcon icon={faPlus} />Add Bot</button>
                        <button onClick={this.openBotUrsModal}
                            className="btn btn-outline-primary float-right mt-3 mr-2"
                        >
                            <FontAwesomeIcon icon={faPlus} />Bot User
                        </button>
                        <h3 className="mt-2 text-secondary">Trading Bot</h3>
                        <ReactDatatable
                            responsive={true}
                            config={this.config}
                            records={records}
                            columns={this.columns}
                            dynamic={false}
                        />
                    </div>
                </div>
            </>

        );
    }

}

export default withRouter(Tradingbot);