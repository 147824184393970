import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import axios from "axios";
import keys from "../../config/index";
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    gettradeHistoryChart,
    getTopTradeHistory,
} from "../../actions/commonActions";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons/faUserAlt";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import { BsFillCalendar2CheckFill } from "react-icons/bs";

import CanvasJSReact from "../../canvasjs.react";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            // {
            //     key: "firstName",
            //     text: "Name",
            //     className: "name",
            //     align: "left",
            //     sortable: false,
            // },
            {
                key: "userId",
                text: "Email",
                className: "email",
                align: "left",
                sortable: false,
            },
            {
                key: "price",
                text: "Price (INR)",
                className: "price",
                align: "left",
                sortable: false,
            },
            // {
            //     key: "Currency",
            //     text: "Currency",
            //     className: "currency",
            //     align: "left",
            //     sortable: false
            // },
        ];
        this.state = {
            _id: "",
            user: "",
            p2p: "",
            stacking: "",
            spot: "",
            supportticket: "",
            depposit: "",
            withdraw: "",
            nameerr: "",
            p2pdispute: "",
            setToptradeUser: "",
            setToptradePrice: [],
        };
    }

    onLogoutClick = (e) => {
        e.preventDefault();
        this.props.logoutUser();
    };

    componentDidMount() {
        // this.TopTradeHistoy();
        this.TradeHistoryChart();
        this.getData();
    }
    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }
    async TradeHistoryChart() {
        try {
            const { status, loading, message, result, arrData } =
                await gettradeHistoryChart();
            // this.setState({ 'loader': loading })
            if (status == "success") {
                console.log("result1111111", result);
                console.log("result111dddddddd1111", arrData);

                const newValuesArr = result.map(
                    (response) => (
                        console.log("response", response),
                        {
                            label: response.userId,
                            y: response.price,
                        }
                    )
                );

                console.log("newvalues", newValuesArr);
                //          this.setState({ 'setToptradePrice': newValuesArr.y })
                //          this.setState({ 'setToptradeUser': newValuesArr.label })

                // setToptrader(result)
                this.setState({ setToptradePrice: newValuesArr });
                const arrData1 = arrData.map(
                    (response) => (
                        console.log("response", response),
                        {
                            userId: response.userId,
                            price: response.price.toFixed(2),
                        }
                    )
                );
                this.setState({ setToptradeUser: arrData1 });
            }
        } catch (err) {}
    }

    // async TopTradeHistoy() {
    //     try {

    //         const { status, loading, message, result } = await getTopTradeHistory()
    //         // this.setState({ 'loader': loading })
    //         if (status == 'success') {
    //             console.log('resultssssss', result)
    //             // this.setState({ "count": result.count, 'records': result.data, 'imageUrl': result.imageUrl })
    //         }
    //     } catch (err) { }
    // }

    getData() {
        axios
            .get(keys.API_URL + "/adminapi/getcountfordashboard")
            .then((res) => {
                let value = res.data.result;
                this.setState({
                    user: value.user,
                    kyc: value.kyc,
                    p2p: value.p2p,
                    p2pdispute: value.p2pdispute,
                    stacking: value.stacking,
                    spot: value.spot,
                    supportticket: value.supportticket,
                    depposit: value.depposit,
                    withdraw: value.withdraw,
                    contactUs: value.contactUs,
                });
            })
            .catch((err) => console.log("errerrerrerrerrerrerrerr", err));
        console.log(this.props.auth);
    }

    render() {
        // this.TradeHistoryChart();

        const options = {
            title: {
                text: "Top Trader's Chart",
            },
            dataPointWidth: 50,

            data: [
                {
                    // Change type to "doughnut", "line", "splineArea", etc.
                    type: "column",
                    dataPoints: this.state.setToptradePrice,
                    // [
                    //  { label:this.state.setToptradeUser,  y: this.state.setToptradePrice },
                    //  { label: "Orange", y: 15  },
                    //  { label: "Banana", y: 25  },
                    //  { label: "Mango",  y: 30  },
                    //  { label: "Grape",  y: 28  }
                    // ]
                },
            ],
        };
        //const { user } = this.props.auth;
        return (
    
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">Dashboard</h3>
                            <div className="row px-0 px-md-2">
                                <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                                    <Link to="/users">
                                        <div
                                            className="card text-white shadow-lg"
                                            style={{
                                                backgroundColor:
                                                    "cornflowerblue",
                                            }}
                                        >
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    Total Users
                                                </h5>
                                                <p className="card-text">
                                                    No of users registered till
                                                    now.
                                                </p>
                                                <p className="btn btn-light">
                                                    <FontAwesomeIcon
                                                        className="text-primary"
                                                        icon={faUserAlt}
                                                    />{" "}
                                                    Count {this.state.user}
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                                    <Link to="/kyc">
                                        <div
                                            className="card text-white shadow-lg"
                                            style={{
                                                backgroundColor: "blueviolet",
                                            }}
                                        >
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    Total KYC
                                                </h5>
                                                <p className="card-text">
                                                    No of kyc uploaded till now.
                                                </p>
                                                <p className="btn btn-light">
                                                    <FontAwesomeIcon
                                                        className="text-primary"
                                                        icon={faUserAlt}
                                                    />{" "}
                                                    Count {this.state.kyc}
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                                    <Link to="/p2ptrade">
                                        <div
                                            className="card text-white shadow-lg"
                                            style={{
                                                backgroundColor: "IndianRed",
                                            }}
                                        >
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    P2P
                                                </h5>
                                                <p className="card-text">
                                                    No of order completed till
                                                    now
                                                </p>
                                                <p className="btn btn-light">
                                                    <FontAwesomeIcon className="text-primary" />
                                                    <BsFillCalendar2CheckFill />{" "}
                                                    Count {this.state.p2p}
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                                    <Link to="/p2pdispute">
                                        <div
                                            className="card text-white shadow-lg"
                                            style={{
                                                backgroundColor: "cadetblue",
                                            }}
                                        >
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    P2P Dispute List
                                                </h5>
                                                <p className="card-text">
                                                    No of disputes till now
                                                </p>
                                                <p className="btn btn-light">
                                                    <FontAwesomeIcon className="text-primary" />
                                                    <BsFillCalendar2CheckFill />{" "}
                                                    Count{" "}
                                                    {this.state.p2pdispute}
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                                    <Link to="/tradehistory">
                                        <div
                                            className="card text-white shadow-lg"
                                            style={{
                                                backgroundColor: "Orange",
                                            }}
                                        >
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    Spot
                                                </h5>
                                                <p className="card-text">
                                                    No of trade completed till
                                                    now
                                                </p>
                                                <p className="btn btn-light">
                                                    <FontAwesomeIcon className="text-primary" />
                                                    <BsFillCalendar2CheckFill />{" "}
                                                    Count {this.state.spot}
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                                    <Link to="/history/stakeOrder">
                                        <div
                                            className="card text-white shadow-lg"
                                            style={{
                                                backgroundColor:
                                                    "MediumSeaGreen",
                                            }}
                                        >
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    Staking
                                                </h5>
                                                <p className="card-text">
                                                    No of order completed till
                                                    now
                                                </p>
                                                <p className="btn btn-light">
                                                    <FontAwesomeIcon className="text-primary" />
                                                    <BsFillCalendar2CheckFill />{" "}
                                                    Count {this.state.stacking}
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                                    <Link to="/support">
                                        <div
                                            className="card text-white shadow-lg"
                                            style={{ backgroundColor: "Gray" }}
                                        >
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    Support Ticket
                                                </h5>
                                                <p className="card-text">
                                                    No of support ticket till
                                                    now
                                                </p>
                                                <p className="btn btn-light">
                                                    <FontAwesomeIcon className="text-primary" />
                                                    <BsFillCalendar2CheckFill />{" "}
                                                    Count{" "}
                                                    {this.state.supportticket}{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                                    <Link to="/deposit">
                                        <div
                                            className="card text-white shadow-lg"
                                            style={{
                                                backgroundColor: "Tomato",
                                            }}
                                        >
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    Deposit
                                                </h5>
                                                <p className="card-text">
                                                    No of deposit completed till
                                                    now
                                                </p>
                                                <p className="btn btn-light">
                                                    <FontAwesomeIcon className="text-primary" />
                                                    <BsFillCalendar2CheckFill />{" "}
                                                    Count {this.state.depposit}{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                                    <Link to="/withdraw">
                                        <div
                                            className="card text-white shadow-lg"
                                            style={{
                                                backgroundColor: "chocolate",
                                            }}
                                        >
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    Withdraw
                                                </h5>
                                                <p className="card-text">
                                                    No of withdraw completed
                                                    till now
                                                </p>
                                                <p className="btn btn-light">
                                                    <FontAwesomeIcon className="text-primary" />
                                                    <BsFillCalendar2CheckFill />{" "}
                                                    Count {this.state.withdraw}
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                                    <Link to="/contactus">
                                        <div
                                            className="card text-white shadow-lg"
                                            style={{ backgroundColor: "coral" }}
                                        >
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    Contact
                                                </h5>
                                                <p className="card-text">
                                                    No of contact till now
                                                </p>
                                                <p className="btn btn-light">
                                                    <FontAwesomeIcon className="text-primary" />
                                                    <BsFillCalendar2CheckFill />{" "}
                                                    Count {this.state.contactUs}
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-sm-11 p-sm-11 mb-4 mb-sm-0">
                                    <CanvasJSChart options={options} />
                                    {/* </div> */}
                                </div>
                                {/* <div id="page-content-wrapper"> */}
                                <div className="col-sm-11 p-sm-11 mb-4 mb-sm-0">
                                    <h3 className="mt-2 text-secondary">
                                        Trader's History
                                    </h3>
                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.setToptradeUser}
                                        columns={this.columns}
                                        onPageChange={this.pageChange.bind(
                                            this
                                        )}
                                    />
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
           
        );
    }
}

Dashboard.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(Dashboard));