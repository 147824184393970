import React, { useState, useEffect, Fragment } from "react";
import { Card, Form } from "react-bootstrap";

// import action
import { updateUsrDash } from '../../actions/siteSettingActions';

// import lib
import { toastAlert } from '../../lib/toastAlert'
import isEmpty from "../../lib/isEmpty";

const initialFormValue = {
    'currencyId': '',
    'colorCode': ''
}

const UserDashboard = (props) => {
    // props
    const { currencyOption, records } = props;

    // state
    const [inputFields, setInputFields] = useState([initialFormValue]);

    // function

    const handleAddFields = () => {
        const values = [...inputFields];
        values.push({
            'currencyId': '',
            'colorCode': ''
        });
        setInputFields(values);
    };

    const handleRemoveFields = index => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
    };

    const handleInputChange = (index, event) => {
        event.preventDefault()
        const { name, value } = event.target;
        const values = [...inputFields];
        values[index][name] = value
        setInputFields(values);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let reqData = {
                currencyList: inputFields
            }
            const { status, loading, message } = await updateUsrDash(reqData)
            if (status == 'success') {
                toastAlert('success', message, 'usrDash')
            } else {
                toastAlert('error', message, 'usrDash')
            }
        } catch (err) { }
    };

    useEffect(() => {
        if (!isEmpty(records) && records.userDashboard) {
            setInputFields(records.userDashboard)
        }
    }, [records])

    return (
        <Card>
            <Card.Header>User Dashboard Detail</Card.Header>
            <Card.Body>
                <form onSubmit={handleSubmit}>
                    <div className="form-row">
                        {inputFields.map((inputField, index) => {
                            console.log("---inputField", inputField)
                            return (
                                <Fragment key={`${inputField}~${index}`}>
                                    <div className="form-group col-sm-6">
                                        <label htmlFor="firstName">Currency</label>
                                        <Form.Control
                                            name="currencyId"
                                            value={inputField.currencyId}
                                            onChange={event => handleInputChange(index, event)}
                                            as="select" custom
                                        >
                                            <option value={''}>Select Currency</option>
                                            {
                                                currencyOption && currencyOption.length > 0 && currencyOption.map((item, key) => {
                                                    return (
                                                        <option value={item._id}>{item.currencySymbol}</option>
                                                    )
                                                })
                                            }
                                        </Form.Control>

                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="colorCode">Color Coder</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="colorCode"
                                            value={inputField.colorCode}
                                            onChange={event => handleInputChange(index, event)}
                                        />
                                    </div>
                                    <div className="form-group col-sm-2">
                                        <button
                                            className="btn btn-link"
                                            type="button"
                                            onClick={() => handleRemoveFields(index)}
                                        >
                                            -
                                        </button>
                                        <button
                                            className="btn btn-link"
                                            type="button"
                                            onClick={() => handleAddFields()}
                                        >
                                            +
                                        </button>
                                    </div>
                                </Fragment>
                            )
                        })}
                    </div>
                    <div className="submit-button">
                        <button
                            className="btn btn-primary mr-2"
                            type="submit"
                            onSubmit={handleSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </Card.Body>
        </Card>
    );
};

export default UserDashboard