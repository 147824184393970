// import package
import React, { Component } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { withRouter } from "react-router-dom";
import Select from 'react-select';

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

// import action
import { Botfunction, SpotTradeOrderplacing } from "../../actions/userActions";

// import config
import keys from "../../actions/config";


const url = keys.baseUrl;
var options = [];
const options1 = [{ 'value': "buy", 'label': "Buy" }, { 'value': "sell", 'label': "Sell" }];

class Tradingbot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: "",
            contact_person: "",
            email: "",
            sitename: "",
            site_description: "",
            phone_number: "",
            mobile_number: "",
            address: "",
            google_analytics: "",
            social_link1: "",
            social_link2: "",
            social_link3: "",
            social_link4: "",
            social_link5: "",
            reg_code: "",
            company_info_link: "",
            license_info_link: "",
            copyright_text: "",
            sitelogo: "",
            sitelogourl: "",
            liveprice: 0,
            tradeprice: 0,
            perparray: [],
            ordertypechange: "",
            errors: {}
        };
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.updatesettings !== undefined
            && nextProps.auth.updatesettings.data !== undefined
            && nextProps.auth.updatesettings.data.message !== undefined) {
            toast(nextProps.auth.updatesettings.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.updatesettings = undefined;
            this.getData();
            this.setState({ errors: {} })
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    handleselectChange = selectedOption => {
        this.setState({ pair: selectedOption });
        var perpaarray = this.state.perparray
        var index1 = perpaarray.findIndex(
            (x) => x.tiker_root === selectedOption.value
        );
        console.log("index of the handle", index1);
        var liveprice = this.state.perparray[index1].markprice
        this.setState({ liveprice: liveprice, tradeprice: liveprice })
    };

    handleselectbuyChange = selectedOption => {
        this.setState({ buyorsell: selectedOption })
    };

    getData() {
        axios
            .post(url + "api/spotpair-data")
            .then(res => {
                // this.setState();
                console.log(res, 'res.data')
                console.log(res.data.data, 'res.data')
                if (res.data) {
                    if (typeof res.data.data != 'undefined') {
                        this.setState({ perparray: res.data.data })

                    }
                    for (var i = 0; i < res.data.data.length; i++) {
                        var opt = { "value": res.data.data[i].tiker_root, "label": res.data.data[i].tiker_root }
                        options.push(opt);
                    }
                }
                console.log(res.data, 'reszxcvbn');
            })
            .catch()
    }
    getData1() {
        axios
            .post(url + "api/spotpair-data")
            .then(res => {
                // this.setState();
                console.log(res, 'res.data')
                console.log(res.data.data, 'res.data')
                if (res.data) {
                    if (typeof res.data.data != 'undefined' && typeof this.state.pair != 'undefined') {
                        console.log(this.state.pair.value, 'this.state.pair')
                        this.setState({ perparray: res.data.data })
                        var perpaarray = res.data.data;
                        console.log(perpaarray);
                        var index1 = perpaarray.findIndex(
                            (x) => x.tiker_root === this.state.pair.value
                        );
                        console.log("index of the handle", index1);
                        if (index1 != -1) {
                            console.log("inside")
                            var liveprice = this.state.perparray[index1].markprice;
                            console.log(liveprice, 'liveprice')
                            this.setState({ liveprice: liveprice, tradeprice: liveprice })

                        }
                    }
                }
            })
            .catch()
    }


    onDeleteBot = e => {
        e.preventDefault();
        const newCategory = {
            buyorsell: this.state.buyorsell,
            pairname: this.state.pair
        };
        console.log(newCategory, "newCategory");
        axios
            .post(url + "api/spotbot-delete", newCategory)
            .then(res => {
                if (res.status) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            }).catch();

    }

    onSettingsUpdate = e => {
        e.preventDefault();
        const data = {};

        if (this.state.buyorsell.value == "buy") {
            if (parseFloat(this.state.pricerangeend) < parseFloat(this.state.liveprice) &&
                parseFloat(this.state.pricerangeend) > parseFloat(this.state.pricerangestart) &&
                parseFloat(this.state.quantityrangeend) > parseFloat(this.state.quantityrangestart) &&
                parseFloat(this.state.quantityrangestart) >= 0) {
                console.log(" live inside");
                data['pricerangestart'] = this.state.pricerangestart;
                data['pricerangeend'] = this.state.pricerangeend;
                data['quantityrangestart'] = this.state.quantityrangestart;
                data['quantityrangeend'] = this.state.quantityrangeend;
                data['buyorsell'] = this.state.buyorsell;
                data['ordercount'] = this.state.ordercount;
                data['pair'] = this.state.pair;
                this.props.Botfunction(data);
            } else {
                toast("Please check the values entered", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        } else {
            if (parseFloat(this.state.pricerangestart) > parseFloat(this.state.liveprice) &&
                parseFloat(this.state.pricerangeend) > parseFloat(this.state.pricerangestart) &&
                parseFloat(this.state.quantityrangeend) > parseFloat(this.state.quantityrangestart) &&
                parseFloat(this.state.quantityrangestart) >= 0) {
                console.log("live inside seelll");
                data['pricerangestart'] = this.state.pricerangestart;
                data['pricerangeend'] = this.state.pricerangeend;
                data['quantityrangestart'] = this.state.quantityrangestart;
                data['quantityrangeend'] = this.state.quantityrangeend;
                data['buyorsell'] = this.state.buyorsell;
                data['ordercount'] = this.state.ordercount;
                data['pair'] = this.state.pair;
                this.props.Botfunction(data);
            } else {
                toast("Please check the values entered", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />

                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                            <h3 className="mt-2 text-secondary">Trading bot</h3>
                            <form noValidate onSubmit={this.onSettingsUpdate} id="update-settings">

                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="copyright_text">Pair</label>
                                    </div>
                                    <div className="col-md-6">
                                        <Select
                                            value={this.state.pair}
                                            onChange={this.handleselectChange}
                                            options={options}
                                        />
                                    </div>
                                </div>


                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="copyright_text">Live Price</label>
                                    </div>
                                    <div className="col-md-4">
                                        {parseFloat(this.state.liveprice).toFixed(8)}
                                    </div>
                                    <div className="col-md-3">
                                        <button
                                            onClick={this.getData1.bind(this)}
                                            form="update-settings"
                                            type="button"
                                            className="btn btn-primary">
                                            Refresh
                                </button>
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="copyright_text">Buy/Sell</label>
                                    </div>
                                    <div className="col-md-6">
                                        <Select
                                            value={this.state.buyorsell}
                                            onChange={this.handleselectbuyChange}
                                            options={options1}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="pricerangestart">Price range start </label>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.pricerangestart}
                                            id="pricerangestart"
                                            type="number"
                                            error={errors.pricerangestart}
                                            className={classnames("form-control", {
                                                invalid: errors.pricerangestart
                                            })} />
                                        <span className="text-danger">{errors.pricerangestart}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="email">Price range End</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.pricerangeend}
                                            id="pricerangeend"
                                            type="number"
                                            error={errors.pricerangeend}
                                            className={classnames("form-control", {
                                                invalid: errors.pricerangeend
                                            })} />
                                        <span className="text-danger">{errors.pricerangeend}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="quantityrangestart">Quantity range start</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.quantityrangestart}
                                            id="quantityrangestart"
                                            type="number"
                                            error={errors.quantityrangestart}
                                            className={classnames("form-control", {
                                                invalid: errors.quantityrangestart
                                            })} />
                                        <span className="text-danger">{errors.quantityrangestart}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="quantityrangeend">Quantity range end</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.quantityrangeend}
                                            id="quantityrangeend"
                                            type="number"
                                            error={errors.quantityrangeend}
                                            className={classnames("form-control", {
                                                invalid: errors.quantityrangeend
                                            })} />
                                        <span className="text-danger">{errors.quantityrangeend}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="ordercount">Order count</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.ordercount}
                                            id="ordercount"
                                            type="number"
                                            error={errors.ordercount}
                                            className={classnames("form-control", {
                                                invalid: errors.ordercount
                                            })} />
                                        <span className="text-danger">{errors.ordercount}</span>
                                    </div>
                                </div>



                            </form>
                            <br />
                            <button
                                form="update-settings"
                                type="submit"
                                className="btn btn-primary">
                                Place Spot Order
                            </button>





                            <h3 className="mt-2 text-secondary">Delete Spot Orders</h3>
                            <form noValidate onSubmit={this.onDeleteBot} id="delete-bot">
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="copyright_text">Pair</label>
                                    </div>
                                    <div className="col-md-6">
                                        <Select
                                            value={this.state.pair}
                                            onChange={this.handleselectChange}
                                            options={options}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="copyright_text">Buy/Sell</label>
                                    </div>
                                    <div className="col-md-6">
                                        <Select
                                            value={this.state.buyorsell}
                                            onChange={this.handleselectbuyChange}
                                            options={options1}
                                        />
                                    </div>
                                </div>

                            </form>
                            <br />
                            <button
                                form="delete-bot"
                                type="submit"
                                className="btn btn-primary">
                                Delete Spot Order
                            </button>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}


Tradingbot.propTypes = {
    Botfunction: PropTypes.func.isRequired,
    SpotTradeOrderplacing: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { Botfunction, SpotTradeOrderplacing }
)(withRouter(Tradingbot));
