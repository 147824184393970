import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
//import compoentns
import { withRouter } from 'react-router-dom';
import * as moment from "moment";


//imprt actions
import { TicketList } from "../../actions/supportAction";

class Support extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "tickerId",
        text: "Ticket ID",
        className: "Id",
        align: "left",
        sortable: false,
      },
      {
        key: "userName",
        text: "User Name",
        className: "userName",
        align: "left",
        sortable: false,
      },
      {
        key: "email",
        text: "Email",
        className: "userName",
        align: "left",
        sortable: false,
      },
      {
        key: "categoryName",
        text: "Category Name",
        className: "categoryName",
        align: "left",
        sortable: false,
      },
      {
        key: "status",
        text: "Status",
        className: "status",
        align: "left",
        sortable: false,
      },
      {
        key: "createdAt",
        text: "Date",
        className: "createdAt",
        align: "left",
        sortable: false,
        filter: "between",
        cell: (record) => {
          return moment(record.createdAt).format("DD-MM-YYYY");
        },
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record._id)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-reply"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Cms",
      no_data_text: "No Enquires found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
      page: 1,
      limit: 10,
      count: 0,
      loader: false,
    };

    this.getData = this.getData.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
  }

  componentDidMount() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };

    this.getData(reqData);
  }

  async getData(reqData) {
    try {
      this.setState({ loader: true });

      const { status, loading, result } = await TicketList(reqData);
      this.setState({ loader: loading });
      if (status == "success") {
        this.setState({ count: result.count, records: result.data });
      }
    } catch (err) { }
  }

  handlePagination(index) {
    let reqData = {
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    };
    this.getData(reqData);
    this.setState({
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    });
  }

  editRecord(id) {
    this.props.history.push("/support_reply/" + id);
  }

  render() {
    const { count } = this.state;
    return (
      <>

        <div id="page-content-wrapper">
          <div className="container-fluid">
            <h3 className="mt-2 text-secondary">Support Ticket List</h3>
            <ReactDatatable
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              dynamic={true}
              total_record={count}
              onChange={this.handlePagination}
            />
          </div>
        </div>
      </>

    );
  }
}

export default withRouter(Support);
