import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useSelector } from 'react-redux'


// import lib
import isLogin from '../../lib/isLogin';

const ConditionRoute = ({ component: Component, Layout: Layout, type, store, ...rest }) => {
    const history = useHistory();

    // redux-state
    const authData = useSelector(state => state.auth)

    return (
        <div>{
            Layout && Layout ?
                <Route
                    {...rest}
                    render={props => {
                        if (type == 'auth' && isLogin() == true) {
                            return <Redirect to="/dashboard" />
                        } else if (type == 'private' && isLogin() != true) {
                            return <Redirect to="/login" />
                        }


                        return (

                            <Layout Component={Component}>
                                {/* <Component {...props} /> */}
                            </Layout>
                        )
                    }}
                /> :
                <Route
                    {...rest}
                    render={props => {
                        if (type == 'auth' && isLogin() == true) {
                            return <Redirect to="/dashboard" />
                        } else if (type == 'private' && isLogin() != true) {
                            return <Redirect to="/login" />
                        }


                        return (

                            <Component {...props} />
                        )
                    }}
                />

        }
        </div>

    )

};

export default ConditionRoute;