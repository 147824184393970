// import package
import React, { Component, Fragment } from "react";
import { Modal, Button } from 'react-bootstrap';
import classnames from "classnames";
// import action
import { approveCoinWithdraw, rejectCoinWithdraw } from '../../actions/walletAction';

// import lib
import { toastAlert } from '../../lib/toastAlert';

class CoinWithdrawModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loader: false,
            isButtonDisabled: false,
            message: "",
            errors: {},
        };
    }
    handleChange = e => {
        e.preventDefault();
        let { name, value } = e.target;
        this.setState({ message: value });
    }

    rejectSubmit = async () => {
        if (!this.state.message) {
            toastAlert('error', "Reason required")
            return
        }
        const { record, fetchData } = this.props;
        this.setState({ 'isButtonDisabled': true })
        toastAlert('loading', "loading", 'coinwithdraw')
        try {
            const { status, loading, message } = await rejectCoinWithdraw(record._id, this.state.message);

            if (status == 'success') {
                this.setState({ 'isButtonDisabled': false })
                fetchData();
                this.handleClose();
                window.location.reload();
                toastAlert('success', message)
            } else {
                this.setState({ 'isButtonDisabled': false })
                toastAlert('error', message)
                window.location.reload();
            }
        } catch (err) { }
    }

    confirmSubmit = async () => {
        const { record, fetchData } = this.props;
        this.setState({ 'isButtonDisabled': true })
        toastAlert('loading', "loading",)
        try {
            const { status, loading, message } = await approveCoinWithdraw(record._id);

            if (status == 'success') {
                this.setState({ 'isButtonDisabled': false })
                this.handleClose();
                toastAlert('success', message, 'coinwithdraw')
                fetchData();
            } else {
                setTimeout(() => {
                    this.setState({ 'isButtonDisabled': false })
                }, 500);
                toastAlert('error', message, 'coinwithdrawError')
            }
        } catch (err) { }
    }

    handleClose = () => {
        const { onHide } = this.props;
        onHide();
    }

    render() {
        const { isShow, record, loader } = this.props;
        const { errors, message } = this.state;
        return (
            <Modal
                show={isShow}
                onHide={this.handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="popUpSpace">
                        {/* {
                            record.status == "completed" && <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">Transaction ID</label>
                                </div>
                                <div className="col-md-9">
                                    {record.txid}
                                </div>
                            </div>
                        } */}

                        <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="answer">To address</label>
                            </div>
                            <div className="col-md-9">
                                {record.toaddress}
                            </div>
                        </div>

                        {/* 
                        <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="answer">Tag id/Memo</label>
                            </div>
                            <div className="col-md-9">
                                {this.state.tagid}
                            </div>
                        </div> */}

                        <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="answer">Transfer Currency</label>
                            </div>
                            <div className="col-md-9">
                                {record.currencySymbol}
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="answer">Transfer Amount</label>
                            </div>
                            <div className="col-md-9">
                                {record.actualAmount}
                            </div>
                        </div>
                        {record.commissionFeeType == "flat" ?
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">Commission Fee</label>
                                </div>
                                <div className="col-md-9">
                                    {record.commissionFee}
                                </div>
                            </div> : <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">Commission Fee</label>
                                </div>
                                <div className="col-md-9">
                                    {record.commissionFee}%
                                </div>
                            </div>}

                        <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="answer">Total Amount</label>
                            </div>
                            <div className="col-md-9">
                                {record.amount}
                            </div>
                        </div>


                        <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="answer">Status</label>
                            </div>
                            <div className="col-md-9">
                                {record.status}
                            </div>
                        </div>
                        {record.status == 'pending' &&
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">Reason</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        type="text"
                                        name="message"
                                        value={message}
                                        onChange={this.handleChange}
                                        error={errors.message}
                                        className={classnames("form-control", {
                                            invalid: errors.message,
                                        })}
                                    />
                                    <span className="text-danger">{errors.message}</span>

                                </div>
                            </div>}
                    </div>
                </Modal.Body>
                {
                    record.status == 'pending' && <Modal.Footer>
                        <Button
                            variant="danger btnDefaultNewBlue"
                            onClick={this.rejectSubmit}
                            disabled={this.state.isButtonDisabled}
                        >
                            Reject
                        </Button>
                        <Button
                            onClick={this.confirmSubmit}
                            variant="success btnDefaultNew"
                            disabled={this.state.isButtonDisabled}
                        >
                            Confirm
                        </Button>
                    </Modal.Footer>
                }

            </Modal>
        )
    }
}

export default CoinWithdrawModal;