
import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from '@ashvin27/react-datatable';
import { faPlus } from "@fortawesome/free-solid-svg-icons";

// import component
import { withRouter } from 'react-router-dom';
import FaqAddModal from "../partials/FaqAddModal";
import FaqUpdateModal from "../partials/FaqUpdateModal";
import moment from 'moment'
// import action
import { getFaqCategory, faqList } from '../../actions/faqActions'
import { getNotification } from '../../actions/admin'


class Notification extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "createdAt",
                text: "Date",
                className: "question",
                align: "left",
                sortable: false,
                width: 200,
                cell: record => {
                    return (
                        <Fragment>
                            <p>{moment(record.createdAt).format('MMMM,Do YYYY, hh:mm A')}</p>
                        </Fragment>
                    )


                }
            },
            {
                key: "email",
                text: "email",
                className: "question",
                align: "left",
                sortable: false,
                width: 200,
                cell: record => {
                    if (record.viewType == 'user') {
                        return (
                            <Fragment>
                                <p>Email:{record && record.userId && record.userId.email}</p>
                                <p>Phone:{record && record.userId && record.userId.phoneNo}</p>
                            </Fragment>
                        )
                    } else {
                        return (
                            <Fragment>
                                <p>{record && record.adminId && record.adminId.email}</p>
                            </Fragment>
                        )
                    }



                }
            },
            {
                key: "type",
                text: "Notification Type",
                className: "question",
                align: "left",
                sortable: false,
                width: 200
            },
            {
                key: "description",
                text: "Description",
                className: "description",
                align: "left",
                sortable: false
            },

        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Faq",
            no_data_text: 'No Faq found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                // filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: false,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            addFormModal: false,
            editFormModal: false,
            editRecord: {},
            records: [],
            search: '',
            page: 1,
            limit: 10,
            count: 0,
            param: "showAll",
            loader: false,
            categoryOption: []
        };

        this.fetchNotification = this.fetchNotification.bind(this);
        this.refetch = this.refetch.bind(this);
        this.handlePagination = this.handlePagination.bind(this)
    }

    componentDidMount() {
        const { page, limit, param } = this.state;
        let reqData = {
            page,
            limit,
            param
        }
        this.fetchNotification(reqData)
    };

    async fetchNotification(reqData) {
        this.setState({ 'loader': true })
        try {
            const { status, loading, result, count } = await getNotification(reqData);
            console.log("resultresultresultresultresultresult", count)
            this.setState({ 'loader': loading })
            if (status == 'success') {
                this.setState({ "count": count, 'records': result })
            }
        } catch (err) { }
    }


    refetch() {
        const { page, limit, param } = this.state;
        let reqData = {
            page,
            limit,
            param
        }
        this.fetchNotification(reqData)
    }

    handlePagination(index) {
        let reqData = {
            page: index.page_number,
            limit: index.page_size,
            search: index.filter_value,
            param: this.state.param
        }
        this.fetchNotification(reqData);
        this.setState({ page: index.page_number, limit: index.page_size, search: index.filter_value })
    }

    render() {
        const { loader, count, } = this.state;

        return (
            <>


                <div id="page-content-wrapper">
                    <div className="container-fluid">

                        <h3 className="mt-2 text-secondary">Notification History</h3>
                        <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            dynamic={true}
                            total_record={count}
                            loading={loader}
                            onChange={this.handlePagination}
                        />
                    </div>
                </div>
            </>

        );
    }

}

export default withRouter(Notification);
