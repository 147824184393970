// import config
import axios from "../config/axios";
let withdrawSource, depositSource;
export const getDepositList = async (data = {}) => {
  try {

      if (depositSource) depositSource.cancel();
      depositSource = axios.CancelToken.source();

      const respData = await axios({
          url: `/adminapi/depositList`,
          method: "post",
          data,
          cancelToken: depositSource.token,
      });
      return {
          status: "success",
          totalCount: respData.data.totalCount,
          result: respData.data.result,
      };
  } catch (err) {
      return {
          status: "failed",
      };
  }
};

export const getWithdrawList = async (data) => {
  try {
    const respData = await axios({
      url: `/adminapi/withdrawList`,
      method: "get",
      params: data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const approveCoinWithdraw = async (id) => {
  try {
    const respData = await axios({
      url: `/adminapi/coinWithdraw/approve/${id}`,
      method: "get",
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const rejectCoinWithdraw = async (id,message) => {
  try {
    const respData = await axios({
      url: `/adminapi/coinWithdraw/reject/${id}/${message}`,
      method: "get",
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const approveFiatWithdraw = async (id) => {
  try {
    const respData = await axios({
      url: `/adminapi/fiatWithdraw/approve/${id}`,
      method: "get",
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const rejectFiatWithdraw = async (id,message) => {
  try {
    const respData = await axios({
      url: `/adminapi/fiatWithdraw/reject/${id}/${message}`,
      method: "get",
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const approveFiatDeposit = async (data) => {
  try {
    const respData = await axios({
      url: `/adminapi/fiatDeposit/approve`,
      method: "post",
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error:err.response.data.errors
    };
  }
};
export const rejectFiatDeposit = async (data) => {
  try {
    const respData = await axios({
      url: `/adminapi/fiatDeposit/reject`,
      method: "post",
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error:err.response.data.errors
    };
  }
};

export const getrefferalList = async (data) => {
  try {
    const respData = await axios({
      url: `/adminapi/refferrallist`,
      method: "get",
      params: data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};
