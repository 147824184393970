import React from "react";
import classnames from "classnames";
import { Modal, Form } from "react-bootstrap";

//import action
import { addP2PPair } from "../../actions/tradePairAction";

//import lib
import { toastAlert } from "../../lib/toastAlert";

const options = [
  { value: "binance", label: "binance" },
  { value: "Off", label: "Off" },
];

const initialFormValue = {
  firstCurrency: " ",
  secondCurrency: " ",
  transactionfee: "",
  markPrice: "",
  status: "0", //active ,deactive
};

class PairAddModal extends React.Component {
  constructor() {
    super();
    this.state = {
      formValue: initialFormValue,
      currencyList: [],

      errors: {},
    };
  }

  handleChange = (e) => {
    e.preventDefault();
    let { name, id, value } = e.target;
    let formData = { ...this.state.formValue, ...{ [id]: value } };
    this.setState({ formValue: formData });
  };

  handleClose = () => {
    const { onHide } = this.props;
    onHide();
    this.setState({ formValue: initialFormValue, errors: {} });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { formValue } = this.state;
      const { fetchData } = this.props;
      let reqData = formValue;
      
      let { status, loading, result, error, message } = await addP2PPair(
        reqData
      );
      console.log("statusstatusstatusstatusstatusstatus", status);
      if (status=="success") {
        toastAlert("success", message, "addTemplate");
        fetchData()
        this.handleClose();
      }
      if (status == "failed") {
        if (error) {
          this.setState({ errors: error });
        }
        // toastAlert("error", error.firstCurrency, "addSpotPair");
      }
    } catch (err) {}
  };

  render() {
    const { errors, currencyList } = this.state;
    const { markPrice, transactionfee, firstCurrency, secondCurrency, status } =
      this.state.formValue;

    const { isShow, currencyOptions } = this.props;

    return (
      <div>
        <Modal
          show={isShow}
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
          scrollable={true}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">Add P2P Pair</h4>
          </Modal.Header>
          <Modal.Body>
            <form noValidate id="add-spot">
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="identifier">Base Currency </label>
                </div>
                <div className="col-md-9">
                  <Form.Control
                    as="select"
                    custom
                    name={"firstCurrency"}
                    id={"firstCurrency"}
                    value={firstCurrency}
                    onChange={this.handleChange}
                    className={classnames("form-control", {
                      invalid: errors.firstCurrency,
                    })}
                  >
                    <option value={""}>{"Select Base Currency"}</option>
                    {currencyOptions &&
                      currencyOptions.length > 0 &&
                      currencyOptions.map((item, key) => {
                        if (item.type == "token")
                          return (
                            <option key={key} value={item.label}>
                              {item.label}
                            </option>
                          );
                      })}
                  </Form.Control>
                  <span className="text-danger">{errors.firstCurrency}</span>
                </div>
              </div>
              {/* <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="taker_fees">Base Currency Decimal</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        onChange={this.handleChange}
                                        value={firstFloatDigit}
                                        name="firstFloatDigit"
                                        error={errors.firstFloatDigit}
                                        id="firstFloatDigit"
                                        type="text"
                                        className={classnames("form-control", {
                                            invalid: errors.firstFloatDigit
                                        })}
                                    />
                                    <span className="text-danger">{errors.firstFloatDigit}</span>
                                </div>
                            </div> */}

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="identifier">Quote Currency </label>
                </div>
                <div className="col-md-9">
                  <Form.Control
                    as="select"
                    custom
                    name={"secondCurrency"}
                    id={"secondCurrency"}
                    value={secondCurrency}
                    onChange={this.handleChange}
                    className={classnames("form-control", {
                      invalid: errors.secondCurrency,
                    })}
                  >
                    <option value={""}>{"Select Quote Currency"}</option>
                    {currencyOptions &&
                      currencyOptions.length > 0 &&
                      currencyOptions.map((item, key) => {
                        if (item.type == "fiat")
                          return (
                            <option key={key} value={item.label}>
                              {item.label}
                            </option>
                          );
                      })}
                  </Form.Control>
                  <span className="text-danger">{errors.secondCurrency}</span>
                </div>
              </div>
              {/* <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="taker_fees">Quote Currency Decimal</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        onChange={this.handleChange}
                                        value={secondFloatDigit}
                                        name="secondFloatDigit"
                                        error={errors.secondFloatDigit}
                                        id="secondFloatDigit"
                                        type="text"
                                        className={classnames("form-control", {
                                            invalid: errors.secondFloatDigit
                                        })}
                                    />
                                    <span className="text-danger">{errors.secondFloatDigit}</span>
                                </div>
                            </div> */}

              {/* <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="maker_rebate">Maker Fee(%)</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        onChange={this.handleChange}
                                        value={maker_rebate}
                                        name="maker_rebate"
                                        error={errors.maker_rebate}
                                        id="maker_rebate"
                                        type="text"
                                        className={classnames("form-control", {
                                            invalid: errors.maker_rebate
                                        })}
                                    />
                                    <span className="text-danger">{errors.maker_rebate}</span>
                                </div>
                            </div> */}

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="taker_fees">Transaction Fee(%)</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={transactionfee}
                    name="transactionfee"
                    error={errors.transactionfee}
                    id="transactionfee"
                    type="number"
                    className={classnames("form-control", {
                      invalid: errors.transactionfee,
                    })}
                  />
                  <span className="text-danger">{errors.transactionfee}</span>
                </div>
              </div>

              {/* <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="taker_fees">Minimum Price(%)</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        onChange={this.handleChange}
                                        value={minPricePercentage}
                                        name="minPricePercentage"
                                        error={errors.minPricePercentage}
                                        id="minPricePercentage"
                                        type="text"
                                        className={classnames("form-control", {
                                            invalid: errors.minPricePercentage
                                        })}
                                    />
                                    <span className="text-danger">{errors.minPricePercentage}</span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="taker_fees">Maximum Price(%)</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        onChange={this.handleChange}
                                        value={maxPricePercentage}
                                        name="maxPricePercentage"
                                        error={errors.maxPricePercentage}
                                        id="maxPricePercentage"
                                        type="text"
                                        className={classnames("form-control", {
                                            invalid: errors.maxPricePercentage
                                        })}
                                    />
                                    <span className="text-danger">{errors.maxPricePercentage}</span>
                                </div>
                            </div>



                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="minQuantity">Minimum Quantity</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        onChange={this.handleChange}
                                        value={minQuantity}
                                        name="minQuantity"
                                        error={errors.minQuantity}
                                        id="minQuantity"
                                        type="text"
                                        className={classnames("form-control", {
                                            invalid: errors.minQuantity
                                        })}
                                    />
                                    <span className="text-danger">{errors.minQuantity}</span>
                                </div>
                            </div>


                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="maxQuantity">Maximum Quantity</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        onChange={this.handleChange}
                                        value={maxQuantity}
                                        name="maxQuantity"
                                        error={errors.maxQuantity}
                                        id="maxQuantity"
                                        type="text"
                                        className={classnames("form-control", {
                                            invalid: errors.maxQuantity
                                        })}
                                    />
                                    <span className="text-danger">{errors.maxQuantity}</span>
                                </div>
                            </div> */}
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="maxQuantity">Market price</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={markPrice}
                    name="markPrice"
                    error={errors.markPrice}
                    id="markPrice"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.markPrice,
                    })}
                  />
                  <span className="text-danger">{errors.markPrice}</span>
                </div>
              </div>
              {/* {
                                botstatus == 'binance' &&
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label>Markup(%)</label>
                                    </div>
                                    <div className="col-md-9">

                                        <input
                                            onChange={this.handleChange}
                                            value={markupPercentage}
                                            name="maxQuantity"
                                            error={errors.markupPercentage}
                                            id="markupPercentage"
                                            type="text"
                                            className={classnames("form-control", {
                                                invalid: errors.markupPercentage
                                            })}
                                        />


                                        <span className="text-danger">{errors.markupPercentage}</span>
                                    </div>
                                </div>
                            } */}

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="autobot">Status</label>
                </div>
                <div className="col-md-9">
                  <Form.Control
                    as="select"
                    custom
                    name={"status"}
                    id={"status"}
                    value={status}
                    onChange={this.handleChange}
                  >
                    <option value={""}>{"Select Status"}</option>
                    <option value={"0"}>{"Active"}</option>
                    <option value={"1"}>{"Deactive"}</option>

                    {/* {
                                            options && options.length > 0 && options.map((item, key) => {
                                                return (
                                                    <option key={key} value={item.value}>{item.label}</option>
                                                )
                                            })
                                        } */}
                  </Form.Control>
                  {/* <span className="text-danger">{errors.botstatus}</span> */}
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={this.handleClose}
            >
              Close
            </button>
            <button onClick={this.handleSubmit} className="btn btn-primary">
              Submit
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default PairAddModal;
