// import package
import React, { createRef, useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import authLogo from "../../images/authLogo2.png";

// import action
import { resetPassword } from "../../actions/authActions";

// import lib
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";

const initialFormValue = {
    password: "",
    confirmPassword: "",
};

const ResetPassword = () => {
    const history = useHistory();
    const { authToken } = useParams();

    // states
    const [formValue, setFormValue] = useState(initialFormValue);
    const [toched, setToched] = useState({});
    const [validateError, setValidateError] = useState({});
    const [loader, setLoader] = useState();

    const { password, confirmPassword } = formValue;

    // function
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } };
        setFormValue(formData);
        setValidateError({ ...validateError, ...{ [name]: "" } });
    };

    const handleBlur = (e) => {
        const { name } = e.target;
        setToched({ ...toched, ...{ [name]: true } });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);
        let reqData = {
            password,
            confirmPassword,
            authToken,
        };
        let { status, error, message } = await resetPassword(reqData);
        setLoader(false);
        if (status == "success") {
            setFormValue(initialFormValue);
            setToched({});
            setValidateError({});
            history.push("/login");
            toastAlert("success", message);
        } else {
            if (error) setValidateError(error);
            if (message) toastAlert("error", message);
        }
    };

    return (
        <div className="container">
                <div className="row mt-5">
                    <div className="col-md-4 mx-auto mt-5 card shadow-lg">
                        <div className="card-body p-1">
                            <img
                                style={{
                                    paddingLeft: "15%",
                                }}
                                className="text-center text-primary mt-3"
                                src={authLogo}
                            />
                            <h2 className="text-center text-primary mt-3">
                                Reset Password
                            </h2>
                            <form
                                noValidate
                                className="white"
                            >
                                <label htmlFor="password">Password</label>
                                <input
                                    className="form-control"
                                    placeholder="Password"
                                    name="password"
                                    type="password"
                                    value={password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {validateError.password && (
                                    <p className="text-danger">
                                        {validateError.password}
                                    </p>
                                )}
                                <br />
                                <label htmlFor="password">Confirm Password</label>
                                <input
                                    className="form-control"
                                    placeholder="Confirm Password"
                                    name="confirmPassword"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {validateError.confirmPassword && (
                                        <p className="text-danger">
                                            {validateError.confirmPassword}
                                        </p>
                                    )}
                                <br />
                                <p className="text-center pb-0 mt-2">
                                    <button
                                    onClick={handleFormSubmit}
                                    disabled={loader}
                                >
                                    {loader && (
                                        <i class="fas fa-spinner fa-spin"></i>
                                    )}
                                    Submit
                                </button>
                                </p>
                                <p className="grey-text text-darken-1">
                                    Back to Login <Link to="/login">Login</Link>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default ResetPassword;