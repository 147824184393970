// import package
import React from "react";
import { Modal, Form } from "react-bootstrap";
import clsx from 'classnames'

// import action
import { newBotUser, getBotUser } from '../../../actions/tradingBot'

// import lib
import { toastAlert } from '../../../lib/toastAlert'
import isEmpty from '../../../lib/isEmpty';
import { botUsrValid } from './validation';

const initialFormValue = {
    'firstName': '',
    'lastName': '',
    'email': ''
}

class BotUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formValue: initialFormValue,
            loader: false,
            errors: {}
        };
    }

    componentDidMount() {
        this.fetchBotUsr()
    }

    async fetchBotUsr() {
        try {
            const { status, message, result } = await getBotUser();
            if (status == 'success') {
                this.setState({
                    formValue: {
                        firstName: result.firstName,
                        lastName: result.lastName,
                        email: result.email,
                    }
                })
            }
        } catch (err) { }
    }

    handleChange = e => {
        e.preventDefault();
        let { name, value } = e.target;
        let formData = { ...this.state.formValue, [name]: value };
        this.setState({ formValue: formData });
    }

    handleClose = () => {
        const { onHide } = this.props;
        onHide();
        // this.setState({ 'formValue': initialFormValue, errors: {} });
    }

    handleSubmit = async e => {
        e.preventDefault();
        const { formValue } = this.state;
        this.setState({ loader: true })

        try {
            let reqData = {
                'firstName': formValue.firstName,
                'lastName': formValue.lastName,
                'email': formValue.email
            }

            const validateError = await botUsrValid(reqData)
            if (!isEmpty(validateError)) {
                this.setState({ "errors": validateError })
                return
            }

            const { status, loading, message, error } = await newBotUser(reqData);
            this.setState({ loader: loading })
            if (status == 'success') {
                toastAlert('success', message, 'newBot')
                this.handleClose()
            } else {
                if (!isEmpty(error)) {
                    this.setState({ errors: error })
                } else {
                    toastAlert('error', message, 'newBot')
                }
            }
        } catch (err) { }
    };


    render() {
        const { firstName, lastName, email } = this.state.formValue
        const { errors, loader } = this.state;
        const { isShow } = this.props;

        return (
            <div>
                <Modal
                    show={isShow}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    // size="lg"
                    centered
                    backdrop="static"
                    scrollable={true}
                >
                    <Modal.Header closeButton>
                        <h4 className="modal-title">Bot User</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <form noValidate>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">First Name</label>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        name="firstName"
                                        type="text"
                                        value={firstName}
                                        onChange={this.handleChange}
                                        error={errors.firstName}
                                        className={clsx("form-control", {
                                            invalid: errors.firstName
                                        })} />
                                    <span className="text-danger">{errors.firstName}</span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Last Name</label>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        name="lastName"
                                        type="text"
                                        value={lastName}
                                        onChange={this.handleChange}
                                        error={errors.lastName}
                                        className={clsx("form-control", {
                                            invalid: errors.lastName
                                        })} />
                                    <span className="text-danger">{errors.lastName}</span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="email">Email</label>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        name="email"
                                        type="email"
                                        value={email}
                                        onChange={this.handleChange}
                                        error={errors.email}
                                        className={clsx("form-control", {
                                            invalid: errors.email
                                        })} />
                                    <span className="text-danger">{errors.email}</span>
                                </div>
                            </div>


                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={this.handleClose}
                        >
                            Close
                        </button>
                        <button
                            onClick={this.handleSubmit}
                            className="btn btn-primary"
                        >
                            {loader && <i class="fas fa-spinner fa-spin"></i>}
                            Submit
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default BotUserModal;