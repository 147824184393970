import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { forgotUser } from "../../actions/authActions";
import classnames from "classnames";
import authLogo from "../../images/authLogo2.png";
import { toast, ToastContainer} from "react-toastify";

class Forgot extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            errors: {},
        };
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }

        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors,
            });
        }

        if (nextProps.auth !== undefined && nextProps.auth.forgot !== undefined && nextProps.auth.forgot.data !== undefined && nextProps.auth.forgot.data.message !== undefined) {
            toast(nextProps.auth.forgot.data.message, {
                position: toast.POSITION.TOP_CENTER,
            });
            nextProps.auth.forgot = undefined;
        }
    }

    onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = (e) => {
        e.preventDefault();
        const userData = {
            email: this.state.email,
        };
        this.props.forgotUser(userData);
    };

    render() {
        const { errors } = this.state;
        return (
            <div className="container">
                <div className="row mt-5">
                    <div className="col-md-4 mx-auto mt-5 card shadow-lg">
                        <div className="card-body p-1">
                            <img
                                style={{
                                    paddingLeft: "15%",
                                }}
                                className="text-center text-primary mt-3"
                                src={authLogo}
                            />
                            <h2 className="text-center text-primary mt-3">
                                Forgot Password
                            </h2>
                            <form
                                noValidate
                                onSubmit={this.onSubmit}
                                className="white"
                            >
                                <label htmlFor="email">Email</label>
                                <input
                                    onChange={this.onChange}
                                    value={this.state.email}
                                    error={errors.email}
                                    id="email"
                                    type="email"
                                    className={classnames("form-control", {
                                        invalid: errors.email,
                                    })}
                                />
                                <span className="text-danger">
                                    {errors.email}
                                </span>
                                <br />
                                <p className="text-center pb-0 mt-2">
                                    <button
                                        type="submit"
                                        className="btn btn-large btn-primary mt-2 px-5"
                                    >
                                        Forgot
                                    </button>
                                </p>
                                <p className="grey-text text-darken-1">
                                    Back to Login <Link to="/login">Login</Link>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Forgot.propTypes = {
    forgotUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});
export default connect(mapStateToProps, { forgotUser })(Forgot);