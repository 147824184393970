// import config
import axios from '../config/axios';

export const spotOrderHistory = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `/adminapi/spotOrderHistory`,
            data,
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            totalCount: respData.data.totalCount,
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const spotTradeHistory = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `/adminapi/spotTradeHistory`,
            data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            totalCount: respData.data.totalCount,
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}
export const passBookHistory = async (data) => {

    try {
        console.log('dataaaaaaaaa', data)
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/userPassBookHistory`,
            'params': data
        });
        console.log('respData', respData)
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            count: respData.data.count
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const perpetualOrderHistory = async (data) => {

    try {
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/perpetualOrderHistory`,
            'params': data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const perpetualTradeHistory = async (data) => {

    try {
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/perpetualTradeHistory`,
            'params': data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}
