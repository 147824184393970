import React from 'react'
import classnames from "classnames";
import { Modal, Form } from "react-bootstrap";
import { Editor } from '@tinymce/tinymce-react';

// import action
import { approveUserKyc, rejectUserKyc } from '../../actions/userKycAction';
// import lib
import { toastAlert } from '../../lib/toastAlert';
import isEmpty from '../../lib/isEmpty'

const initialFormValue = {
    'formType': '',
    'userId': '',
    'type': '',
    'frontImage': '',
    'backImage': '',
    'selfiImage': '',
    'proofNumber': '',
    'reason': '',
    'oldreason': '',
}


class KycUpdate extends React.Component {
    constructor(props) {
        super();
        this.state = {
            formValue: initialFormValue,
            errors: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        const { record } = nextProps;

        console.log("recordrecordrecordrecordrecord", record)
        if (record) {
            this.setState({ formValue: record })
        }
    }

    handleChange = e => {
        e.preventDefault();
        let { name, value } = e.target;
        let formData = { ...this.state.formValue, ...{ [name]: value } };
        this.setState({ formValue: formData });
    }

    handleClose = () => {
        const { onHide } = this.props;
        onHide();
        this.setState({ 'formValue': initialFormValue, errors: {} });
    }

    handleApprove = async e => {
        e.preventDefault();
        try {
            const { fetchData } = this.props;
            const { formValue } = this.state;

            console.log("-------formValue", formValue)

            let reqData = {
                'userId': formValue.userId,
                'formType': formValue.formType,
            };

            const { status, loading, message, error } = await approveUserKyc(reqData);
            if (status == 'success') {
                fetchData();
                toastAlert('success', message, 'addTemplate');
                this.handleClose()
            } else {
                toastAlert('error', message, 'addTemplate');
            }
        } catch (err) { }
    }

    handleReject = async e => {
        e.preventDefault();
        if (window.confirm("Are you sure to Reject KYC") == true) {
            try {
                const { fetchData } = this.props;
                const { formValue } = this.state;

                let reqData = {
                    'userId': formValue.userId,
                    'formType': formValue.formType,
                    'reason': formValue.reason,
                };

                const { status, loading, message, error } = await rejectUserKyc(reqData);
                if (status == 'success') {
                    fetchData();
                    toastAlert('success', message, 'kycform');
                    this.handleClose()
                } else {
                    if (error) {
                        this.setState({ errors: error })
                    }
                    toastAlert('error', message, 'kycform');
                }
            } catch (err) { }
        }
    }
    isPDF = async (url) => {

        if (!isEmpty(url)) {
            alert(url.toLowerCase().endsWith('.pdf'))
            return url.toLowerCase().endsWith('.pdf');
        }
    }
    render() {
        const { errors } = this.state;
        const { formType, type, reason, frontImage, backImage, selfiImage, proofNumber, oldreason } = this.state.formValue
        const { isShow } = this.props;
        const frontImageShow = frontImage && frontImage.toLowerCase().endsWith('.pdf');
        return (

            <div>
                <Modal
                    show={isShow}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    size="lg"
                    centered
                    scrollable={true}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        {
                            formType == 'idProof' && <h4 className="modal-title">Identity Document</h4>
                        }
                        {
                            formType == 'addressProof' && <h4 className="modal-title">Residential Document</h4>
                        }

                    </Modal.Header>
                    <Modal.Body>
                        <form noValidate>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Type</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        type="text"
                                        name="type"
                                        value={type}
                                        className={classnames("form-control")}
                                    />
                                </div>
                            </div>

                            {
                                formType == 'idProof' && <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="name">Proof Number</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            type="text"
                                            name="proofNumber"
                                            value={proofNumber}
                                            className={classnames("form-control")}
                                        />
                                    </div>
                                </div>
                            }


                            {frontImage &&
                                <>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Front Image</label>
                                        </div>
                                        <div className="col-md-9">
                                            <a
                                                href={frontImage}
                                                target="_blank"
                                                className="btn btn-primary mr-3"
                                            >
                                                view
                                            </a>
                                        </div>
                                    </div>

                                    {!frontImage.toLowerCase().endsWith('.pdf') &&
                                        <div className="row mt-2">
                                            <div className="col-md-12">
                                                <img src={frontImage} width="100%" height="250" />
                                            </div>
                                        </div>}
                                </>
                            }
                            {backImage &&
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="name">Back Image</label>
                                    </div>
                                    <div className="col-md-9">
                                        <a
                                            href={backImage}
                                            target="_blank"
                                            className="btn btn-primary mr-3"
                                        >
                                            view
                                        </a>
                                    </div>
                                </div>
                            }

                            {
                                formType == 'idProof' && <div className="row mt-2">
                                    <div className="col-md-12">
                                        {/* <div>
                                            <label htmlFor="name">Back Image</label>
                                            <a
                        href={backImage}
                        target="_blank"
                        className="btn btn-primary mr-3"
                        >
                        view
                        </a>
                                        </div> */}
                                        {!backImage.toLowerCase().endsWith('.pdf') &&
                                            <img src={backImage} width="100%" height="250" />}
                                    </div>
                                </div>
                            }
                            {selfiImage &&
                                <>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Selfie Image</label>
                                        </div>
                                        <div className="col-md-9">
                                            <a
                                                href={selfiImage}
                                                target="_blank"
                                                className="btn btn-primary mr-3"
                                            >
                                                view
                                            </a>
                                        </div>
                                    </div>

                                    {
                                        formType == 'idProof' && <div className="row mt-2">
                                            <div className="col-md-12">
                                                {/* <div>
                                            <label htmlFor="name">Selfi Image</label>
                                            <a
                        href={selfiImage}
                        target="_blank"
                        className="btn btn-primary mr-3"
                        >
                        view
                        </a>
                                        </div> */}
                                                {!selfiImage.toLowerCase().endsWith('.pdf') &&
                                                    <img src={selfiImage} width="100%" height="250" />

                                                }    </div>
                                        </div>
                                    }
                                </>}

                            {oldreason &&

                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="name">Old Rejected Reason</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input

                                            type="text"
                                            name="reason"
                                            value={oldreason}
                                            className={classnames("form-control", {
                                                invalid: errors.oldreason
                                            })}
                                            disable


                                        />
                                        <span className="text-danger">{errors.oldreason}</span>
                                    </div>
                                </div>

                            }

                            {this.state.formValue && this.state.formValue.status != "new" &&
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="name">Reason</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            type="text"
                                            name="reason"
                                            value={reason}
                                            onChange={this.handleChange}
                                            error={errors.reason}
                                            className={classnames("form-control", {
                                                invalid: errors.reason
                                            })}
                                        />
                                        <span className="text-danger">{errors.reason}</span>
                                    </div>
                                </div>

                            }

                        </form>

                    </Modal.Body>
                    {this.state.formValue && this.state.formValue.status == "pending" &&
                        <Modal.Footer>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.handleApprove}
                            >
                                Approve
                            </button>
                            <button
                                type="button"
                                onClick={this.handleReject}
                                className="btn btn-primary"
                            >
                                Reject
                            </button>
                        </Modal.Footer>
                    }
                    {this.state.formValue && this.state.formValue.status == "approved" &&
                        <Modal.Footer>
                            <button
                                type="button"
                                onClick={this.handleReject}
                                className="btn btn-danger"
                            >
                                Force Reject
                            </button>
                        </Modal.Footer>
                    }
                </Modal>


            </div>

        )
    }
}

export default KycUpdate
