import React, { Component, Fragment } from "react";
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { newsletteremail } from "../../actions/userActions";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import keys from "../../actions/config";
import { CKEditor } from "ckeditor4-react";
import Multiselect from "multiselect-react-dropdown";

const url = keys.baseUrl;

class Newsletter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email_assigned: false,
            emails: {},
            sendMails: {},
            message: "",
            errors: {},
            loader: false
        };

        this.handleEditorChange = this.handleEditorChange.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.errors !== prevProps.errors) {
            this.setState({ loader: false });
            this.setState({
                errors: this.props.errors,
            });
        }
        if (this.props.auth !== undefined && this.props.auth.profile !== undefined && this.props.auth.profile.data !== undefined && this.props.auth.profile.data.message !== undefined) {
            this.setState({ loader: false });
            toast(this.props.auth.profile.data.message, {
                position: toast.POSITION.TOP_CENTER,
            });
            this.props.auth.profile = undefined;
            setTimeout(function () {
                window.location.reload(false);
            }, 3000);
        }
    }

    onSelect = (selectedList, selectedItem) => {
        this.setState({
            sendMails: selectedList,
        });
        this.setState({ errors: { ...this.state.errors, ...{ email: "" } } });
    };

    onRemove = (selectedList, removedItem) => {
        this.setState({
            sendMails: selectedList,
        });
        this.setState({ errors: { ...this.state.errors, ...{ email: "" } } });
    };

    getData() {
        axios
            .get(url + "adminApi/news-letter")
            .then((res) => {
                var arremail = [];
                arremail.push("All")
                res.data.map((item, i) => {
                    return arremail.push(item.email);
                });
                this.setState({ emails: arremail, email_assigned: true });
            })
            .catch();
    }

    handleEditorChange(e) {
        this.setState({ message: e.editor.getData() });
        this.setState({ errors: { ...this.state.errors, ...{ message: "" } } });
    }

    onemailsend = (e) => {
        e.preventDefault();
        this.setState({ loader: true });
        const newsletteremail = {
            email: this.state.sendMails,
            message: this.state.message,
        };
        this.props.newsletteremail(newsletteremail);
    };

    render() {
        const { errors, loader } = this.state;

        return (
            <>

                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <h3 className="mt-2 text-secondary">
                        Newsletter Details
                        </h3>
                        <form
                            noValidate
                            onSubmit={this.onemailsend}
                            id="send-email"
                        >
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="email">Email</label>
                                </div>
                                <div className="col-md-9">
                                    {this.state.email_assigned ? (
                                        <Multiselect
                                            options={this.state.emails}
                                            onSelect={this.onSelect}
                                            onRemove={this.onRemove}
                                            isObject={false}
                                            emptyRecordMsg="No Emails Available"
                                            avoidHighlightFirstOption={true}
                                            hidePlaceholder={true}
                                        />
                                    ) : (
                                        ""
                                    )}

                                    <span className="text-danger">
                                        {errors.email}
                                    </span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="content">Message</label>
                                </div>
                                <div className="col-md-9">
                                    <CKEditor
                                        config={{
                                            extraAllowedContent: "div(*)",
                                            allowedContent: true,
                                            height: 300,
                                        }}
                                        initData={this.state.message}
                                        onChange={this.handleEditorChange}
                                    />
                                    <span className="text-danger">
                                        {errors.message}
                                    </span>
                                </div>
                            </div>
                        </form>
                        <br />
                        <div className="modal-footer">
                            {
                                loader && <i className="fa fa-spinner fa-spin"></i>
                            }
                            {
                                !loader && (
                                    <button form="send-email" type="submit" className="btn btn-primary">
                                        Send
                                    </button>
                                )
                            }
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </>

        );
    }
}

Newsletter.propTypes = {
    newsletteremail: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, { newsletteremail })(
    withRouter(Newsletter)
);