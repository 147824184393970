import React, { Component, Fragment } from "react";
import { withRouter } from 'react-router-dom';
import ReactDatatable from "@ashvin27/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import keys from "../../actions/config";
import * as moment from "moment";

const url = keys.baseUrl;
class Users extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "created_date",
        text: "Date & Time",
        className: "date",
        align: "left",
        sortable: false,
        cell: record => {

          return (
            <Fragment>
              {moment.utc(record.created_date).local().format("DD-MM-YYYY  h:mm a ")}

            </Fragment>
          )


        }
      },
      // {
      //   key: "_id",
      //   text: "ID #",
      //   className: "userId",
      //   align: "left",
      //   sortable: false,
      // },
      {
        key: "orderbookId",
        text: "Trade ID",
        className: "userId",
        align: "left",
        sortable: false,
      },
      {
        key: "buyerEmail",
        text: "Buyer Email",
        className: "userId",
        align: "left",
        sortable: false,
      },
      {
        key: "sellerEmail",
        text: "Seller Email",
        className: "userId",
        align: "left",
        sortable: false,
      },
      {
        key: "raised_by",
        text: "Post By",
        className: "userId",
        align: "left",
        sortable: false,
      },
   

      {
        key: "status",
        text: "Status",
        className: "userId",
        align: "left",
        sortable: false,
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              {record.status != "Open" && (
                <button
                  title="View"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.viewRecord(record)}
                  style={{ marginRight: "5px" }}
                >
                  <i className="fa fa-eye"></i>
                </button>
              )}
              {record.status == "Open" && (
                <button
                  title="Log"
                  title="Dispute"
                  className="btn btn-danger btn-sm"
                  onClick={() => this.viewRecord(record)}
                  style={{ marginRight: "5px" }}
                >
                  <i className="fa fa-gavel"></i>
                </button>
              )}
            </Fragment>
          );
        },
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Buyhistory",
      no_data_text: "No Record found!",
      sort: { column: "Created date", order: "desc" },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
      defaultSortAsc: true,
    };

    this.state = {
      currentRecord: {
        records: [],
        responsive: true,
      },
    };

    this.getData = this.getData.bind(this);
  }

  viewRecord(record) {
    this.setState({ currentRecord: record });
    this.props.history.push("/p2ptradeview/" + record.orderbookId);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    axios
      .post(url + "adminapi/p2pdisputelist")
      .then((res) => {
        this.setState({ records: res.data.data });
      })
      .catch();
  }

  render() {
    return (
      <>

        <div id="page-content-wrapper">
          <div className="container-fluid">
            <h3 className="mt-2 text-secondary">P2P Dispute List</h3>
            <div style={{ overflow: "scroll" }}>
              <ReactDatatable
                responsive={this.state.responsive}
                config={this.config}
                records={this.state.records}
                columns={this.columns}
              />{" "}
            </div>
          </div>
        </div>
        <ToastContainer />
      </>

    );
  }
}

Users.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
});

export default connect(mapStateToProps)(withRouter(Users));