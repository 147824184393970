import React, { Component, Fragment } from "react";
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SubCategoryAddModal from "../partials/SubcategoryAddModal.jsx";
import SubCategoryUpdateModal from "../partials/SubcategoryUpdateModal.jsx";
import { toast, ToastContainer } from "react-toastify";
import $ from 'jquery';
import keys from "../../actions/config";
const url = keys.baseUrl;
class SubCategory extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "subcategoryName",
                text: "SubCategory",
                className: "currencyName",
                align: "left",
                sortable: false,
                width: 200
            },
            {
                key: "categoryName",
                text: "Main Category Name",
                className: "categoryname",
                align: "left",
                sortable: false,
                cell: record => {
                    if (record.maincategoryId) {

                        return (record.maincategoryId.categoryName)

                    }
                }

            },
            {
                key: "status",
                text: "status",
                className: "status",
                align: "left",
                sortable: false,
                cell: record => {
                    return (record.status == 1 ? ('Active') : ('In-Active'))
                }
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-subcategory-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Currency",
            no_data_text: 'No Category found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
                id: "",
                subcategoryName: '',
                status: '',
                categoryName: ''
            },
            errors: {}

        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        $("#add-subcategory-modal").modal("hide");

        this.getData()
    }

    getData() {
        axios
            .get(url + "api/sub-category")
            .then(res => {
                console.log("Response of subb", res)
                this.setState({ records: res.data })
            })
            .catch()
    }

    editRecord(record) {
        $("#update-subcategory-modal").find(".text-danger").hide();
        $("#add-subcategory-modal").find(".text-danger").hide();
        this.setState({ currentRecord: record, errors: {} });
    }
    addRecord() {
        $("#add-subcategory-modal").find(".text-danger").hide();
    }


    deleteRecord(record) {
        console.log(record);
        if (!window.confirm('Are you sure you want to delete this Sub category?')) { return false; }
        axios
            .post(url + "api/subcategory-delete", { _id: record._id })
            .then(res => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <>

                <SubCategoryAddModal />
                <SubCategoryUpdateModal record={this.state.currentRecord} />
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <button onClick={() => this.addRecord()} className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-subcategory-modal"><FontAwesomeIcon icon={faPlus} /> Add Sub Category</button>
                        <h3 className="mt-2 text-secondary"> Sub Category List</h3>
                        <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            onPageChange={this.pageChange.bind(this)}
                        />
                    </div>
                </div>
                <ToastContainer />
            </>

        );
    }

}

SubCategory.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(withRouter(SubCategory));
