import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";

//import components

import { withRouter } from 'react-router-dom';

//import Actions
import { getUserBalnce } from "../../actions/userActions";

class Userbalance extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "user.email",
        text: "Email",
        className: "balance",
        align: "left",
        sortable: false,
        cell: (record) => {
          return record.userId ? record.userId.email ? record.userId.email : "-":"-";
        },
      },
      {
        key: "user.phoneNo",
        text: "Phone No",
        className: "balance",
        align: "left",
        sortable: false,
        cell: (record) => {
          return record.userId ? record.userId.phoneNo ? `${record.userId.phoneNo}` : "-":"-";
        },
      },

      {
        key: "spotwallet",
        text: "Spot Wallet",
        className: "balance",
        align: "left",
        sortable: false,
        cell: (record) => {
          return record.spotwallet > 0 ? record.spotwallet : 0;
        },
      },
      {
        key: "p2pbalance",
        text: "P2P Wallet",
        className: "derivativeWallet",
        align: "left",
        sortable: false,
        cell: (record) => {
          return record.p2pbalance > 0 ? record.p2pbalance : 0;
        },
      },
      {
        key: "currency",
        text: "Currency",
        className: "currency",
        align: "left",
        sortable: false,
        cell: (record) => {
          return record.currency ? record.currency.currencySymbol ? record.currency.currencySymbol : "-":"-";
        },
      },
      {
        key: "action",
        text: "PassBook",
        className: "subject",
        align: "left",
        sortable: false,
        cell: (record) => {
            if (record&&record.userId&&record.userId.uniqueId != 'undefined') {
                return (
                    <span>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => this.props.history.push("/passbookHistory/" + record.userId.uniqueId+"/"+record.currency.currencySymbol)}
                            style={{ marginRight: "5px" }}
                        >
                            View
                        </button>
                    </span>
                );
            }
        },
    },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Userbalance",
      no_data_text: "No Userbalance found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter ...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
      loader: false,
      page: 1,
      limit: 10,
      count: 0,
      responsive: true,
      loader: false,
    };
    this.getUserBalanceList = this.getUserBalanceList.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
  }

  componentDidMount() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };

    this.getUserBalanceList(reqData);
  }

  async getUserBalanceList(reqData) {
    try {
      this.setState({ loader: true });

      const { status, loading, result } = await getUserBalnce(reqData);

      this.setState({ loader: false });
      if (status == "success") {
        console.log("aaaaaaaaaaaaaaaa", result.data)
        this.setState({ count: result.count, records: result.data });
      }
    } catch (err) { }
  }

  handlePagination(index) {
    let reqData = {
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    };
    this.getUserBalanceList(reqData);
    this.setState({
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    });
  }

  render() {
    const { count ,loader} = this.state;
    return (


      <div id="page-content-wrapper">
        <div className="container-fluid">
          <h3 className="mt-2 text-secondary">User balance List</h3>
          <ReactDatatable
            config={this.config}
            records={this.state.records}
            columns={this.columns}
            total_record={count}
            dynamic={true}
            loading={loader}
            onChange={this.handlePagination}
          />
        </div>
      </div>


    );
  }
}

export default withRouter(Userbalance);