let key = {};
// if (process.env.NODE_ENV === "production") {
// console.log(
//   "Set Production Config process.env.REACT_APP_MODEprocess.env.REACT_APP_MODE",
//   process.env.REACT_APP_MODE
// );
if (process.env.REACT_APP_MODE === "production") {
  console.log("Set Production Config");
  const API_URL = "https://producationapi.mudra.exchange";

  // Live Env
  key = {
    secretOrKey: "FxUum76z",
    Recaptchakey: "6Lf-oQAaAAAAAHsxE5WyOHwmKav2lOfeL5KeA7AV", //local
    API_URL: "https://producationapi.mudra.exchange",
    FRONT_URL: "https://www.mudra.exchange/",
    ADMIN_URL: "https://controls.mudra.exchange",
    getGeoInfo: "https://ipapi.co/json/",
    BINANCE: {
      Email: "mudraex@gmail.com",
      PhoneNo: "9873188583",
    },
    WAZIRX: {
      Email: "mudraex@gmail.com",
      PhoneNo: "9873188583",
    },
  };
} else if (process.env.REACT_APP_MODE === "development") {
  console.log("Set Production Config");
  const API_URL = "https://mudraapi.wealwin.com";

  //Development ENV

  key = {
    secretOrKey: "FxUum76z",
    Recaptchakey: "6Lf-oQAaAAAAAHsxE5WyOHwmKav2lOfeL5KeA7AV", //local
    API_URL: "https://mudraapi.wealwin.com",
    FRONT_URL: "https://mudra.wealwin.com/",
    ADMIN_URL: "https://mudra.wealwin.com/admin",
    getGeoInfo: "https://ipapi.co/json/",
    BINANCE: {
      Email: "mudraex@gmail.com",
      PhoneNo: "9873188583",
    },
    WAZIRX: {
      Email: "mudraex@gmail.com",
      PhoneNo: "9873188583",
    },
  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://localhost";
  key = {
    secretOrKey: "FxUum76z",
    Recaptchakey: "6LdpeoQUAAAAAHwFEDfpcA-W5-leSH8548lZWWeb", //local
    API_URL: `${API_URL}:2053`,

    getGeoInfo: "https://ipapi.co/json/",

    socialMedia: {
      facebook: {
        appId: "1034988646970193",
      },
      linkedIn: {
        clientId: "78szlpfkw7ee7s",
        redirectUrl: "https://99893158a13c.ngrok.io/signup",
        oauthUrl:
          "https://www.linkedin.com/o",
        scope: "r_liteprofile%",
        state: "",
      },
    },
    BINANCE: {
      Email: "mudraex@gmail.com",
      PhoneNo: "9873188583",
    },
    WAZIRX: {
      Email: "mudraex@gmail.com",
      PhoneNo: "9873188583",
    },
  };
}

export default key;
