import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { addarticle } from "../../actions/category";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";
import axios from "axios";
// import keys from "../../actions/config";
import DatePicker from "react-datepicker";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import keys from "../../config/index";

const url = keys.API_URL;
// const url = keys.baseUrl;
const imageurl = keys.API_URL;

const statusOptions = [
  { value: "New", label: "New" },
  // { value: "Update", label: "Update" },
  { value: "Deactive", label: "Deactive" },
];

const initialFormValue = {
  id: "",
  title: "",
  content: "",
  image: "",
  articlename: "",
  metatitle: "",
  metadescription: "",
  metakeywords: "",
  alttag: "",
  author: "",
  social_link1: "",
  social_link2: "",
  social_link3: "",
  social_link4: "",
  social_link5: "",
  alttag_pro: "",
  slug: "",
  query_image: "",
  curimage: null,
  title: "",
  promotion_image: "",
  query_image_pro: "",
  curimage_pro: null,
  maincategoryId: {
    value: "",
    label: "",
  },
  errors: "",
  slugerror: '',
  authorImage: "img",
  curimage_auther: '',
  query_image_auther: '',
  status: 'New',
  date: new Date(),
};

class SubcategoryAddModal extends React.Component {
  constructor() {
    super();

 
    this.state = {
      formValue: initialFormValue,
      errors: {},
    };

    this.handleEditorChange = this.handleEditorChange.bind(this);
  }
  componentDidMount() {
    this.getData();
  }

  getData() {
    axios.get(url + "/api/getBlogCategoryList").then((category) => {
      var categoryarray = [];
      category.data.data.map((item, i) => {
        const name = item.category_name;
        const value = item._id;
        const obj = { value: value, label: name };
        categoryarray.push(obj);
      });
      // console.log(currencyarray,'currencyarray');
      this.setState({ categoryName1: categoryarray, email_assigned: true });
    });

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      $("#add-article-modal").find(".text-danger").show();
      this.setState({
        errors: nextProps.errors,
      });
    } else {
      this.setState({
        errors: "",
      });
    }
    //console.log(nextProps.auth.currencyadd.data,'shdbjksfcgdsyfvfdsuy');
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.currencyadd !== undefined &&
      nextProps.auth.currencyadd.data !== undefined &&
      nextProps.auth.currencyadd.data.message !== undefined
    ) {
      $("#add-article-modal").modal("hide");
      this.setState({
        errors: "",
      });
      toast(nextProps.auth.currencyadd.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      nextProps.auth.currencyadd = undefined;
      this.setState({
        errors: "",
      });
    }
  }
  handleEditorChange(content, editor) {
    this.setState({ content });
  }

  handleChange = (selectedOption) => {
    this.setState({ categoryName: selectedOption.value });
    console.log(`Option selected:`, selectedOption);
    var data = {
      categoryid: selectedOption.value,
    };
    axios
      .post(url + "api/sub-category-select-find", data)
      .then((subcategory) => {
        console.log("Response data====>", subcategory);
        var subcategoryarray = [];
        subcategory.data.map((item, i) => {
          const name = item.subcategoryName;
          const value = item._id;
          const obj = { value: value, label: name };
          subcategoryarray.push(obj);
        });
        console.log(subcategoryarray, "subcategoryarray");
        this.setState({
          subcategoryName: subcategoryarray,
          email_assigned: true,
        });
      });
  };

  handleDate = (date) => {
    console.log("datedatedatedate", date)
    let formData = { ...this.state.formValue, ...{ date: date } };
    this.setState({ formValue: formData });
  };
  handleStatus = (e) => {
    e.preventDefault();
    let { name, id, value } = e.target;
    let formData = { ...this.state.formValue, ...{ [id]: value } };
    this.setState({ formValue: formData });
  };


  handleChange1 = (selectedOption) => {
    this.setState({ subcategorydropdown: selectedOption });
    // console.log(`Option selected:`, selectedOption );
  };
  // onChange = e => {
  //   this.setState({ [e.target.id]: e.target.value });
  // };

  onChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    console.log(name, value, "=====");
    let formData = { ...this.state.formValue, ...{ [name]: value } };
    this.setState({ formValue: formData });
    let errData = { ...this.state.errors, ...{ [name]: "" } };
    this.setState({ errors: errData });
  };

  // handleChangefile = event => {
  //   this.setState({
  //     query_image: event.target.files[0]
  //   });
  // };

  handleChangefile = (event) => {
    console.log(
      URL.createObjectURL(event.target.files[0]),
      "event.target.files[0]"
    );
    // let formData = {
    //       'image': URL.createObjectURL(event.target.files[0]),
    //     }
    //     this.setState({ formValue: formData })
    this.setState({
      curimage: URL.createObjectURL(event.target.files[0]),
      query_image: event.target.files[0],
    });
  };

  handleChangefile1 = (event) => {
    console.log(
      URL.createObjectURL(event.target.files[0]),
      "event.target.files[0]"
    );
    // let formData = {
    //       'image': URL.createObjectURL(event.target.files[0]),
    //     }
    //     this.setState({ formValue: formData })
    this.setState({
      curimage_pro: URL.createObjectURL(event.target.files[0]),
      query_image_pro: event.target.files[0],
    });
  };

  handleCategory = (SelectedCategory) => {
    console.log(SelectedCategory, "SelectedCategory");
    // let formData = {
    //      'articlename': SelectedCategory
    //    }
    // this.setState({ ...this.state.formValue,...formData })
    let formData = {
      ...this.state.formValue,
      ...{ maincategoryId: SelectedCategory },
    };
    console.log(formData, "dddddddddd");
    this.setState({ formValue: formData });
  };


  handleChangefileauther = (event) => {
    console.log(
      URL.createObjectURL(event.target.files[0]),
      "event.target.files[0]"
    );
    // let formData = {
    //       'image': URL.createObjectURL(event.target.files[0]),
    //     }
    //     this.setState({ formValue: formData })
    this.setState({
      curimage_auther: URL.createObjectURL(event.target.files[0]),
      query_image_auther: event.target.files[0],
    });
  };


  onarticleAdd = async (e) => {
    e.preventDefault();
    // const newarticle = {
    //   articledetails: this.state.articlename,
    //   maincategoryId: this.state.categoryName,
    //   subcategoryId: this.state.subcategorydropdown.value,
    //   content: this.state.content,
    //   query_image: this.state.query_image

    // };

    // this.props.addarticle(newarticle);
    // console.log("newarticle***********", this.state.SelectedCategory);
    const data = new FormData();

    const {
      maincategoryId,
      selectedOption,
      subcategoryId,
      subcategoryName1,
      subcategoryoption,
      subcategorydropdown,
      metatitle,
      metadescription,
      metakeywords,
      alttag,
      author,
      social_link1,
      social_link2,
      social_link3,
      social_link4,
      social_link5,
      alttag_pro,
      query_image_pro,
      slug,
      categoryName,
      title,
      image,
      promotion_image,
      authorDetails,
      authorImage,
      status,
      date
    } = this.state.formValue;
    // if ((slug == "") || (slug == "undefined") || (slug == undefined)) {
    //   return this.setState({ slugerror: "Required" })
    // }

    // let resplchane = await slug.replace(/[^\w\s]/gi, '-')
    // console.log("resplchaneresplchaneresplchane", resplchane)
    // if ((resplchane == "") || (resplchane == "undefined") || (resplchane == undefined)) {
    //   return this.setState({ slugerror: "Required" })
    // }


    data.append("maincategoryId", maincategoryId.value);

    data.append("title", title);
    // data.append("subcategoryId", this.state.subcategorydropdown.value);
    data.append("content", this.state.content);
    data.append("file", this.state.query_image);
    data.append("alttag", alttag);
    data.append("author", author);

    data.append("metatitle", metatitle);
    data.append("metadescription", metadescription);

    data.append("metakeywords", metakeywords);
    data.append("social_link1", social_link1);
    data.append("social_link2", social_link2);
    data.append("authorDetails", authorDetails);
    data.append("authorImage", this.state.query_image_auther);
    data.append("social_link3", social_link3);

    data.append("social_link4", social_link4);

    data.append("social_link5", social_link5);

    data.append("file_pro", this.state.query_image_pro);
    data.append("alttag_pro", alttag_pro);
    data.append("status", status);
    data.append("date", date);
    data.append("slug", slug);
    axios
      .post(url + "/api/blogAdd", data)
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          // this.getData();
          // window.location.reload(false);
          $("#add-article-modal").modal("hide");
        } else {

          $("#add-article-modal").modal("hide");
          // window.location.reload();

        }
      })
      .catch((err) => {
        console.log(err.response.data.errors, "err.response.data.errors");
        this.setState({ errors: err.response.data.errors });
      });
    // this.getData();
    // window.location.reload();
    // this.props.addarticle(data);
  };

  render() {
    //const { selectedOption, subcategoryName } = this.state.categoryName1;

    const {
      maincategoryId,
      subcategoryId,
      subcategoryName1,
      subcategoryoption,
      subcategorydropdown,
      metatitle,
      metadescription,
      metakeywords,
      alttag,
      author,
      social_link1,
      social_link2,
      social_link3,
      social_link4,
      social_link5,
      alttag_pro,
      query_image_pro,
      slug,
      categoryName,
      title,
      image,
      promotion_image,
      authorImage,
      authorDetails,
      status, date
    } = this.state.formValue;
    const { errors } = this.state;

    return (
      <div>
        <div className="modal fade" id="add-article-modal" data-reset="true" data-keyboard="false" data-backdrop="static">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Article</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form noValidate onSubmit={this.onarticleAdd} id="add-article">
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyName">Category</label>
                    </div>
                    <div className="col-md-9">
                      <Select
                        value={this.state.SelectedCategory}
                        // defaultValue={{
                        //   label: this.state.maincategoryId,
                        //   value: this.state.maincategoryId
                        // }}
                        onChange={this.handleCategory}
                        options={this.state.categoryName1}
                      />
                      {errors && errors.categoryErr && (
                        <span style={{ color: "red" }}>
                          {errors.categoryErr}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyName">Title</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={title}
                        id="title"
                        type="text"
                        name="title"
                        // error={errors.currencyName}
                        className={classnames("form-control", {
                          invalid: errors && errors.title,
                        })}
                      />
                      {errors && errors.title && (
                        <span style={{ color: "red" }}>{errors.title}</span>
                      )}
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <br />
                      <label htmlFor="query_image">Image</label>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="file"
                        accept="image/x-png,image/gif,image/jpeg"
                        onChange={this.handleChangefile}
                      />
                      {this.state.query_image && (
                        <img width="100px" src={this.state.curimage} />
                      )}
                      {errors && errors.imageErr && (
                        <span style={{ color: "red" }}>{errors.imageErr}</span>
                      )}
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">AltTag</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={alttag}
                        error={errors && errors.alttag}
                        name="alttag"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors && errors.alttag,
                        })}
                      />
                      <span style={{ color: "red" }}>
                        {errors && errors.alttag}
                      </span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">Author</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={author}
                        error={errors && errors.author}
                        name="author"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors && errors.author,
                        })}
                      />
                      <span style={{ color: "red" }}>
                        {errors && errors.author}
                      </span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">Author Details</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={authorDetails}
                        error={errors.authorDetails}
                        name="authorDetails"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.authorDetails,
                        })}
                      />
                      <span style={{ color: "red" }}>{errors.authorDetails}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <br />
                      <label htmlFor="authorImage">Auther Image</label>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="file"
                        accept="image/x-png,image/gif,image/jpeg"
                        onChange={this.handleChangefileauther}
                      />
                      <img width="100px" />
                      {this.state.curimage_auther ? (
                        <img width="100px" src={this.state.curimage_auther} />
                      ) : (
                        <img
                          className="img-fluid proofThumb mb-3"
                          src={imageurl + "/images/blogImages/" + authorImage}
                        />
                      )}
                    </div>
                  </div>


                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="content">Content</label>
                    </div>
                    <div className="col-md-9">
                      <Editor
                        apiKey="5vk89nvvi2zckrb2lp2ctyyolewhq1v3pzdiwb7at68h40a5"
                        initialValue=""
                        value={this.state.content}
                        onEditorChange={this.handleEditorChange}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount,image code",
                          ],
                          // images_upload_url: "postAcceptor.php",

                          /* we override default upload handler to simulate successful upload*/

                          images_upload_handler: function (
                            blobInfo,
                            success,
                            failure
                          ) {
                            let data = new FormData();
                            data.append(
                              "file",
                              blobInfo.blob(),
                              blobInfo.filename()
                            );
                            axios
                              .post(url + "/api/tinyimgupload", data)

                              .then(function (res) {
                                console.log(res, "kr resss");
                                success(
                                  url + "/images/tinymce/" + res.data.result
                                );
                              })
                              .catch(function (err) {
                                failure("HTTP Error: " + err.message);
                              });
                          },

                          menubar:
                            "file edit view insert format tools table tc help",

                          toolbar:
                            "undo redo code | formatselect | bold italic backcolor | \
                                               alignleft aligncenter alignright alignjustify | \
                                               bullist numlist outdent indent | removeformat | help",
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {errors && errors.contentErr}
                      </span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">Meta Title</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={metatitle}
                        error={errors && errors.metatitle}
                        name="metatitle"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors && errors.metatitle,
                        })}
                      />
                      <span style={{ color: "red" }}>
                        {errors && errors.metatitle}
                      </span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">Meta Description</label>
                    </div>
                    <div className="col-md-9">
                      <textarea
                        onChange={this.onChange}
                        value={metadescription}
                        error={errors && errors.metadescription}
                        name="metadescription"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors && errors.metadescription,
                        })}
                      />
                      <span style={{ color: "red" }}>
                        {errors && errors.metadescription}
                      </span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">Meta Keywords</label>
                    </div>
                    <div className="col-md-9">
                      <textarea
                        onChange={this.onChange}
                        value={metakeywords}
                        error={errors && errors.metakeywords}
                        name="metakeywords"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors && errors.metakeywords,
                        })}
                      />
                      <span style={{ color: "red" }}>
                        {errors && errors.metakeywords}
                      </span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="social_link1">Twitter URL</label>
                    </div>
                    <div className="col-md-6">
                      <input
                        onChange={this.onChange}
                        value={social_link1}
                        name="social_link1"
                        type="text"
                        error={errors && errors.social_link1}
                        className={classnames("form-control", {
                          invalid: errors && errors.social_link1,
                        })}
                      />
                      <span style={{ color: "red" }}>
                        {errors && errors.social_link1}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="social_link1">Medium URL</label>
                    </div>
                    <div className="col-md-6">
                      <input
                        onChange={this.onChange}
                        value={social_link2}
                        name="social_link2"
                        type="text"
                        error={errors && errors.social_link2}
                        className={classnames("form-control", {
                          invalid: errors && errors.social_link2,
                        })}
                      />
                      <span style={{ color: "red" }}>
                        {errors && errors.social_link2}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="social_link1">Telegram Link</label>
                    </div>
                    <div className="col-md-6">
                      <input
                        onChange={this.onChange}
                        value={social_link3}
                        name="social_link3"
                        type="text"
                        error={errors && errors.social_link3}
                        className={classnames("form-control", {
                          invalid: errors && errors.social_link3,
                        })}
                      />
                      <span style={{ color: "red" }}>
                        {errors && errors && errors.social_link3}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="social_link1">Facebook URL</label>
                    </div>
                    <div className="col-md-6">
                      <input
                        onChange={this.onChange}
                        value={social_link4}
                        name="social_link4"
                        type="text"
                        error={errors && errors.social_link4}
                        className={classnames("form-control", {
                          invalid: errors && errors.social_link4,
                        })}
                      />
                      <span style={{ color: "red" }}>
                        {errors && errors.social_link4}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="social_link1">Linkedin URL</label>
                    </div>
                    <div className="col-md-6">
                      <input
                        onChange={this.onChange}
                        value={social_link5}
                        name="social_link5"
                        type="text"
                        error={errors && errors.social_link5}
                        className={classnames("form-control", {
                          invalid: errors && errors.social_link5,
                        })}
                      />
                      <span style={{ color: "red" }}>
                        {errors && errors.social_link5}
                      </span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <br />
                      <label htmlFor="query_image">Promotion Image</label>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="file"
                        accept="image/x-png,image/gif,image/jpeg"
                        onChange={this.handleChangefile1}
                      />
                      <img width="100px" />
                      {this.state.query_image_pro ? (
                        <img
                          className="img-fluid proofThumb mb-3"
                          src={this.state.curimage_pro}
                        />
                      ) : (
                        <img
                          className="img-fluid proofThumb mb-3"
                          src={
                            imageurl + "/images/blogImages/" + promotion_image
                          }
                        />
                      )}
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">AltTag</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={alttag_pro}
                        error={errors && errors.alttag_pro}
                        name="alttag_pro"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors && errors.alttag_pro,
                        })}
                      />
                      <span style={{ color: "red" }}>
                        {errors && errors.alttag_pro}
                      </span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="slug">Slug</label>
                    </div>
                    <div className="col-md-9">
                      <textarea
                        onChange={this.onChange}
                        value={slug}
                        error={errors && errors.slug}
                        name="slug"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors && errors.slug,
                        })}
                      />
                      <span style={{ color: "red" }}>{this.state.slugerror}</span>
                      <span style={{ color: "red" }}>
                        {errors && errors.slug}
                      </span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">Date</label>
                    </div>
                    <div className="col-md-9">
                      <div>
                        <DatePicker
                          selected={date}
                          onChange={this.handleDate}
                          className={classnames("form-control", {
                            invalid: errors.date
                          })}
                          dateFormat="MMMM d, yyyy h:mm aa"
                          showTimeSelect
                        />
                        <span style={{ color: "red" }}>{errors.date}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="password2">Status</label>
                    </div>
                    <div className="col-md-9">

                      <Form.Control
                        as="select"
                        custom
                        name={"status"}
                        id={"status"}
                        value={status}
                        onChange={this.handleStatus}
                      >

                        {statusOptions &&
                          statusOptions.length > 0 &&
                          statusOptions.map((item, key) => {
                            return (
                              <option key={key} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                      </Form.Control>
                      <span style={{ color: "red" }}>{errors.status}</span>
                    </div>
                  </div>


                  {/*<div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="first_currency">Main Category</label>
                    </div>
                    <div className="col-md-9">
                      <Select
                        value={selectedOption}
                        defaultValue={{
                          label: this.state.maincategoryId,
                          value: this.state.maincategoryId
                        }}
                        onChange={this.handleChange}
                        options={this.state.categoryName1}
                      />
                      <span style={{ color: "red" }}>
                        {errors.first_currency}
                      </span>
                    </div>
                  </div>*/}

                  {/*}  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="first_currency">Sub Category</label>
                    </div>
                    <div className="col-md-9">
                      {this.state.subcategoryName.length == 0 ? (
                        <span style={{ color: "red" }}>
                          Please select another category
                        </span>
                      ) : (
                        <Select
                          value={subcategoryName}
                          defaultValue={{
                            label: this.state.maincategoryId,
                            value: this.state.maincategoryId
                          }}
                          onChange={this.handleChange1}
                          options={this.state.subcategoryName}
                        />
                      )}

                      <span style={{ color: "red" }}>
                        {errors.first_currency}
                      </span>
                    </div>
                  </div>*/}
                </form>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  form="add-article"
                  type="submit"
                  className="btn btn-primary"
                >
                  Add Article
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SubcategoryAddModal.propTypes = {
  addarticle: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(withRouter(SubcategoryAddModal));
