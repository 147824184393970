import React from "react";
import classnames from "classnames";
import { Modal, Form } from "react-bootstrap";
import Select from "react-select";

// import action
import { addStaking } from "../../actions/staking";

// import lib
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty";

const initialFormValue = {
  currencyId: "",
  minimumAmount: 0,
  maximumAmount: 0,
  redemptionPeriod: "",
  type: [],
  flexibleAPY: "",
  totalDateForFlexibleEnd:'',
  periodList: [{ days: "", APY: "" }],
};

class StakingAddModal extends React.Component {
  constructor() {
    super();
    this.state = {
      formValue: initialFormValue,
      errors: {},
      loader: false,
      typeOption: [
        {
          label: "Fixed",
          value: "fixed",
        },
        {
          label: "Flexible",
          value: "flexible",
        },
      ],
    };

    this.handleSelect = this.handleSelect.bind(this);
  }

  handleChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let formData = { ...this.state.formValue, ...{ [name]: value } };
    this.setState({ formValue: formData });
    let clearError={...this.state.errors,...{ [name]: ""}}
    this.setState({errors:clearError})
  };

  handleSelect(selectedOption) {
    let formData = this.state.formValue;
    if (selectedOption && selectedOption.length > 0) {
      formData = {
        ...formData,
        ...{
          type: selectedOption.map((el) => {
            return el.value;
          }),
        },
      };
    } else {
      formData = {
        ...formData,
        ...{
          type:
            selectedOption &&
            selectedOption.map((el) => {
              return el.value;
            }),
        },
      };
    }
    let clearError={...this.state.errors,...{ ["type"]: ""}}
    this.setState({errors:clearError})
    this.setState({ formValue: formData });
  }

  // handle input change
  handleInputChange = (e, index) => {
    const { periodList } = this.state.formValue;
    const { name, value } = e.target;
    const list = [...periodList];
    list[index][name] = value;
    let formData = { ...this.state.formValue, ...{ periodList: list } };
    this.setState({ formValue: formData });
  };

  // handle click event of the Remove button
  handleRemoveClick = (index) => {
    const { periodList } = this.state.formValue;
    const list = [...periodList];
    list.splice(index, 1);
    let formData = { ...this.state.formValue, ...{ periodList: list } };

    this.setState({ formValue: formData });
  };

  // handle click event of the Add button
  handleAddClick = () => {
    const { periodList } = this.state.formValue;
    const list = [...periodList];
    list.push({ days: "", APY: "" });
    let formData = { ...this.state.formValue, ...{ periodList: list } };
    this.setState({ formValue: formData });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loader: true });
    const { formValue } = this.state;
    const { fetchData } = this.props;

    let reqData = {
      currencyId: formValue.currencyId,
      minimumAmount: formValue.minimumAmount,
      maximumAmount: formValue.maximumAmount,
      redemptionPeriod: formValue.redemptionPeriod,
      type: formValue.type,
      flexibleAPY: formValue.flexibleAPY,
      periodList: formValue.periodList,
      totalDateForFlexibleEnd:formValue.totalDateForFlexibleEnd,
    };

    try {
      const { status, loading, message, error, result } = await addStaking(
        reqData
      );
      this.setState({ loader: false });
      if (status == "success") {
        toastAlert("success", message, "addstaking");
        this.handleClose();
        fetchData();
      } else {
        if (error) {
          this.setState({ errors: error });
        }
        toastAlert("error", message, "addstaking");
      }
    } catch (err) {}
  };

  handleClose = () => {
    const { onHide } = this.props;
    onHide();
    this.setState({ formValue: initialFormValue, errors: {} });
  };

  render() {
    const { errors, typeOption, loader } = this.state;
    const {
      currencyId,
      minimumAmount,
      maximumAmount,
      redemptionPeriod,
      type,
      flexibleAPY,
      periodList,
      totalDateForFlexibleEnd
    } = this.state.formValue;
    const { isShow, currencyOption } = this.props;

    return (
      <div>
        <Modal
          show={isShow}
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
          backdrop="static"
          // scrollable={true}
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">Add Staking</h4>
          </Modal.Header>
          <Modal.Body>
            <form noValidate onSubmit={this.handleSubmit} id="add-staking">
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="identifier">Staking Currency</label>
                </div>
                <div className="col-md-9">
                  <Form.Control
                    as="select"
                    custom
                    name={"currencyId"}
                    value={currencyId}
                    onChange={this.handleChange}
                  >
                    <option value={""}>{"Select currency"}</option>
                    {currencyOption &&
                      currencyOption.length > 0 &&
                      currencyOption.map((item, key) => {
                        return (
                          <option key={key} value={item._id}>
                            {item.currencySymbol}
                          </option>
                        );
                      })}
                  </Form.Control>
                  <span className="text-danger">{errors.currencyId}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="minimum">Minimum Amount</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={minimumAmount}
                    error={errors.minimumAmount}
                    name="minimumAmount"
                    type="number"
                    className={classnames("form-control", {
                      invalid: errors.minimumAmount,
                    })}
                  />
                  <span className="text-danger">{errors.minimumAmount}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="minimum">Maximum Amount</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={maximumAmount}
                    error={errors.maximumAmount}
                    name="maximumAmount"
                    type="number"
                    className={classnames("form-control", {
                      invalid: errors.maximumAmount,
                    })}
                  />
                  <span className="text-danger">{errors.maximumAmount}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="minimum">Redemption Period (In Days)</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={redemptionPeriod}
                    error={errors.redemptionPeriod}
                    name="redemptionPeriod"
                    type="number"
                    className={classnames("form-control", {
                      invalid: errors.redemptionPeriod,
                    })}
                  />
                  <span className="text-danger">{errors.redemptionPeriod}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="flexible">
                    <span>Type</span>
                  </label>
                </div>
                <div className="col-md-9">
                  <Select
                    value={
                      typeOption && typeOption.length > 0
                        ? typeOption.filter((el) => {
                            if (!isEmpty(type) && type.includes(el.value)) {
                              return el;
                            }
                          })
                        : []
                    }
                    isMulti
                    name="colors"
                    options={typeOption}
                    onChange={this.handleSelect}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                  <span className="text-danger">{errors.type}</span>
                </div>
              </div>

              {!isEmpty(type) && type.includes("flexible") && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="minimum">Flexible APY(%)</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      onChange={this.handleChange}
                      value={flexibleAPY}
                      error={errors.flexibleAPY}
                      name="flexibleAPY"
                      type="number"
                      className={classnames("form-control", {
                        invalid: errors.flexibleAPY,
                      })}
                    />
                    <span className="text-danger">{errors.flexibleAPY}</span>
                  </div>
                </div>
              )}
              {!isEmpty(type) && type.includes("flexible") && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="minimum">Total Staking Days</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      onChange={this.handleChange}
                      value={totalDateForFlexibleEnd}
                      error={errors.totalDateForFlexibleEnd}
                      name="totalDateForFlexibleEnd"
                      type="number"
                      className={classnames("form-control", {
                        invalid: errors.totalDateForFlexibleEnd,
                      })}
                    />
                    <span className="text-danger">{errors.totalDateForFlexibleEnd}</span>
                  </div>
                </div>
              )}
              {!isEmpty(type) &&
                type.includes("fixed") &&
                periodList &&
                periodList.length > 0 &&
                periodList.map((item, key) => {
                  return (
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="minimum">Period {key + 1}</label>
                      </div>
                      <div className="box row col-md-9">
                        <div className="col-md-3">
                          <input
                            name="days"
                            placeholder="Enter Days"
                            value={item.days}
                            onChange={(e) => this.handleInputChange(e, key)}
                            className={classnames("form-control")}
                          />
                        </div>
                        <div className="col-md-3">
                          <input
                            name="APY"
                            placeholder="Enter APY(%)"
                            value={item.APY}
                            onChange={(e) => this.handleInputChange(e, key)}
                            className={classnames("form-control")}
                          />
                        </div>
                        <div className="btn-box col-md-3">
                          {periodList.length !== 1 && (
                            <button
                              className="btn btn-primary"
                              onClick={() => this.handleRemoveClick(key)}
                            >
                              Remove
                            </button>
                          )}
                          {periodList.length - 1 === key && (
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={this.handleAddClick}
                            >
                              Add
                            </button>
                          )}
                        </div>
                      
                      </div>
                    </div>
                  );
                })}
                  <span className="text-danger">{errors.periodList}</span>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={this.handleClose}
            >
              Close
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-primary"
              disabled={loader}
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default StakingAddModal;
