import axios from '../config/axios';

export const categoryAdd = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/adminapi/supportCategory`,
            'data': data
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.result.messages,
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const categoryList = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/supportCategory`,
            'params': data
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const categoryUpdate = async (data) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `/adminapi/supportCategory`,
            'data': data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.result.messages,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const TicketList = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/ticketList`,
            'params': data
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}
export const getMessage = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/ticketMessage`,
            'params': data
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const replyMsg = async (data) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `/adminapi/ticketMessage`,
            data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const closeTicket = async (data) => {
    try {
        let respData = await axios({
            'method': 'delete',
            'url': `/adminapi/ticketMessage`,
            data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}