import React from "react";
import classnames from "classnames";
import { Modal, Form } from "react-bootstrap";

// import action
import { updateCurrency } from "../../actions/currency";

// import lib
import { toastAlert } from "../../lib/toastAlert";
import fileObjectUrl from "../../lib/fileObjectUrl";
import isEmpty from "../../lib/isEmpty";

const initialFormValue = {
  currencyId: "",
  currencyName: "",
  currencySymbol: "",
  type: "crypto",
  withdrawFee: 0,
  minimumWithdraw: 0,
  currencyImage: "",
  bankName: "",
  accountNo: "",
  holderName: "",
  bankcode: "",
  country: "",
  tokenType: "",
  minABI: "",
  contractAddress: "",
  depositFee: 0,
  minimumdeposit: 0,
  decimals: 0,
  status: "active",
  isPrimary: false,
  displaypriority: 1,
  withdrawLimit: 100,
  CoinpaymetNetWorkFee: 0.1,
  gateWay: "CoinPayment",
  withdrawFeeType: "percentage",
  withdrawFeeFlat: 0.1,
  burnFee: 0,
};

class CurrencyUpdateModal extends React.Component {
  constructor() {
    super();
    this.state = {
      loader: false,
      formValue: initialFormValue,
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    const { record, imageUrl } = nextProps;
    if (record) {
      console.log("recordrecordrecordrecordrecordrecord", record);
      if (record.type == "crypto") {
        let formData = {
          currencyId: record._id,
          currencyName: record.currencyName,
          currencySymbol: record.currencySymbol,
          currencyImage: isEmpty(record.currencyImage)
            ? ""
            : imageUrl + record.currencyImage,
          type: record.type,
          withdrawFee: record.withdrawFee,
          minimumWithdraw: record.minimumWithdraw,
          status: record.status,
          displaypriority: record.displaypriority,
          gateWay: record.gateWay,
          CoinpaymetNetWorkFee: isEmpty(record.CoinpaymetNetWorkFee)
            ? 0.1
            : record.CoinpaymetNetWorkFee,
          isPrimary: false,
          withdrawLimit: isEmpty(record.withdrawLimit)
            ? 100
            : record.withdrawLimit,
          depositFee: isEmpty(record.depositFee) ? 0 : record.depositFee,
          minimumdeposit: isEmpty(record.minimumdeposit)
            ? 0
            : record.minimumdeposit,
          withdrawFeeType: isEmpty(record.withdrawFeeType)
            ? "percentage"
            : record.withdrawFeeType,
          withdrawFeeFlat: isEmpty(record.withdrawFeeFlat)
            ? 0
            : record.withdrawFeeFlat,
        };
        this.setState({ formValue: formData });
      } else if (record.type == "fiat") {
        let formData = {
          currencyId: record._id,
          currencyName: record.currencyName,
          currencySymbol: record.currencySymbol,
          currencyImage: isEmpty(record.currencyImage)
            ? ""
            : imageUrl + record.currencyImage,
          type: record.type,
          withdrawFee: record.withdrawFee,
          minimumWithdraw: record.minimumWithdraw,
          bankName: record.bankDetails.bankName,
          accountNo: record.bankDetails.accountNo,
          holderName: record.bankDetails.holderName,
          bankcode: record.bankDetails.bankcode,
          country: record.bankDetails.country,
          status: record.status,
          displaypriority: record.displaypriority,
          gateWay: record.gateWay,
          CoinpaymetNetWorkFee: isEmpty(record.CoinpaymetNetWorkFee)
            ? 0.1
            : record.CoinpaymetNetWorkFee,
          isPrimary: false,
          withdrawLimit: isEmpty(record.withdrawLimit)
            ? 100
            : record.withdrawLimit,
          depositFee: isEmpty(record.depositFee) ? 0 : record.depositFee,
          minimumdeposit: isEmpty(record.minimumdeposit)
            ? 0
            : record.minimumdeposit,
          withdrawFeeType: isEmpty(record.withdrawFeeType)
            ? "percentage"
            : record.withdrawFeeType,
          withdrawFeeFlat: isEmpty(record.withdrawFeeFlat)
            ? 0
            : record.withdrawFeeFlat,
        };
        this.setState({ formValue: formData });
      } else if (record.type == "token") {
        let formData = {
          currencyId: record._id,
          currencyName: record.currencyName,
          currencySymbol: record.currencySymbol,
          currencyImage: isEmpty(record.currencyImage)
            ? ""
            : imageUrl + record.currencyImage,
          type: record.type,
          withdrawFee: record.withdrawFee,
          minimumWithdraw: record.minimumWithdraw,
          tokenType: record.tokenType,
          minABI: record.minABI,
          contractAddress: record.contractAddress,
          decimals: record.decimals,
          status: record.status,
          displaypriority: record.displaypriority,
          gateWay: record.gateWay,
          CoinpaymetNetWorkFee: isEmpty(record.CoinpaymetNetWorkFee)
            ? 0.1
            : record.CoinpaymetNetWorkFee,
          isPrimary: false,
          withdrawLimit: isEmpty(record.withdrawLimit)
            ? 100
            : record.withdrawLimit,
          depositFee: isEmpty(record.depositFee) ? 0 : record.depositFee,
          minimumdeposit: isEmpty(record.minimumdeposit)
            ? 0
            : record.minimumdeposit,
          withdrawFeeType: isEmpty(record.withdrawFeeType)
            ? "percentage"
            : record.withdrawFeeType,
          withdrawFeeFlat: isEmpty(record.withdrawFeeFlat)
            ? 0
            : record.withdrawFeeFlat,
          burnFee: isEmpty(record.burnFee)
            ? 0
            : record.burnFee,
        };
        this.setState({ formValue: formData });
      }
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...this.state.formValue, ...{ [name]: value } };
    this.setState({ formValue: formData });
  };

  handleClose = () => {
    const { onHide } = this.props;
    onHide();
    this.setState({ formValue: initialFormValue, errors: {} });
  };

  handleFile = (e) => {
    e.preventDefault();
    const { name, files } = e.target;
    let formData = { ...this.state.formValue, ...{ [name]: files[0] } };
    this.setState({ formValue: formData });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { formValue } = this.state;
    const { fetchData } = this.props;

    if (formValue && formValue.currencyImage) {
      if (formValue.currencyImage.size > 20000) {
        this.setState({
          errors: { currencyImage: "Image size should be less than  20 Kb" },
        });
        toastAlert(
          "error",
          "Image size should be less than  20 Kb",
          "currencyUpdateModal"
        );
        return false;
      }
    }

    try {
      const formData = new FormData();
      formData.append("currencyId", formValue.currencyId);
      formData.append("type", formValue.type);
      formData.append("currencyName", formValue.currencyName);
      formData.append("currencySymbol", formValue.currencySymbol);
      formData.append("contractAddress", formValue.contractAddress);
      formData.append("minABI", formValue.minABI);
      formData.append("decimals", formValue.decimals);
      formData.append("withdrawFee", formValue.withdrawFee);
      formData.append("minimumWithdraw", formValue.minimumWithdraw);
      formData.append("withdrawLimit", formValue.withdrawLimit);
      formData.append("gateWay", formValue.gateWay);
      formData.append("CoinpaymetNetWorkFee", formValue.CoinpaymetNetWorkFee);
      formData.append("bankName", formValue.bankName);
      formData.append("accountNo", formValue.accountNo);
      formData.append("holderName", formValue.holderName);
      formData.append("bankcode", formValue.bankcode);
      formData.append("country", formValue.country);
      formData.append("currencyImage", formValue.currencyImage);
      formData.append("status", formValue.status);
      formData.append("displaypriority", formValue.displaypriority);
      formData.append("minimumdeposit", formValue.minimumdeposit);
      formData.append("depositFee", formValue.depositFee);
      formData.append("withdrawFeeType", formValue.withdrawFeeType);
      formData.append("withdrawFeeFlat", formValue.withdrawFeeFlat);
      formData.append("burnFee", formValue.burnFee);

      this.setState({ loader: true });

      const { status, loading, message, error } = await updateCurrency(
        formData
      );
      this.setState({ loader: loading });
      if (status == "success") {
        this.handleClose();
        fetchData();
        toastAlert("success", message, "currencyUpdateModal");
      } else if (status == "failed") {
        if (error) {
          this.setState({ errors: error });
        } else {
          toastAlert("error", message, "currencyUpdateModal");
        }
      }
    } catch (err) { }
  };

  render() {
    const {
      type,
      currencyName,
      currencySymbol,
      decimals,
      contractAddress,
      minABI,
      withdrawFee,
      minimumWithdraw,
      bankName,
      accountNo,
      holderName,
      bankcode,
      country,
      currencyImage,
      displaypriority,
      status,
      withdrawLimit,
      gateWay,
      CoinpaymetNetWorkFee,
      depositFee, withdrawFeeType, withdrawFeeFlat,
      minimumdeposit,
      burnFee,
    } = this.state.formValue;
    const { errors, loader } = this.state;

    const { isShow } = this.props;

    return (
      <div>
        <Modal
          show={isShow}
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
          scrollable={true}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">Update Currency</h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="currencyName">Currency Type</label>
                </div>
                <div className="col-md-9">
                  <Form.Control
                    name="type"
                    value={type}
                    onChange={this.handleChange}
                    as="select"
                    custom
                  >
                    <option value={"crypto"}>Crypto</option>
                    <option value={"token"}>Token</option>
                    <option value={"fiat"}>Fiat</option>
                  </Form.Control>

                  <span className="text-danger">{errors.type}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="currencyName">Currency Name</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="currencyName"
                    type="text"
                    value={currencyName}
                    onChange={this.handleChange}
                    error={errors.currencyName}
                    className={classnames("form-control", {
                      invalid: errors.currencyName,
                    })}
                  />
                  <span className="text-danger">{errors.currencyName}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="currencySymbol">Currency Symbol</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="currencySymbol"
                    type="text"
                    value={currencySymbol}
                    onChange={this.handleChange}
                    error={errors.currencySymbol}
                    className={classnames("form-control", {
                      invalid: errors.currencySymbol,
                    })}
                  />
                  <span className="text-danger">{errors.currencySymbol}</span>
                </div>
              </div>
              {type && type != "fiat" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencyName">GateWay Type</label>
                  </div>
                  <div className="col-md-9">
                    <Form.Control
                      name="gateWay"
                      value={gateWay}
                      onChange={this.handleChange}
                      as="select"
                      custom
                    >
                      <option value={"ERC"}>ERC.20</option>
                      <option value={"BEB"}>BEB.20</option>
                      <option value={"CoinPayment"}>CoinPayment</option>
                    </Form.Control>

                    <span className="text-danger">{errors.gateWay}</span>
                  </div>
                </div>
              )}
              {gateWay && gateWay == "CoinPayment" && type && type != "fiat" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencySymbol">
                      Coinpaymet NetWork Fee
                    </label>
                  </div>
                  <div className="col-md-9">
                    <input
                      name="CoinpaymetNetWorkFee"
                      type="number"
                      value={CoinpaymetNetWorkFee}
                      onChange={this.handleChange}
                      error={errors.CoinpaymetNetWorkFee}
                      className={classnames("form-control", {
                        invalid: errors.CoinpaymetNetWorkFee,
                      })}
                    />
                    <span className="text-danger">
                      {errors.CoinpaymetNetWorkFee}
                    </span>
                  </div>
                </div>
              )}

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="displaypriority">Currency Priority</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="displaypriority"
                    type="number"
                    value={displaypriority}
                    onChange={this.handleChange}
                    error={errors.displaypriority}
                    className={classnames("form-control", {
                      invalid: errors.displaypriority,
                    })}
                  />
                  <span className="text-danger">{errors.displaypriority}</span>
                </div>
              </div>

              {type == "token" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencyName">Contract Address</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      name="contractAddress"
                      type="text"
                      onChange={this.handleChange}
                      value={contractAddress}
                      error={errors.contractAddress}
                      className={classnames("form-control", {
                        invalid: errors.contractAddress,
                      })}
                    />
                    <span className="text-danger">
                      {errors.contractAddress}
                    </span>
                  </div>
                </div>
              )}

              {type == "token" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencyName">Burn Fee</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      name="burnFee"
                      type="text"
                      onChange={this.handleChange}
                      value={burnFee}
                      error={errors.burnFee}
                      className={classnames("form-control", {
                        invalid: errors.burnFee,
                      })}
                    />
                    <span className="text-danger">
                      {errors.burnFee}
                    </span>
                  </div>
                </div>
              )}

              {type == "token" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencyName">Min ABI</label>
                  </div>
                  <div className="col-md-9">
                    <textarea
                      name="minABI"
                      type="text"
                      value={minABI}
                      onChange={this.handleChange}
                      error={errors.minABI}
                      className={classnames("form-control", {
                        invalid: errors.minABI,
                      })}
                    />
                    <span className="text-danger">{errors.minABI}</span>
                  </div>
                </div>
              )}

              {type == "token" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencySymbol">Decimals</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      name="decimals"
                      type="number"
                      value={decimals}
                      onChange={this.handleChange}
                      error={errors.decimals}
                      className={classnames("form-control", {
                        invalid: errors.decimals,
                      })}
                    />
                    <span className="text-danger">{errors.decimals}</span>
                  </div>
                </div>
              )}

              {type == "fiat" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencySymbol">Bank Name</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      name="bankName"
                      type="text"
                      value={bankName}
                      onChange={this.handleChange}
                      error={errors.bankName}
                      className={classnames("form-control", {
                        invalid: errors.bankName,
                      })}
                    />
                    <span className="text-danger">{errors.bankName}</span>
                  </div>
                </div>
              )}

              {type == "fiat" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencySymbol">Account No.</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      name="accountNo"
                      type="text"
                      value={accountNo}
                      onChange={this.handleChange}
                      error={errors.accountNo}
                      className={classnames("form-control", {
                        invalid: errors.accountNo,
                      })}
                    />
                    <span className="text-danger">{errors.accountNo}</span>
                  </div>
                </div>
              )}

              {type == "fiat" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencySymbol">Holder Name</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      name="holderName"
                      type="text"
                      value={holderName}
                      onChange={this.handleChange}
                      error={errors.holderName}
                      className={classnames("form-control", {
                        invalid: errors.holderName,
                      })}
                    />
                    <span className="text-danger">{errors.holderName}</span>
                  </div>
                </div>
              )}

              {type == "fiat" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencySymbol">
                      Bank Code(IBAN or IFSC)
                    </label>
                  </div>
                  <div className="col-md-9">
                    <input
                      name="bankcode"
                      type="text"
                      value={bankcode}
                      onChange={this.handleChange}
                      error={errors.bankcode}
                      className={classnames("form-control", {
                        invalid: errors.bankcode,
                      })}
                    />
                    <span className="text-danger">{errors.bankcode}</span>
                  </div>
                </div>
              )}

              {type == "fiat" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencySymbol">Country</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      name="country"
                      type="text"
                      value={country}
                      onChange={this.handleChange}
                      error={errors.country}
                      className={classnames("form-control", {
                        invalid: errors.country,
                      })}
                    />
                    <span className="text-danger">{errors.country}</span>
                  </div>
                </div>
              )}
              {type == "fiat" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencySymbol">Deposit Fee %</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      name="depositFee"
                      type="text"
                      value={depositFee}
                      onChange={this.handleChange}
                      error={errors.depositFee}
                      className={classnames("form-control", {
                        invalid: errors.depositFee,
                      })}
                    />
                    <span className="text-danger">{errors.depositFee}</span>
                  </div>
                </div>
              )}
              {type == "fiat" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencySymbol">Minimum Deposit</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      name="minimumdeposit"
                      type="text"
                      value={minimumdeposit}
                      onChange={this.handleChange}
                      error={errors.minimumdeposit}
                      className={classnames("form-control", {
                        invalid: errors.minimumdeposit,
                      })}
                    />
                    <span className="text-danger">{errors.minimumdeposit}</span>
                  </div>
                </div>
              )}

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="withdrawFeeType">Withdraw Fee type</label>
                </div>
                <div className="col-md-9">
                  <Form.Control
                    name="withdrawFeeType"
                    value={withdrawFeeType}
                    onChange={this.handleChange}
                    as="select"
                    custom
                  >
                    <option value={"percentage"}>Percentage (%)</option>
                    <option value={"flat"}>Flat</option>
                  </Form.Control>

                  <span className="text-danger">{errors.withdrawFeeType}</span>
                </div>
              </div>
              {withdrawFeeType == "percentage" &&
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="fee">Withdrawal Fee in percentage(%)</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      name="withdrawFee"
                      type="text"
                      value={withdrawFee}
                      onChange={this.handleChange}
                      error={errors.withdrawFee}
                      className={classnames("form-control", {
                        invalid: errors.withdrawFee,
                      })}
                    />
                    <span className="text-danger">{errors.withdrawFee}</span>
                  </div>
                </div>}
              {withdrawFeeType == "flat" &&
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="withdrawFeeFlat">Withdrawal Fee(flat)</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      name="withdrawFeeFlat"
                      type="text"
                      value={withdrawFeeFlat}
                      onChange={this.handleChange}
                      error={errors.withdrawFeeFlat}
                      className={classnames("form-control", {
                        invalid: errors.withdrawFeeFlat,
                      })}
                    />
                    <span className="text-danger">{errors.withdrawFeeFlat}</span>
                  </div>
                </div>
              }
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="fee">Withdrawal Limit</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="withdrawLimit"
                    type="text"
                    value={withdrawLimit}
                    onChange={this.handleChange}
                    error={errors.withdrawLimit}
                    className={classnames("form-control", {
                      invalid: errors.withdrawLimit,
                    })}
                  />
                  <span className="text-danger">{errors.withdrawLimit}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="minimum">Minimum Withdrawal</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="minimumWithdraw"
                    type="text"
                    value={minimumWithdraw}
                    onChange={this.handleChange}
                    error={errors.minimumWithdraw}
                    className={classnames("form-control", {
                      invalid: errors.minimumWithdraw,
                    })}
                  />
                  <span className="text-danger">{errors.minimumWithdraw}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="currencyName">Status</label>
                </div>
                <div className="col-md-9">
                  <Form.Control
                    name="status"
                    value={status}
                    onChange={this.handleChange}
                    as="select"
                    custom
                  >
                    <option value={"active"}>Active</option>
                    <option value={"deactive"}>Deactive</option>
                  </Form.Control>

                  <span className="text-danger">{errors.type}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="minimum">Currency icon</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="currencyImage"
                    type="file"
                    onChange={this.handleFile}
                    accept="image/x-png,image/gif,image/jpeg"
                    aria-describedby="fileHelp"
                  />
                  <span className="text-danger">{errors.currencyImage}</span>
                  <img
                    className="img-fluid proofThumb"
                    src={fileObjectUrl(currencyImage)}
                  />
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={this.handleClose}
            >
              Close
            </button>
            <button onClick={this.handleSubmit} className="btn btn-primary">
              {loader && <i class="fas fa-spinner fa-spin"></i>}Update currency
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CurrencyUpdateModal;
