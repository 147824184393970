// Modal
export const TOGGLE_SIDE_BAR = "TOGGLE_SIDE_BAR";

// User Register
export const USER_REGISTER_START = "USER_REGISTER_START";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_ERROR = "USER_REGISTER_ERROR";

// Current User
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const SET_AUTHENTICATION = "SET_AUTHENTICATION";
export const REMOVE_AUTHENTICATION = "REMOVE_AUTHENTICATION";

export const USER_UPDATE = "USER_UPDATE";
