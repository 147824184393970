import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from "@ashvin27/react-datatable";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

// import component
import { withRouter } from 'react-router-dom';
import KycUpdateHistory from "../partials/kycUpdateHistory";
import { dateTimeFormat } from '../../lib/dateHelper';
// import action
import { getUserKychistory, changeUserType } from "../../actions/userKycAction";
import { toastAlert } from "../../lib/toastAlert";

class UserKycHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      phone: ''
    };

    this.columns = [
      //   {
      //     key: "email",
      //     text: "Email",
      //     className: "identifier",
      //     align: "left",
      //     sortable: false,
      //   },
      {
        key: "action",
        text: "Date",
        className: "identifier",
        align: "left",
        sortable: false,
        cell: record => {
          return dateTimeFormat(record.createdAt);
        }
      },
      {
        key: "identity",
        text: "Identity Document",
        className: "subject",
        align: "left",
        sortable: false,
        cell: (record) => {
          if (record.idProof.status == "new") {
            return "-";
          } else if (record.idProof.status == "pending") {
            return (
              <>
                {/* {record.idProof.status} */}
                {record.idProof.status.charAt(0).toUpperCase() + record.idProof.status.slice(1)}

                <span>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => this.editRecord(record, "idProof")}
                    style={{ marginRight: "5px" }}
                  >
                    View
                  </button>
                </span>
              </>
            );
          } else {
            return (
              <>
                {record.idProof.status.charAt(0).toUpperCase() + record.idProof.status.slice(1)}
                <span>&nbsp;&nbsp;&nbsp;
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => this.editRecord(record, 'idProof')}
                    style={{ marginRight: '5px' }}
                  >
                    View
                  </button>
                </span>

              </>
            )
          }
        },
      },
      {
        key: "identity",
        text: "Proof of Address",
        className: "subject",
        align: "left",
        sortable: false,
        cell: (record) => {
          if (record.addressProof.status == "new") {
            return "-";
          } else if (record.addressProof.status == "pending") {
            return (
              <>
                {/* {record.addressProof.status} */}
                {record.addressProof.status.charAt(0).toUpperCase() + record.addressProof.status.slice(1)}
                &nbsp;&nbsp;&nbsp;
                <span>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => this.editRecord(record, "addressProof")}
                    style={{ marginRight: "5px" }}
                  >
                    View
                  </button>
                </span>
              </>
            );
          } else {
            // return <>{record.addressProof.status}</>;
            return (
              <>
                {/* {record.addressProof.status} */}
                {record.addressProof.status.charAt(0).toUpperCase() + record.addressProof.status.slice(1)}

                <span>&nbsp;&nbsp;&nbsp;
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => this.editRecord(record, "addressProof")}
                    style={{ marginRight: "5px" }}
                  >
                    View
                  </button>
                </span>
              </>
            );
          }
        },
      },



    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Emailtemplates",
      no_data_text: "No Email Templates found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      docFormModal: false,
      docRecord: {},
      records: [],
      search: "",
      page: 1,
      limit: 10,
      count: 0,
      loader: false,
      languageOption: [],
    };

    this.fetchKyc = this.fetchKychistory.bind(this);
    this.refetch = this.refetch.bind(this);
    this.handleCloseDocForm = this.handleCloseDocForm.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const { page, limit } = this.state;
    let reqData = {
      id,
      page,
      limit,
    };
    this.fetchKychistory(reqData);
  }

  async fetchKychistory(reqData) {
    this.setState({ loader: true });
    try {
      const { status, loading, message, result } = await getUserKychistory(reqData);
      this.setState({ loader: loading });
      if (status == "success") {
        console.log("qaaaaaaaaaaaaaaaaaaaaaaaaaa", result.userdetail)
        let ueremail = result.userdetail.email, phon = result.userdetail.phoneNo
        this.setState({ count: result.count, records: result.data, email: ueremail, phone: phon });
      }
    } catch (err) { }
  }

  async handleVerifyType(reqData) {
    console.log(reqData, "ReqDataaa");
    this.setState({ loader: true });
    try {
      const { status, loading, message, result } = await changeUserType(
        reqData.userid
      );
      this.setState({ loader: loading });
      if (status == "success") {
        toastAlert("success", message, "kyc");
        this.refetch();
      } else {
        toastAlert("error", message, "kyc");
      }
    } catch (err) { }
  }

  editRecord(record, formType) {
    if (formType == "idProof") {
      let docRecord = {
        userId: record.userId,
        type: record.idProof.type,
        proofNumber: record.idProof.proofNumber,
        frontImage: record.idProof.frontImage,
        backImage: record.idProof.backImage,
        selfiImage: record.idProof.selfiImage,
        status: record.idProof.status,
        oldreason: record.idProof.reason,
        formType,
      };
      console.log("docRecorddocRecorddocRecorddocRecord", docRecord)
      this.setState({ docFormModal: true, docRecord });
    } else if (formType == "addressProof") {
      let docRecord = {
        userId: record.userId,
        type: record.addressProof.type,
        frontImage: record.addressProof.frontImage,
        status: record.addressProof.status,
        oldreason: record.addressProof.reason,
        formType,
      };
      console.log("docRecorddocRecorddocRecorddocRecord", docRecord, record)
      this.setState({ docFormModal: true, docRecord });
    }
  }

  handlePagination(index) {
    const { id } = this.props.match.params;
    let reqData = {
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
      id
    };
    this.fetchKyc(reqData);
    this.setState({
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    });
  }

  handleCloseDocForm() {
    this.setState({ docFormModal: false });
  }

  refetch() {
    const { id } = this.props.match.params;
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
      id
    };
    this.fetchKyc(reqData);
  }

  render() {
    const { docFormModal, docRecord, loader, count } = this.state;

    return (
      <>

        <KycUpdateHistory
          isShow={docFormModal}
          onHide={this.handleCloseDocForm}
          fetchData={this.refetch}
          record={docRecord}
        />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <h2 className="mt-2 text-secondary">KYC History</h2>
            {this.state.email && <h3 className="mt-2 text-secondary">Email:{this.state.email}</h3>}
            {this.state.phone && <h3 className="mt-2 text-secondary">PhoneNo:{this.state.phone}</h3>}

            <ReactDatatable
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              dynamic={true}
              total_record={count}
              loading={loader}
              onChange={this.handlePagination}
            />
          </div>
        </div>
      </>

    );
  }
}

export default withRouter(UserKycHistory);
