import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";

import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import { toast, ToastContainer } from "react-toastify";
import keys from "../../actions/config";

// import component
import { withRouter } from 'react-router-dom';
import CmsAddModal from "../partials/CmsAddModal";
import CmsUpdateModal from "../partials/CmsUpdateModal";
import SliderManageADD from "../partials/SliderManageADD";
import { capitalize } from "../../lib/stringCase"
// import action
import { getCmsList } from "../../actions/cmsActions";

const url = keys.baseUrl;
class CmsPage extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "page",
        text: "Page name",
        className: "subject",
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <p>
              {capitalize(record && record.page)}
            </p>
          )
        }
      },
      {
        key: "title",
        text: "Place",
        className: "subject",
        align: "left",
        sortable: false,
      },

      {
        key: "identifier",
        text: "Identifier",
        className: "identifier",
        align: "left",
        sortable: false,
      },

      {
        key: "status",
        text: "Status",
        className: "identifier",
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <p>
              {capitalize(record && record.status)}
            </p>
          )
        }
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          let page = record.page
          const checkin = record.status;
          if (checkin == 'active') {
            var lockVal = 'fa fa-lock';
          } else {
            var lockVal = 'fa fa-unlock';
          }
          return (
            <Fragment>
              {
                page && page == "slider" ? <button
                  data-toggle="modal"
                  data-target="#update-slider-modal"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.addSliderRecord(record)}
                  style={{ marginRight: "5px" }}
                >
                  <i className="fa fa-edit"></i>
                </button> :
                  <button
                    data-toggle="modal"
                    data-target="#update-cmsedit-modal"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.editRecord(record)}
                    style={{ marginRight: "5px" }}
                  >
                    <i className="fa fa-edit"></i>
                  </button>

              }

              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.deleteRecord(record)}
              >
                <i className={lockVal}></i>
              </button>
            </Fragment>
          );
        },
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Cms",
      no_data_text: "No CMS found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      loader: false,
      records: [],
      addFormModal: false,
      editFormModal: false,
      sliderMoal: false,
      editRecord: {},
      count: 0,
    };

    this.fetchCmsData = this.fetchCmsData.bind(this);
    this.handleCloseEditForm = this.handleCloseEditForm.bind(this);
    this.handleCloseAddForm = this.handleCloseAddForm.bind(this);
    this.addSliderRecord = this.addSliderRecord.bind(this);
    this.handleCloseAddSliderForm = this.handleCloseAddSliderForm.bind(this);

  }

  componentDidMount() {
    this.fetchCmsData();
  }

  componentDidUpdate(prevProps) {

    // Check if specific props or state values have changed
    if (this.props.location !== prevProps.location) {
      // Perform logic or side effects based on prop change
      this.setState({ records: [], count: 0, loader: true });

      this.fetchCmsData();
      console.log('someProp has changed');
    }
  }


  async fetchCmsData() {
    try {
      this.setState({ loader: true });
      const { stage } = this.props.match.params;
      let params = "all";
      if (stage) {
        params = stage
      }
      const { status, loading, result } = await getCmsList(params);
      this.setState({ loader: loading });
      if (status == "success") {
        this.setState({ records: result, count: result.length });
      }
    } catch (err) { }
  }

  addRecord() {
    this.setState({ addFormModal: true });
  }

  editRecord(record) {
    this.setState({
      editFormModal: true,
      editRecord: record,
    });

    setTimeout(() => {
      $("#update-cmsedit-modal")
        .find(".text-danger")
        .hide();
    }, 2000);

  }

  handleCloseEditForm() {
    this.setState({ editFormModal: false, editRecord: {} });
  }

  deleteRecord(record) {
    console.log(record);
    axios
      .put(url + "adminApi/sliderUpdate", { _id: record._id })
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.fetchCmsData();
        }
      })
      .catch();

  }

  handleCloseAddForm() {
    this.setState({ addFormModal: false, editRecord: {} });
  }

  handleCloseAddSliderForm() {
    this.setState({ sliderMoal: false, editRecord: {} });
  }
  addSliderRecord(record = "") {
    setTimeout(() => {
      $("#update-slider-modal")
        .find(".text-danger")
        .hide();
    }, 2000);
    if (record) {
      this.setState({ sliderMoal: true, editRecord: record, });
    } else {
      this.setState({ sliderMoal: true });
    }

  }
  render() {
    const { addFormModal, editFormModal, editRecord, sliderMoal } = this.state;
    const { stage } = this.props.match.params;
    return (
      <>

        <CmsAddModal
          isShow={addFormModal}
          onHide={this.handleCloseAddForm}
          fetchData={this.fetchLanguage}
        />
        <CmsUpdateModal
          isShow={editFormModal}
          onHide={this.handleCloseEditForm}
          fetchData={this.fetchCmsData}
          record={editRecord}
        />
        {
          stage && stage == "slider" && <SliderManageADD
            isShow={sliderMoal}
            onHide={this.handleCloseAddSliderForm}
            fetchData={this.fetchCmsData}
            record={editRecord}
          />
        }

        <div id="page-content-wrapper">
          <div className="container-fluid">
            {
              stage && stage == "slider" && <button data-toggle="modal"
                data-target="#update-slider-modal" onClick={() => this.addSliderRecord()} className="btn btn-outline-primary float-right mt-3 mr-2" > Add Slider CMS</button>
            }


            <h3 className="mt-2 text-secondary">Cms List</h3>
            <ReactDatatable
              config={this.config}
              records={this.state.records}
              columns={this.columns}

            // columns={this.columns}
            // // total_record={this.state.count}
            // dynamic={true}
            // loading={this.state.loader}
            // responsive={true}
            />
          </div>
        </div>
        <ToastContainer />
      </>

    );
  }
}

export default withRouter(CmsPage);
