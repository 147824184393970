import React, { Component, Fragment } from "react";
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@ashvin27/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {getContact} from "../../actions/userActions";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ContactUpdateModal from "../partials/ContactUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import keys from "../../actions/config";
const url = keys.baseUrl;
class Contactus extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "name",
                text: "Name",
                className: "name",
                align: "left",
                sortable: false,
            },
            {
                key: "email",
                text: "Email",
                className: "email",
                align: "left",
                sortable: false,
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: false,
                cell: (record) => {
                    return record.status ? "Replied" : "Pending";
                },
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: (record) => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-contact-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: "5px" }}
                            >
                                <i className="fa fa-reply"></i>
                            </button>
                        </Fragment>
                    );
                },
            },
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Perpetual",
            no_data_text: 'No Contracts found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };


        this.state = {
            currentRecord: {
                _id: "",
                name: "",
                email: "",
                message: "",
                reply: "",
            },
            records: [],
            loader: false,
            page: 1,
            limit: 10,
            count: 0,
            responsive: true,
        };

        this.getData = this.getData.bind(this);
        this.pageChange = this.pageChange.bind(this)

    }

    componentDidMount() {
        const { page, limit } = this.state;
        let reqData = {
            page,
            limit
        }
        this.getData(reqData);
    }

    componentWillReceiveProps(nextProps) {
        const { page, limit } = this.state;
        let reqData = {
            page,
            limit
        }
        this.getData(reqData);
    }

   async getData(reqData) {
        console.log(reqData,"reqDatareqDatareqDatareqData");
        // axios
        //     .get(`adminApi/contactus`,reqData)
        //     .then((res) => {
        //         console.log(res.data, "aaaaaaaaaaaaaaaaaaa");
        //         this.setState({ records: res.data.contact,count:res.data.count });
        //     })
        //     .catch();

            try {
                this.setState({ 'loader': true })
                const { status, loading, result } = await getContact(reqData);
                this.setState({ 'loader': false })
                if (status == 'success') {
                    this.setState({ "count": result.count, 'records': result.contact })
                }
            } catch (err) { }
    }

    editRecord(record) {
        console.log(record, "record");
        this.setState({ currentRecord: record });
    }

    deleteRecord(record) {
        console.log(record);
        axios
            .post(url + "api/contact-delete", { _id: record._id })
            .then((res) => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            })
            .catch();
        this.getData();
    }

    pageChange(index) {
        let reqData = {
            page: index.page_number,
            limit: index.page_size,
            search: index.filter_value
        }
        this.getData(reqData);
        this.setState({ page: index.page_number, limit: index.page_size, search: index.filter_value })
    }

    render() {
        const { count, loader,responsive } = this.state
        console.log("countcountcountcountcount",count);
        return (
            <>

                <ContactUpdateModal record={this.state.currentRecord} />
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <h3 className="mt-2 text-secondary">
                            Enquires List
                        </h3>
                        <ReactDatatable
                            config={this.config}
                            responsive={responsive}
                            records={this.state.records}
                            columns={this.columns}
                            total_record={count}
                            dynamic={true}
                            onChange={this.pageChange}
                        />
                    </div>
                </div>
                <ToastContainer />
            </>

        );
    }
}

Contactus.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    records: state.records,
});

export default connect(mapStateToProps)(withRouter(Contactus));