import React, { Component, Fragment } from "react";
import classnames from "classnames";
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateChangepassword } from "../../actions/userActions";
import { tfaFormsubmit } from "../../actions/authActions";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import keys from "../../actions/config";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "qrcode.react";
import config from "../../actions/config";

const url = keys.baseUrl;
class twofactor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      googleauth: "",
      errors: {},
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    if (this.props.auth !== undefined && this.props.auth.user !== undefined) {
      const id = this.props.auth.user._id;

      axios
        .get(url + "adminapi/userget/" + id)
        .then((res) => {
          this.setState({
            userid: res.data.userid,
            name: res.data.name,
            email: res.data.email,
            currency: res.data.currency,
            mobilesite: res.data.mobilesite,
            windoworder: res.data.windoworder,
            position: res.data.position,
            animation: res.data.animation,
            loginhistory: res.data.loginhistory,
            apiKeydetails: res.data.apiKeydetails,
            sms: res.data.sms,
            secretcode: res.data.newSecret.secret,
            phone: res.data.phonenumber,
            verifiedstatus: res.data.verifiedstatus,
            Factor2AImg: `otpauth://totp/${encodeURIComponent(
              "MudraExchange-Admin"
            )}:${encodeURIComponent(
              res.data.email
            )}?secret=${encodeURIComponent(res.data.newSecret.secret)}`,
          });
          if (res.data.google != "Disabled") {
            this.setState({ googleauth: "Verified" });
          }
        })
        .catch();
    }
    //  console.log(this.props.auth,'res.datares.data');
  }

  tfaFormsubmit = (e) => {
    e.preventDefault();
    const userData = {
      userid: this.props.auth.user._id,
      loginpassword: this.state.loginpassword,
      secretcode: this.state.secretcode,
      onecode: this.state.onecode,
      id: this.props.auth.user.id,
    };
    axios
      .post(url + "adminapi/tfaenable/", userData)
      .then((res) => {
        toast("2fa updated successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    console.log(nextProps, "nextProps");
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.updatenewuser !== undefined &&
      nextProps.auth.updatenewuser.data !== undefined &&
      nextProps.auth.updatenewuser.data.message !== undefined
    ) {
      toast(nextProps.auth.updatenewuser.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });

      nextProps.auth.updatenewuser = undefined;
      this.getData();
    }
  }
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  onsendOtp = (e) => {
    e.preventDefault();
    // if(typeof this.state.phone != "undefined" && this.state.phone!=""){
    const emaildata = {
      _id: this.props.auth.user.id,
      // email: this.state.email
    };
    console.log("emaildata", emaildata);
    axios
      .post(url + "api/sendotppasswordchange", emaildata)
      .then((res) => {
        if (res.data.success) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.setState({ errors: "" });
        } else {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch();
  };

  onChangepasswordUpdate = (e) => {
    e.preventDefault();
    const updatechangepassword = {
      _id: this.props.auth.user.id,
      oldpassword: this.state.oldpassword,
      password: this.state.password,
      password2: this.state.password2,
      otpnew: this.state.otpnew,
    };
    // this.props.updateChangepassword(updatechangepassword);
  };

  render() {
    const { errors } = this.state;
    return (
      <>

        <div id="page-content-wrapper">
          <div className="container-fluid">
            <h3 className="mt-2 text-secondary">2FA</h3>

            <form
              noValidate
              onSubmit={this.onChangepasswordUpdate}
              id="update-Changepassword"
            >
              <div className="row mt-3">
                <div className="col-xl-4 col-lg-4 col-md-6 col-10">
                  <div className="form-group">
                    <label>
                      Write down 2FA code for login and withdrawals.
                    </label>
                    <input
                      onChange={this.onChange}
                      value={this.state.secretcode}
                      id="secretcode"
                      type="text"
                      error={errors.secretcode}
                      className={classnames("form-control", {
                        invalid: errors.secretcode,
                      })}
                    />
                    <CopyToClipboard
                      onCopy={() => {
                        toast("Copied", {
                          position: toast.POSITION.TOP_CENTER,
                        });
                      }}
                      text={this.state.secretcode}
                    >
                      <button class="btn btn-primary">Copy</button>
                    </CopyToClipboard>
                  </div>
                </div>

                <div className="col-12 col-sm-2">
                  <div className="form-group">
                    <span className="scanCode">
                      {!!this.state.Factor2AImg?.length && (
                        <QRCode
                          value={this.state.Factor2AImg}
                          level="L"
                          includeMargin
                          className="img-fluid"
                        />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label>Login Password</label>
                    <input
                      onChange={this.onChange}
                      value={this.state.loginpassword}
                      id="loginpassword"
                      type="password"
                      error={errors.loginpassword}
                      className={classnames("form-control", {
                        invalid: errors.loginpassword,
                      })}
                    />
                    <span className="text-danger">
                      {errors.loginpassword}
                    </span>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label>Enter 6 Digit 2FA Authentication Code</label>
                    <input
                      onChange={this.onChange}
                      value={this.state.onecode}
                      id="onecode"
                      type="password"
                      error={errors.onecode}
                      className={classnames("form-control", {
                        invalid: errors.onecode,
                      })}
                    />
                    <span className="text-danger">{errors.onecode}</span>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-2 col-sm-12">
                  <div className="form-group">
                    <label className="d-none d-md-block invisible">
                      Label Space
                    </label>
                    <button
                      className="btn btn-primary"
                      onClick={this.tfaFormsubmit}
                    >
                      {this.state.googleauth != "" ? "Disable" : "Enable"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </>

    );
  }
}

twofactor.propTypes = {
  // updateChangepassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { tfaFormsubmit })(
  withRouter(twofactor)
);
