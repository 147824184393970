// import config
import axios from '../config/axios';

export const getSiteSetting = async () => {
    try {
        const respData = await axios({
            'url': `/adminapi/getSiteSetting`,
            'method': 'get'
        })
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false
        }
    }
}

export const updateSiteSetting = async (data) => {
    try {
        const respData = await axios({
            'url': `/adminapi/updateSiteSetting`,
            'method': 'put',
            'data': data
        })
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false
        }
    }
}

export const updateSiteDetails = async (data) => {
    try {
        const respData = await axios({
            'url': `/adminapi/updateSiteDetails`,
            'method': 'put',
            'data': data
        })
        return {
            status: "success",
            loading: true,
            message: respData.data.message
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const updateUsrDash = async (data) => {
    try {
        const respData = await axios({
            'url': `/adminapi/updateUsrDash`,
            'method': 'put',
            'data': data
        })
        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
        }
    }
}


export const downloadwithdrawlistcsvfile = async () => {
    try {
        const respData = await axios({
            'url': `/adminapi/downloadWithdrawListCsvFile`,
            'method': 'get'
        })
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            file:respData.data.file,
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false
        }
    }
}

export const bulkDeposit = async (data) => {
    try {
        const respData = await axios({
            'url': `/adminapi/bulkDeposit`,
            'method': 'post',
            'data': data
        })
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            file:respData.data.file,
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
        }
    }
}



export const bulkWithdraw = async (data) => {
    try {
        const respData = await axios({
            'url': `/adminapi/bulkWithhdraw`,
            'method': 'post',
            'data': data
        })
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            file:respData.data.file,
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
        }
    }
}