import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import Select from 'react-select';

// import component
import { withRouter } from 'react-router-dom';
import SiteDetails from '../partials/siteDetals'
import UserDashboard from '../partials/UserDashboard';

// import action
import { toastAlert } from '../../lib/toastAlert';
import { getSiteSetting, updateSiteSetting } from '../../actions/siteSettingActions';
import { getPairDropdown } from '../../actions/commonActions'
import { getCurrency } from '../../actions/currency';


class SiteSettingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pairListOption: [],
            currencyOption: [],
            marketTrend: [],
            records: {},
            loader: false
        };

        this.fetchSiteSetting = this.fetchSiteSetting.bind(this);
        this.handlePairChange = this.handlePairChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fetchCurrency = this.fetchCurrency.bind(this)
    }

    componentDidMount() {
        this.fetchSiteSetting();
        this.fetchPairDropdown();
        this.fetchCurrency();
    };


    async fetchSiteSetting() {
        try {
            const { status, loading, result } = await getSiteSetting();
            // console.log(result,'resu')
            if (status == 'success') {
                this.setState({ 'marketTrend': result.marketTrend, "records": result })
            }
        } catch (err) { }
    }

    async fetchPairDropdown() {
        try {
            const { status, loading, result } = await getPairDropdown();
            if (status == 'success') {
                let option = []
                result && result.length > 0 && result.map((item, key) => {
                    option.push({
                        'label': item.firstCurrencySymbol + item.secondCurrencySymbol,
                        'value': item._id
                    })
                })
                this.setState({ 'pairListOption': option })
            }
        } catch (err) { }
    }

    async fetchCurrency() {
        try {
            const { status, result } = await getCurrency();
            if (status == 'success') {
                this.setState({ currencyOption: result })
            }
        } catch (err) { }
    }

    handlePairChange(selectedOption) {
        if (selectedOption && selectedOption.length > 0) {
            this.setState({
                "marketTrend": selectedOption.map((el) => { return el.value; })
            })
        } else {
            this.setState({ "marketTrend": [] })
        }
    };


    async handleSubmit(e) {
        e.preventDefault();
        const { marketTrend } = this.state;

        let reqData = {
            marketTrend
        }

        try {
            const { status, loading, result } = await updateSiteSetting(reqData);

            if (status == 'success') {
                toastAlert('success', "Added Successfully", 'siteSettings')
                this.setState({ 'marketTrend': result.marketTrend })
            }
        } catch (err) { }
    }



    render() {
        const { pairListOption, marketTrend, currencyOption, records } = this.state;


        return (
            <>

                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <h3 className="mt-2 text-secondary"> Site Setting</h3>
                        {/* <Card>
                                <Card.Header>Market Trend</Card.Header>
                                <Card.Body>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="currencyName">Pair Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Select
                                                value={pairListOption && pairListOption.length > 0 ? pairListOption.filter((el) => {
                                                    if (marketTrend.includes(el.value)) {
                                                        return el;
                                                    }
                                                }) : []}
                                                isMulti
                                                name="colors"
                                                options={pairListOption}
                                                onChange={this.handlePairChange}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />

                                        </div>
                                    </div>
                                </Card.Body>
                                <Card.Footer>
                                    <Button onClick={this.handleSubmit}>Submit</Button>
                                </Card.Footer>
                            </Card> */}
                        <SiteDetails
                            records={records}
                            fetchSiteSetting={this.fetchSiteSetting}
                        />
                        <UserDashboard
                            records={records}
                            currencyOption={currencyOption}
                        />
                    </div>
                </div>
            </>

        );
    }

}

export default withRouter(SiteSettingPage);