import axios from "axios";
import {
    GET_ERRORS,
    USER_ADD,
    USER_UPDATE,
    PROFILE_UPDATE,
    PASSWORD_UPDATE,
    SETTINGS_UPDATE,
    REPLY_CONTACT,
    SUPPORT_REPLY,
    Lauchpad_ADD,
    Lauchpad_UPDATE,
    CURRENCY_ADD,
    CONTACT_ADD
} from "./types";
import config from "../config";
import keys from "./config";
const url = keys.baseUrl;

export const addLaunchpad = async (data) => {

  try{  
    let respData = await axios({
        'url' : `${config.API_URL}/adminApi/add-launchpad`,
        'method' : 'post',
        data
    })
    
    return {
        status : respData.data.status,
        message : respData.data.message,
    }
    
  }catch(err){
     return {
         status : false,
         message : err.response.data.message,
         error : err.response.data.errors,
       }
  }
    
};

export const updateLaunchpad = async (data) => {
    try{  
        let respData = await axios({
            'url' : `${config.API_URL}/adminApi/update-launchpad`,
            'method' : 'post',
            data
        })
        return {
           status : respData.data.status,
           message : respData.data.message,
        }
    }catch(err){
        return {
           status : false,
           messsage : err.response.data.message,
           error : err.response.data.errors,
        }
    }    
};
export const rejectLaunchpad = async (data) => {
    try{  
        let respData = await axios({
            'url' : `${config.API_URL}/adminApi/rejectLaunchpad`,
            'method' : 'post',
            data
        })
        return {
           status : respData.data.status,
           message : respData.data.message,
        }
    }catch(err){
        return {
           status : false,
           messsage : err.response.data.message,
           error : err.response.data.errors,
        }
    }    
};
export const getLaunchpad = async () => {
    try{  
        let respData = await axios({
            'url' : `${config.API_URL}/adminApi/get-launchpad`,
            'method' : 'get',
        })
        //console.log(respData,'respDataaaaaaaaaaaaaa')

       return {
             status : respData.data.status,
             result : respData.data.data,
           }
    }catch(err){
        return {
             status : false,
             messsage : err.response.data.message,
             error : err.response.data.errors,
        }
    }    
};

export const changeLaunchpadStatus = async (data) => {
    try{  
        let respData = await axios({
            'url' : `${config.API_URL}/adminApi/launchpad-change-status`,
            'method' : 'post',
            data
        })
        //console.log(respData,'respDataaaaaaaaaaaaaa')

       return {
             status : true,
             message : respData.data.message,
           }
    }catch(err){
        return {
             status : false,
             message : err.response.data.message,
             error : err.response.data.errors,
        }
    }    
};

export const deleteLaunchpad = async (data) => {
    try{  
        let respData = await axios({
            'url' : `${config.API_URL}/adminApi/delete-launchpad`,
            'method' : 'post',
            data
        })
        //console.log(respData,'respDataaaaaaaaaaaaaa')

       return {
             status : respData.data.status,
             message : respData.data.message,
           }
    }catch(err){
        return {
             status : false,
             messsage : err.response.data.message,
             error : err.response.data.errors,
        }
    }    
};
