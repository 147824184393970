// import package
import React, { Component, Fragment } from "react";
import { Modal, Button } from 'react-bootstrap';
import classnames from "classnames";
// import action
import { approveFiatWithdraw, rejectFiatWithdraw } from '../../actions/walletAction';

// import lib
import { toastAlert } from '../../lib/toastAlert';
import { capitalize } from "../../lib/capitalize";

class FiatWithdrawModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loader: false,
            message: "",
            errors: {},
        };
    }


    rejectSubmit = async () => {
        const { record, fetchData } = this.props;
        this.setState({ 'loader': true })
        if (!this.state.message) {
            toastAlert('error', "Reason required", 'coinwithdraw')
            return
        }
        try {
            const { status, loading, message } = await rejectFiatWithdraw(record._id, this.state.message);
            this.setState({ 'loader': loading })
            if (status == 'success') {
                fetchData();
                this.handleClose();
                toastAlert('success', message, 'coinwithdraw')
            } else {
                toastAlert('error', message, 'coinwithdraw')
            }
        } catch (err) { }
    }

    confirmSubmit = async () => {
        const { record, fetchData } = this.props;
        this.setState({ 'loader': true })
        try {
            const { status, loading, message } = await approveFiatWithdraw(record._id);
            this.setState({ 'loader': loading })
            if (status == 'success') {
                fetchData();
                this.handleClose();
                toastAlert('success', message, 'coinwithdraw')
            } else {
                toastAlert('error', message, 'coinwithdraw')
            }
        } catch (err) { }
    }
    handleChange = e => {
        e.preventDefault();
        let { name, value } = e.target;
        this.setState({ message: value });
    }
    handleClose = () => {
        const { onHide } = this.props;
        onHide();
    }

    render() {
        const { isShow, record, loader } = this.props;
        const { errors, message } = this.state;
        return (
            <Modal
                show={isShow}
                onHide={this.handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="popUpSpace">
                        {
                            record.status == "completed" && <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">Transaction ID</label>
                                </div>
                                <div className="col-md-9">
                                    {record.txid}
                                </div>
                            </div>
                        }

                        <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="answer">Bank Name</label>
                            </div>
                            <div className="col-md-9">
                                {record.bankDetail && record.bankDetail.bankName}
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="answer">Account Number</label>
                            </div>
                            <div className="col-md-9">
                                {record.bankDetail && record.bankDetail.accountNo}
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="answer">IBAN Code</label>
                            </div>
                            <div className="col-md-9">
                                {record.bankDetail && record.bankDetail.bankcode}
                            </div>
                        </div>


                        <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="answer">Country</label>
                            </div>
                            <div className="col-md-9">
                                {record.bankDetail && record.bankDetail.country}
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="answer">City</label>
                            </div>
                            <div className="col-md-9">
                                {record.bankDetail && record.bankDetail.city}
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="answer">Transfer Currency</label>
                            </div>
                            <div className="col-md-9">
                                {record.currencySymbol}
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="answer">Transfer Amount</label>
                            </div>
                            <div className="col-md-9">
                                {record.actualAmount}
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="answer">Commission Fee(%)</label>
                            </div>
                            <div className="col-md-9">
                                {record.commissionFee}
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="answer">Total Amount</label>
                            </div>
                            <div className="col-md-9">
                                {record.amount}
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="answer">Status</label>
                            </div>
                            <div className="col-md-9">
                                {capitalize(record.status)}
                            </div>
                        </div>
                        {record.status == 'pending' &&
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">Reason</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        type="text"
                                        name="message"
                                        value={message}
                                        onChange={this.handleChange}
                                        error={errors.message}
                                        className={classnames("form-control", {
                                            invalid: errors.message,
                                        })}
                                    />
                                    <span className="text-danger">{errors.message}</span>

                                </div>
                            </div>}
                    </div>
                </Modal.Body>
                {
                    record.status == 'pending' && <Modal.Footer>
                        <Button
                            variant="danger btnDefaultNewBlue"
                            onClick={this.rejectSubmit}
                            disabled={loader}
                        >
                            Reject
                        </Button>
                        <Button
                            onClick={this.confirmSubmit}
                            variant="success btnDefaultNew"
                            disabled={loader}
                        >
                            Confirm
                        </Button>
                    </Modal.Footer>
                }

            </Modal>
        )
    }
}

export default FiatWithdrawModal;