import React from 'react'
import classnames from "classnames";
import { Modal, Form } from "react-bootstrap";
import fileObjectUrl from '../../lib/fileObjectUrl';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sliderUpdate } from "../../actions/cmsActions";
import { withRouter } from "react-router-dom";
import { toastAlert } from "../../lib/toastAlert";
import config from '../../config';
import { Editor } from "@tinymce/tinymce-react";
import $ from "jquery";
import 'react-toastify/dist/ReactToastify.css';

const initialFormValue = {
    'identifier': '',
    'subject': 'slider',
    'title': '',
    'content': '<div>Hello</div>',
    'page': 'Home',
    'sliderType': 'web',
    'light_image': '',
    'dark_image': '',
    'light_image_url': '',
    'dark_image_url': '',
    'status': '',
    '_id': '',
}

class SliderManageADD extends React.Component {
    constructor() {
        super();
        this.state = {
            formValue: initialFormValue,
            errors: {},
        };
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }


    componentWillReceiveProps(nextProps) {
        const { record } = nextProps
        if (record) {
            let formData = {
                'identifier': record.identifier ? record.identifier : "",
                'subject': record.subject ? record.subject : "",
                'title': record.title ? record.title : "",
                'content': record.content ? record.content : "",
                'page': record.page ? record.page : "",
                'sliderType': record.sliderType ? record.sliderType : "web",
                'light_image': record.light_image ? record.light_image : "",
                'dark_image': record.dark_image ? record.dark_image : "",
                'light_image_url': record.light_image ? config.API_URL + "/currency/" + record.light_image : "",
                'dark_image_url': record.dark_image ? config.API_URL + "/currency/" + record.dark_image : "",
                'status': record.status ? record.status : "",
                '_id': record._id ? record._id : "",
            }
            this.setState({ formValue: formData });

        }

    }
    // handleEditorChange(e) {
    //     let formData = {
    //         ...this.state.formValue,
    //         ...{ content: e.editor.getData() },
    //     };
    //     this.setState({ formValue: formData });
    // }

    handleEditorChange(content, editor) {
        
          let formData = { ...this.state.formValue, ...{ 'content': content } };
          this.setState({ formValue: formData });
          
      }
    
    onChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...this.state.formValue, ...{ [name]: value } };
        this.setState({ formValue: formData });
        let errData = { ...this.state.errors, ...{ [name]: "" } };
        this.setState({ errors: errData });
        console.log(e.target, "errDataerrDataerrDataerrData", errData);
    };

    // onCmsAdd = e => {
    handleSubmit = async (e) => {
        e.preventDefault();
        let {
            _id,
            identifier,
            title,
            subject,
            content,
            page,
            sliderType,
            light_image,
            dark_image,
        } = this.state.formValue;
        const { fetchData } = this.props;

        const formData = new FormData();
        formData.append("_id", _id);
        formData.append("identifier", identifier);
        formData.append("title", title);
        formData.append("content", content);
        formData.append("subject", subject);
        formData.append("page", page);
        formData.append("sliderType", sliderType);
        formData.append("light_image", light_image);
        formData.append("status", this.state.status);
        if (typeof (dark_image) != "undefined")
            formData.append("dark_image", dark_image);

        const { status, loading, message, error } = await sliderUpdate(formData);
        if (status == "success") {
            toastAlert("success", message, "updateCms");
            fetchData();
            this.handleClose();
        } else {
            if (error) {
                this.setState({ errors: error });
            }
            toastAlert("error", message, "updateCms");
        }
    };

    handleChangeefile = (event) => {
        const { name } = event.target
        if (event.target.files[0]) {
            let urlname = '';
            let formData,errorForm;
            // this.setState({ formValue: formData });
            let curimage = URL.createObjectURL(event.target.files[0]);
            if (name == "light_image") {
                formData = { ...this.state.formValue, ...{ ["light_image_url"]: fileObjectUrl(event.target.files[0]), [name]: event.target.files[0] } }
                errorForm = { ...this.state.errors, ...{ "light_image": "" } }

            } else {
                urlname = "dark_image_url"
                formData = { ...this.state.formValue, ...{ ["dark_image_url"]: fileObjectUrl(event.target.files[0]), [name]: event.target.files[0] } }
                errorForm = { ...this.state.errors, ...{ ["dark_image"]: ""} }

            }
            this.setState({ formValue: formData ,errors:errorForm});
            // urlname: fileObjectUrl(event.target.files[0])  
        }
    };

    handleClose = () => {
        const { onHide } = this.props;
        this.setState({ formValue: initialFormValue, errors: {} });
        $(".modal-backdrop").hide();
        $("#update-slider-modal").hide();
          onHide();
    };
    render() {
        const { errors } = this.state;
        const { identifier, title, content, light_image_url, dark_image_url, sliderType, subject, page, light_image, dark_image, status } = this.state.formValue
        const { isShow } = this.props;

        return (
            <div>
                <div className="modal fade" id="update-slider-modal" data-keyboard="false" data-backdrop="static">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Slider Details</h4>
                                <button type="button" className="close" data-dismiss="modal">
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                <form noValidate  id="add-cms">
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="subject">Slider For</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Form.Control
                                                name="sliderType"
                                                value={sliderType}
                                                onChange={this.onChange}
                                                as="select"
                                                custom
                                            >
                                                <option value={"web"}>Web</option>
                                                <option value={"app"}>App</option>
                                            </Form.Control>
                                            <span className="text-danger">{errors.sliderType}</span>
                                        </div>
                                    </div> */}

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="title">Place</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={title}
                                                id="title"
                                                type="text"
                                                name="title"
                                                className={classnames("form-control", {
                                                    invalid: errors.title
                                                })} />
                                            <span style={{ color: "red" }}>{errors.title}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="identifier">Identifier</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                name="identifier"
                                                value={identifier}
                                                id="identifier"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.identifier
                                                })}
                                            />
                                            <span style={{ color: "red" }}>{errors.identifier}</span>
                                        </div>
                                    </div>
                                    {/* {sliderType == "web" ?
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="content">Content</label>
                                            </div>
                                            <div className="col-md-9">
                                            <Editor apiKey='5vk89nvvi2zckrb2lp2ctyyolewhq1v3pzdiwb7at68h40a5'
                                        // initialValue={this.state.formValue.content}
                                        value={content}
                                        onEditorChange={this.handleEditorChange}
                                        init={{
                                            height: 500,
                                            menubar: false,
                                            plugins: [
                                              'advlist autolink lists link image charmap print preview anchor',
                                              'searchreplace visualblocks code fullscreen',
                                              'insertdatetime media table paste code help wordcount'
                                          ],
                                          menubar:
                                          "file edit view insert format tools table tc help",
              
                                          toolbar:
                                          'undo redo code | formatselect | bold italic backcolor | \
                                         alignleft aligncenter alignright alignjustify | \
                                         bullist numlist outdent indent | removeformat | help',
                                         paste_data_images:true,
                                         file_browser_callback_types: 'image',
                                    //      images_upload_handler: function (blobInfo, success, failure) {
                                    //          console.log('blobInfo',blobInfo);
                                    //  },
                         
                                         selector: 'textarea',  // change this value according to your HTML
                                         file_picker_callback: function(callback, value, meta) {
                                         if (meta.filetype == 'file') {
                                             //callback('mypage.html', {text: 'My text'});
                                         }
                                         if (meta.filetype == 'image') {
                         
                                         }
                                         if (meta.filetype == 'media') {
                                             //callback('movie.mp4', {source2: 'alt.ogg', poster: 'image.jpg'});
                                             }
                                         }
                                       
                                        }}
                                    />
                                                <span className="text-danger">{errors.content}</span>
                                            </div>
                                        </div> : */}
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="content">Light image</label>
                                            </div>
                                            <div className="col-md-9">

                                                <input
                                                    type="file"
                                                    accept="image/x-png,image/gif,image/jpeg"
                                                    onChange={this.handleChangeefile}
                                                    name="light_image"
                                                    id="exampleInputFile"
                                                    aria-describedby="fileHelp"
                                                />
                                                <img src={light_image_url} style={{ 'width': '106px' }} />
                                                <span style={{ color: "red" }}>{errors.light_image}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="content">Dark image</label>
                                            </div>
                                            <div className="col-md-9">

                                            <input
                                                    type="file"
                                                    accept="image/x-png,image/gif,image/jpeg"
                                                    onChange={this.handleChangeefile}
                                                    name="dark_image"
                                                    id="exampleInputFile"
                                                    aria-describedby="fileHelp"
                                                />
                                                <img src={dark_image_url} style={{ 'width': '106px' }} />
                                                <span style={{ color: "red" }}>{errors.dark_image}</span>
                                            </div>
                                        </div>
                                        {/* } */}
                                        
                                </form>

                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={this.handleClose}
                                >
                                    Close
                                </button>
                                <button
                                    onClick={this.handleSubmit}
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    Submit
                                </button>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SliderManageADD;
