import React, { Component, Fragment } from "react";
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@ashvin27/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ArticleAddModal from "../partials/ArticleAddmodal.jsx";
import ArticleUpdateModal from "../partials/ArticleupdateModal";
import { toast, ToastContainer } from "react-toastify";
import $ from "jquery";
// import keys from "../../actions/config";
// const url = keys.baseUrl;
import keys from "../../config/index"



const url = keys.API_URL;

class Perpetual extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "title",
        text: "Category",
        className: "pair",
        align: "left",
        sortable: false,
        width: 200
      },
      {
        key: "category_name",
        text: "Title",
        className: "categoryname",
        align: "left",
        sortable: false,
        cell: record => {
          return (record.blog_category.category_name)
        }

      },

      // {
      //     key: "category_name",
      //     text: "Image",
      //     className: "categoryname",
      //     align: "left",
      //     sortable: false,
      //     cell: record => {
      //         return(
      //             <img src={`${url}/images/blogImages/${record.image}`} style={{width:"104px"}}/> 
      //             )
      //     }

      // },
      {
        key: "trendingPost",
        text: "Trend Post",
        className: "status",
        align: "left",
        sortable: false,
        cell: record => {
          if (record.trendingPost == 0) {
            return (
              <Fragment>
                <button variant="contained"
                  onClick={() => this.trendingPost(record._id)}
                  style={{ backgroundColor: "red" }}
                  color="primary">No</button>
              </Fragment>
            )
          }
          else {
            return (
              <Fragment>
                <button variant="contained"
                  onClick={() => this.trendingPost(record._id)}
                  style={{ backgroundColor: "green" }}
                  color="primary">Yes</button>
              </Fragment>
            )
          }


        }
      },

      {
        key: "status",
        text: "Status",
        className: "status",
        align: "left",
        sortable: false,
        cell: record => {
          return (record.status)
        }

      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: record => {
          return (
            <Fragment>
              <button
                data-toggle="modal"
                data-target="#update-article-modal"
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.deleteRecord(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        }
      }
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Perpetual",
      no_data_text: "No Articles found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last"
        }
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true
    };

    this.state = {
      records: []
    };

    this.state = {
      currentRecord: {
        id: "",
        tiker_root: ""
      }
    };

    this.getData = this.getData.bind(this);
  }


  componentDidMount() {
    this.getData();
  }

  componentWillReceiveProps(nextProps) {
    this.getData();
  }

  getData() {
    axios
      .get(url + "/api/getBlogList")
      .then(res => {
        console.log("save ", res.data.cmsData)
        if (res.data.cmsData != undefined) {
          this.setState({ records: res.data.cmsData });
        }
      })
      .catch();
  }
  trendingPost(id) {
    let passData = {
      id: id
    }
    axios
      .post(url + '/api/changeTrendingPost', passData).then(res => {
        toast(res.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
        this.getData();

      })

  }
  editRecord(record) {
    $("#update-article-modal")
      .find(".text-danger")
      .hide();
    console.log("recorxs", record);
    this.setState({ currentRecord: record });
  }
  addRecord() {
    $("#add-article-modal")
      .find(".text-danger")
      .hide();
  }

  deleteRecord(record) {
    if (!window.confirm("Are you sure you want to delete this Article?")) {
      return false;
    }

    axios
      .post(url + "/api/blogDelete", { _id: record._id })
      .then(res => {
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER
          });
        }
      })
      .catch();
    setTimeout(
      function () {
        this.getData();
      }.bind(this),
      1000
    );
  }

  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }

  render() {
    return (
      <>

        <ArticleAddModal />
        <ArticleUpdateModal record={this.state.currentRecord} />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <button
              onClick={() => this.addRecord()}
              className="btn btn-outline-primary float-right mt-3 mr-2"
              data-toggle="modal"
              data-target="#add-article-modal"
            >
              <FontAwesomeIcon icon={faPlus} /> Add Article
            </button>
            <h3 className="mt-2 text-secondary">Blog List</h3>
            <ReactDatatable
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              onPageChange={this.pageChange.bind(this)}
            />
          </div>
        </div>
        <ToastContainer />
      </>

    );
  }
}

Perpetual.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  records: state.records
});

export default connect(mapStateToProps)(withRouter(Perpetual));
