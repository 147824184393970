// import action
import { decodeJwt } from "./jsonWebToken";

// import config
import axios from "../config/axios";
import jwt_decode from "jwt-decode";
import Config from '../config/index';
// import lib
import { setAuthToken } from "../lib/localStorage";

import { SET_CURRENT_USER } from "./types";
let loginHistorySource, subAdminsSource;
export const login = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/adminapi/login`,
      data,
    });

    if (respData.data.twoFaStatus == "enabled") {
      return {
        status: "success",
        loading: false,
        message: respData.data.message,
        twoFaStatus: respData.data.twoFaStatus,
      };
    }
    setAuthToken(respData.data.token);
    // decodeJwt(respData.data.token, dispatch)
    const decoded = jwt_decode(respData.data.token);
    dispatch(setCurrentUser(decoded));
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};
export const getNotification = async (data) => {
  try {
      const respData = await axios({
          'url': `/adminapi/getNotification`,
          'method': 'get',
          'params': data
      })
      return {
          status: "success",
          loading: false,
          result: respData.data.result,
          count: respData.data.count

      }
  } catch (err) {
      return {
          status: 'failed',
          loading: false
      }
  }
}


export  const  clearAllNotification = async () => {
  try {
      let respData = await axios({
          'method': 'post',
          'url': `/adminapi/getNotification`,
      });
      return {
          status: "success",
          loading: false,
      }
  }
  catch (err) {
      return {
          status: "failed",
          loading: false,
          error: err.response.data.errors
      }
  }
}

export const loginHisPagination = async () => {
  try {
      let respData = await axios({
          'method': 'get',
          'url': `/adminapi/login-history`,
        
      });


      return {
          status: "success",
          loading: false,
          message: respData.data.message,
          result:respData.data.result
      }
  } catch (err) {
      return {
          status: 'failed',
          loading: false,
          message: err.response.data.message,
          error: err.response.data.errors
      }
  }
}




export const getGeoInfoData = async () => {
  try {
      let respData = await axios({
          'method': 'get',
          'url': `${Config.getGeoInfo}`,
        
      });


      return {
          status: "success",
          loading: false,
          message: respData.data.message,
          result:respData.data
      }
  } catch (err) {
      return {
          status: 'failed',
          loading: false,
          message: err.response.data.message,
          error: err.response.data.errors
      }
  }
}

export const loginHistory = async (data = {}) => {
  try {
      if (loginHistorySource) loginHistorySource.cancel();
      loginHistorySource = axios.CancelToken.source();

      let respData = await axios({
          method: "post",
          url: `/adminapi/loginHistory`,
          data,
          cancelToken: loginHistorySource.token,
      });

      return {
          status: "success",
          totalCount: respData.data.totalCount,
          result: respData.data.result,
      };
  } catch (err) {
      return {
          status: "failed",
      };
  }
};