import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from '@ashvin27/react-datatable';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

// import component
import { withRouter } from 'react-router-dom';
import LaunchpadAddModel from "../partials/LaunchpadAddModal";
import LaunchpadUpdateModal from "../partials/LaunchpadUpdateModal";
import { capitalize } from '../../lib/capitalize'
// import action
import { getLaunchpad, changeLaunchpadStatus, deleteLaunchpad } from '../../actions/LaunchpadActions';
import { getCurrency } from '../../actions/currency';
import { toast, ToastContainer } from "react-toastify";
import keys from "../../actions/config";
import $ from 'jquery';

// import lib
import { dateTimeFormat } from '../../lib/dateHelper';

const url = keys.baseUrl;



class Launchpad extends Component {
    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this)

        this.columns = [
            {
                key: "startDate",
                text: "Start Date",
                className: "date",
                align: "left",
                sortable: false,
                cell: record => {
                    return dateTimeFormat(record.startDate);
                }
            },
            {
                key: "endDate",
                text: "End Date",
                className: "date",
                align: "left",
                sortable: false,
                cell: record => {
                    return dateTimeFormat(record.endDate);
                }

            },
            {
                key: "tokenName",
                text: "Token Name",
                className: "email",
                align: "left",
                sortable: false
            },
            {
                key: "industry",
                text: "Industry",
                className: "email",
                align: "left",
                sortable: false
            },
            {
                key: "website",
                text: "Web Site",
                className: "email",
                align: "left",
                sortable: false
            },
            {
                key: "status",
                text: "Status",
                className: "email",
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <>{capitalize(record && record.status)}</>
                    )
                }
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 200,
                align: "left",
                sortable: false,
                cell: record => {
                    const checkin = record.status;
                    let show=true;
                    if (checkin == "active") {
                        var lockVal = 'fa fa-lock';
                    } else {
                        show=false
                        var lockVal = 'fa fa-unlock';
                    }

                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-launchpad-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                            </button>
                            {show?
                            <button
                                className="btn btn-primary btn-sm mr-1"
                                onClick={() => this.changestatus(record)}>
                                <i className={lockVal}></i>
                            </button>:<></>}
                            {/*<button
                                className="btn btn-danger btn-sm mr-1"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>*/}
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Launchpad",
            no_data_text: 'No user found!',
            sort: { column: "Created date", order: "desc" },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
            defaultSortAsc: true,
        };

        this.state = {
            currentRecord: {
                _id: '',
                tokenName: '',
                website: '',
                symbol: '',
                price: '',
                minAmt: '',
                maxSupply: '',
                industry: '',
                discount: '',
                image: '',
                created_date: '',
                content: '',
                availableSale: '',
                availableCurrency: '',
                startDate: new Date(),
                endDate: new Date(),
                currencyOptions: [],
            }
        };

    }

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    componentDidMount() {
        this.getData()
        this.getCurrencyData()
    };



    async changestatus(record) {
        let { status, message } = await changeLaunchpadStatus({ _id: record._id })
        if (status) {
            this.getData()
            toast(message, {
                position: toast.POSITION.TOP_CENTER,
            })
            
        }else{
            toast(message, {
                position: toast.POSITION.TOP_CENTER,
            })
        }
    }

    async getData() {
        let { status, result } = await getLaunchpad()
        if (status) {
            this.setState({ records: result })
        }
    }

    async getCurrencyData() {
        let { status, result } = await getCurrency()
        if (status == "success") {
            let arrayData = [];
            result && result.length > 0 && result.map((item, i) => {
                console.log("aaaaaaaaaaaaaaaa", item);
                if (item.type != "fiat") {
                    let resobj = {
                        value: item.currencySymbol,
                        label: item.currencySymbol
                    }
                    arrayData.push(resobj)
                }
            })
            this.setState({ currencyOptions: arrayData })
        }
    }

    editRecord(record) {
        $("#update-launchpad-modal").find(".text-danger").hide();
        this.setState({ currentRecord: record });
    }

    async deleteRecord(record) {

        if (window.confirm("Are you Sure delete confirm?")) {

            let { status, message } = await deleteLaunchpad({ _id: record._id })
            if (status) {
                toast(message, {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
            this.getData();
        }
        return
    }



    onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    };


    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {

        return (
            <>

                <LaunchpadAddModel getData={this.getData} currencyOptions={this.state.currencyOptions} />
                <LaunchpadUpdateModal record={this.state.currentRecord} getData={this.getData} currencyOptions={this.state.currencyOptions} />

                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-launch-modal"><FontAwesomeIcon icon={faPlus} /> add</button>
                        <h3 className="mt-2 text-secondary">Launchpad Management</h3>
                        <ReactDatatable
                            // responsive={this.state.responsive}
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            onPageChange={this.pageChange.bind(this)}
                        />
                    </div>
                </div>
            </>

        );
    }
}

export default withRouter(Launchpad);
