import React, { Component, Fragment } from "react";
import { withRouter } from 'react-router-dom';

import ReactDatatable from "@ashvin27/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import keys from "../../actions/config";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
const url = keys.baseUrl;

class StakeSettlement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      search: "",
      page: 1,
      limit: 10,
      count: 0,
      loader: false,
    };
    this.columns = [
      {
        key: "date",
        text: "Date",
        className: "date",
        align: "left",
        sortable: false,
        width: 200,
        cell: (record) => {
          return moment(record.settleDate).format("YYYY-MM-DD h:mm:ss");
        },
      },
      {
        key: "email",
        text: "Email",
        className: "date",
        align: "left",
        sortable: false,
        width: 200,
        cell: (record) => {
          if (record && record.userId) {
            return record.userId.email;
          }
          return "-";
        },
      },
      {
        key: "stakeOrderId",
        text: "Order Id",
        className: "type",
        align: "left",
        sortable: false,
        width: 200,
      },
      {
        key: "_id",
        text: "User Id",
        className: "type",
        align: "left",
        sortable: false,
        width: 200,
        cell: (record) => {
          if (record && record.userId) {
            return record.userId._id;
          }
          return "-";
        },
      },
      {
        key: "coin",
        text: "Coin",
        className: "type",
        align: "left",
        sortable: false,
        width: 200,
      },
      {
        key: "amount",
        text: "Amount",
        className: "type",
        align: "left",
        sortable: false,
        width: 200,
      },

      {
        key: "StakeType",
        text: "Stake Type",
        className: "type",
        align: "left",
        sortable: false,
        width: 200,
      },
      {
        key: "type",
        text: "Type",
        className: "type",
        align: "left",
        sortable: false,
        width: 200,
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Order",
      no_data_text: "No Records found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in Coins...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.fetchData = this.fetchData.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
  }
  componentDidMount() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };
    this.fetchData(reqData);
  }

  async fetchData(reqData) {
    this.setState({ loader: true });
    try {
      const respData = await axios({
        url: `${url}adminapi/stakingsettlementHistory`,
        method: "get",
        params: reqData,
      });
      this.setState({
        loader: false,
        count: respData.data.count,
        records: respData.data.data,
      });
    } catch (err) {
      this.setState({ loader: false });
    }
  }

  handlePagination(index) {
    let reqData = {
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    };
    this.fetchData(reqData);
    this.setState({
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    });
  }

  render() {
    const { records, loader, count } = this.state;
    return (
      <>

        <div id="page-content-wrapper">
          <div className="container-fluid">
            <h3 className="mt-2 text-secondary">Stake Settlement</h3>
            <ReactDatatable
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              dynamic={true}
              total_record={count}
              loading={loader}
              onChange={this.handlePagination}
            />
          </div>
        </div>
        <ToastContainer />
      </>

    );
  }
}

StakeSettlement.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
});

export default connect(mapStateToProps)(withRouter(StakeSettlement));
