import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updatearticle } from "../../actions/category";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import $ from "jquery";
import { Editor } from "@tinymce/tinymce-react";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import axios from "axios";
// import keys from "../../actions/config";
// const url = keys.baseUrl;
import { Form } from "react-bootstrap";
import keys from "../../config/index";
import DatePicker from "react-datepicker";
const url = keys.API_URL;
const imageurl = keys.API_URL;

const statusOptions = [
  { value: "New", label: "New" },
  { value: "Update", label: "Update" },
  { value: "Read more ...", label: "Read more" },
  { value: "Deactive", label: "Deactive" },
];

const initialFormValue = {
  id: "",
  title: "",
  content: "",
  image: "",
  articlename: "",
  metatitle: "",
  metadescription: "",
  metakeywords: "",
  alttag: "",
  author: "",
  social_link1: "",
  social_link2: "",
  social_link3: "",
  social_link4: "",
  social_link5: "",
  alttag_pro: "",
  slug: "",
  query_image: "",
  curimage: null,
  title: "",
  promotion_image: "",
  query_image_pro: "",
  curimage_pro: null,
  slugerror: "",
  authorDetails: "",
  authorImage: "img",
  curimage_auther: '',
  query_image_auther: '',
  status: 'New',
  date: '',

};

class ArticleupdateModal extends React.Component {
  constructor(props) {
    super(props);

    console.log(props, "propspropspropspropspropsprops");

    this.state = {
      formValue: initialFormValue,
      errors: {},
    };
    $("#update-subcategory-modal").find(".text-danger").hide();
   
    this.handleEditorChange = this.handleEditorChange.bind(this);
    //this.getBlogCategoryEditData = this.getBlogCategoryEditData.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    console.log(nextProps, "poooooooo");
    if (nextProps.record) {
      let formData = {
        id: nextProps.record._id,
        // 'title' : nextProps.record.title,
        content: nextProps.record.content,
        image: `${url}/images/blogImages/${nextProps.record.image}`,
        articlename: { label: nextProps.record.title },
      };
      //  this.setState({ formValue: formData })
      console.log(nextProps.record.blog_category, "lllllll");
      if (nextProps.record.blog_category) {
        var maincategoryId = {
          value: nextProps.record.blog_category._id,
          label: nextProps.record.blog_category.category_name,
        };
        console.log("nextProps.record.status", nextProps.record.status)
        this.setState({
          formValue: {
            id: nextProps.record._id,
            articlename_update: nextProps.record.Articlename,
            title: nextProps.record.title,
            categoryName: nextProps.record.blog_category._id,
            //subcategoryName: nextProps.record.subcategoryName,
            content: nextProps.record.content,
            maincategoryId: maincategoryId,
            //subcategoryId: subcategoryId,
            hidden1: nextProps.record.categoryName,
            image: nextProps.record.image,
            articlename_update: nextProps.record.Articlename,
            metatitle: nextProps.record.metatitle,
            metadescription: nextProps.record.metadescription,
            metakeywords: nextProps.record.metakeywords,
            alttag: nextProps.record.alttag,
            author: nextProps.record.author,

            social_link1: nextProps.record.social_link1,
            social_link2: nextProps.record.social_link2,
            social_link3: nextProps.record.social_link3,
            social_link4: nextProps.record.social_link4,
            social_link5: nextProps.record.social_link5,
            promotion_image: nextProps.record.promotion_image,
            authorImage: nextProps.record.authorImage,
            authorDetails: nextProps.record.authorDetails,
            alttag_pro: nextProps.record.alttag_pro,
            slug: nextProps.record.slug,
            status: nextProps.record.status,
            date: nextProps.record.date ? new Date(nextProps.record.date) : new Date(),
            errors: "",
          },
        });
      }
   
    }
    if (nextProps.errors) {
      $("#update-subcategory-modal").find(".text-danger").show();
      this.setState({
        errors: nextProps.errors,
      });
    } else {
      this.setState({
        errors: "",
      });
    }
    // console.log(nextProps)
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.currencyupdate !== undefined &&
      nextProps.auth.currencyupdate.data !== undefined &&
      nextProps.auth.currencyupdate.data.message !== undefined &&
      nextProps.auth.currencyupdate.data.success
    ) {
      $("#update-subcategory-modal").modal("hide");
      toast(nextProps.auth.currencyupdate.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      nextProps.auth.currencyupdate = "";
      this.setState({
        errors: "",
      });
    }
  }



  onChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    console.log(name, value, "=====");
    let formData = { ...this.state.formValue, ...{ [name]: value } };
    this.setState({ formValue: formData });
    let errData = { ...this.state.errors, ...{ [name]: "" } };
    this.setState({ errors: errData });
  };

  handleDate = (date) => {
    console.log("datedatedatedate", date)
    let formData = { ...this.state.formValue, ...{ date: date } };
    this.setState({ formValue: formData });
  };


  handleEditorChange(content, editor) {
    this.setState({ content });
  }

  handleChangefile = (event) => {
    console.log(
      URL.createObjectURL(event.target.files[0]),
      "event.target.files[0]"
    );
    this.setState({
      curimage: URL.createObjectURL(event.target.files[0]),
      query_image: event.target.files[0],
    });
  };


  handleChangefile1 = (event) => {
    console.log(
      URL.createObjectURL(event.target.files[0]),
      "event.target.files[0]"
    );
    this.setState({
      curimage_pro: URL.createObjectURL(event.target.files[0]),
      query_image_pro: event.target.files[0],
    });
  };

  handleChangefileauther = (event) => {
    console.log(
      URL.createObjectURL(event.target.files[0]),
      "event.target.files[0]"
    );

    this.setState({
      curimage_auther: URL.createObjectURL(event.target.files[0]),
      query_image_auther: event.target.files[0],
    });
  };

  handleChange = (selectedOption) => {
    this.setState({ categoryName: selectedOption.value });
    console.log(`Option selected:`, selectedOption);

    var data = {
      categoryid: selectedOption.value,
    };
    axios
      .post(url + "api/sub-category-select-find", data)
      .then((subcategory) => {
        console.log("Response data====>", subcategory);
        var subcategoryarray = [];
        subcategory.data.map((item, i) => {
          const name = item.subcategoryName;
          const value = item._id;
          const obj = { value: value, label: name };
          subcategoryarray.push(obj);
        });
        console.log(subcategoryarray, "subcategoryarray");
        this.setState({
          subcategoryoption: subcategoryarray,
          email_assigned: true,
        });
      });
  };

  handleChange1 = (selectedOption) => {
    this.setState({ subcategorydropdown: selectedOption });
  };

  componentDidMount() {

    // this.setState({ errors: "" });
    this.getData();
    this.getBlogCategoryEditData();
  }

  getBlogCategoryEditData() {
    axios.get(url + "/api/getBlogCategoryList").then((blogcategory) => {
      var categoryarray = [];
      blogcategory.data.data.map((item, i) => {
        const name = item.category_name;
        const value = item._id;
        const obj = { value: value, label: name };
        categoryarray.push(obj);
      });
      console.log(categoryarray, 'currencyarray');
      this.setState({ articleOption: categoryarray, email_assigned: true });
    });
  }
  getData() {
    axios
      .get(url + "/api/getBlogCategoryList")
      .then((subcategory) => {
        var categoryarray = [];
        subcategory.data.data.map((item, i) => {
          const name = item.category_name;
          const value = item._id;
          const obj = { value: value, label: name };
          categoryarray.push(obj);
        });
        console.log(categoryarray, "addcategroyyyyyyyoyyyyyyy");
        this.setState({ categoryName1: categoryarray, email_assigned: true });
      })
      .catch();
  }

  onArticleupdate = async (e) => {
    e.preventDefault();
    $("#update-article-modal").find(".text-danger").show();


    const {
      maincategoryId,
      selectedOption,
      subcategoryId,
      subcategoryName1,
      subcategoryoption,
      subcategorydropdown,
      metatitle,
      metadescription,
      metakeywords,
      alttag,
      author,
      social_link1,
      social_link2,
      social_link3,
      social_link4,
      social_link5,
      alttag_pro,
      query_image_pro,
      slug,
      categoryName,
      title,
      image,
      authorDetails,
      authorImage,
      promotion_image,
      status,
      date
    } = this.state.formValue;
    console.log("slugslugslugslugslugslugslugslug", slug);
    // if (slug == "" || slug == "undefined" || slug == undefined) {
    //   return this.setState({ slugerror: "Required" });
    // } else {
    //   // let rewhitespaceinslug=await slug.replace(/\s+/g, '-')
    //   let resplchane = await slug.replace(/[^\w\s]/gi, "-");
    //   console.log("resplchaneresplchaneresplchane", resplchane);
    //   if (
    //     resplchane == "" ||
    //     resplchane == "undefined" ||
    //     resplchane == undefined
    //   ) {
    //     return this.setState({ slugerror: "Required" });
    //   }
    const data = new FormData();
    data.append("_id", this.state.formValue.id);
    data.append("title", title);

    //    data.append("articledetails", this.state.formValue.articlename.label);
    data.append("maincategoryId", maincategoryId.value);
    // // data.append("subcategoryId",  this.state.subcategorydropdown.value);
    data.append("content", this.state.content);
    data.append("file", this.state.query_image);
    data.append("alttag", alttag);
    data.append("author", author);
    data.append("authorDetails", authorDetails);
    data.append("authorImage", this.state.query_image_auther);
    data.append("metatitle", metatitle);
    data.append("metadescription", metadescription);

    data.append("metakeywords", metakeywords);
    data.append("social_link1", social_link1);
    data.append("social_link2", social_link2);

    data.append("social_link3", social_link3);

    data.append("social_link4", social_link4);

    data.append("social_link5", social_link5);

    data.append("file_pro", this.state.query_image_pro);
    data.append("alttag_pro", alttag_pro);

    data.append("slug", slug);
    data.append("status", status);
    data.append("date", date);

    // return false
    axios
      .post(url + "/api/blogEdit", data)
      .then((res) => {
        console.log(res, "dddddddddd");
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          // this.getData();
          // window.location.reload(false)

          $("#update-article-modal").modal("hide");
          window.location.reload();
        } else {
          $("#update-article-modal").modal("hide");
          // window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err.response.data.errors, "err.response.data.errors");
        this.setState({ errors: err.response.data.errors });
      });
    this.getData();

    // this.props.updatearticle(newcategory);
    // }
  };
  handleCategory = (SelectedCategory) => {
    console.log(SelectedCategory, "SelectedCategory");
    // let formData = {
    //      'articlename': SelectedCategory
    //    }
    // this.setState({ ...this.state.formValue,...formData })
    let formData = {
      ...this.state.formValue,
      ...{ maincategoryId: SelectedCategory },
    };
    this.setState({ formValue: formData });
  };
  handleStatus = (e) => {
    e.preventDefault();
    let { name, id, value } = e.target;
    let formData = { ...this.state.formValue, ...{ [id]: value } };
    this.setState({ formValue: formData });
  };


  render() {
    const {
      maincategoryId,
      selectedOption,
      subcategoryId,
      subcategoryName1,
      subcategoryoption,
      subcategorydropdown,
      metatitle,
      metadescription,
      metakeywords,
      alttag,
      author,
      social_link1,
      social_link2,
      social_link3,
      social_link4,
      social_link5,
      alttag_pro,
      slug,
      image,
      categoryName,
      title,
      promotion_image,
      authorImage,
      authorDetails,
      status,
      date
    } = this.state.formValue;
    const { errors } = this.state;
    console.log(this.state.formValue, "this.state.formValue");
    return (
      <div>
        <div className="modal fade" id="update-article-modal" data-keyboard="false" data-backdrop="static">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Update Category</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form
                  noValidate
                  onSubmit={this.onArticleupdate}
                  id="update-subcategory"
                >
                  <input
                    onChange={this.onChange}
                    value={maincategoryId}
                    id="category-update-id"
                    type="text"
                    className="d-none"
                  />
                  <input
                    onChange={this.onChange}
                    value={this.state.hidden}
                    id="hidden"
                    type="text"
                    className="d-none"
                  />
                  <input
                    onChange={this.onChange}
                    value={this.state.hidden1}
                    id="hidden1"
                    type="text"
                    className="d-none"
                  />

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">Title</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={title}
                        error={errors.title}
                        name="title"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.title,
                        })}
                      />
                      <span style={{ color: "red" }}>{errors.title}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyName">Category</label>
                    </div>
                    <div className="col-md-9">
                      <Select
                        onChange={this.handleCategory}
                        value={maincategoryId}
                        // id="articlename_update"
                        options={this.state.articleOption}
                      // error={errors.currencyName}
                      />
                      <span style={{ color: "red" }}>{errors.currencyName}</span>
                    </div>
                  </div>
                  {/*}  <div className="row mt-5">
                                      <div className="col-md-3">
                                        <label htmlFor="name">Image</label>
                                      </div>
                                      <div className="col-md-3">
                                        {" "}
                                        <img
                                          className="img-fluid proofThumb mb-3"
                                          src={imageurl + "help_images/" + this.state.article_image}
                                        />

                                      </div>
                                    </div>
*/}

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <br />
                      <label htmlFor="query_image">Image</label>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="file"
                        accept="image/x-png,image/gif,image/jpeg"
                        onChange={this.handleChangefile}
                      />
                      <img width="100px" />
                      {this.state.query_image ? (
                        <img width="100px" src={this.state.curimage} />
                      ) : (
                        <img
                          className="img-fluid proofThumb mb-3"
                          src={imageurl + "/images/blogImages/" + image}
                        />
                      )}
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">AltTag</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={alttag}
                        error={errors.alttag}
                        name="alttag"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.alttag,
                        })}
                      />

                      <span style={{ color: "red" }}>{errors.alttag}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">Author</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={author}
                        error={errors.author}
                        name="author"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.author,
                        })}
                      />
                      <span style={{ color: "red" }}>{errors.author}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">Author Details</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={authorDetails}
                        error={errors.authorDetails}
                        name="authorDetails"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.authorDetails,
                        })}
                      />
                      <span style={{ color: "red" }}>{errors.authorDetails}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <br />
                      <label htmlFor="authorImage">Auther Image</label>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="file"
                        accept="image/x-png,image/gif,image/jpeg"
                        onChange={this.handleChangefileauther}
                      />
                      <img width="100px" />
                      {this.state.curimage_auther ? (
                        <img width="100px" src={this.state.curimage_auther} />
                      ) : (
                        <img
                          className="img-fluid proofThumb mb-3"
                          src={imageurl + "/images/blogImages/" + authorImage}
                        />
                      )}
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="content">Content</label>
                    </div>
                    <div className="col-md-9">
                      <Editor
                        apiKey="5vk89nvvi2zckrb2lp2ctyyolewhq1v3pzdiwb7at68h40a5"
                        initialValue={this.state.formValue.content}
                        // value={this.state.formValue.content}
                        onEditorChange={this.handleEditorChange}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          images_upload_handler: function (
                            blobInfo,
                            success,
                            failure
                          ) {
                            let data = new FormData();
                            data.append(
                              "file",
                              blobInfo.blob(),
                              blobInfo.filename()
                            );
                            axios
                              .post(url + "/api/tinyimgupload", data)

                              .then(function (res) {
                                console.log(res, "kr resss");
                                success(
                                  url + "/images/tinymce/" + res.data.result
                                );
                              })
                              .catch(function (err) {
                                failure("HTTP Error: " + err.message);
                              });
                          },

                          menubar:
                            "file edit view insert format tools table tc help",
                          toolbar:
                            "undo redo code | formatselect | bold italic backcolor | \
                                               alignleft aligncenter alignright alignjustify | \
                                               bullist numlist outdent indent | removeformat | help",
                        }}
                      />
                      <span style={{ color: "red" }}>{errors.content}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">Meta Title</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={metatitle}
                        error={errors.metatitle}
                        name="metatitle"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.metatitle,
                        })}
                      />
                      <span style={{ color: "red" }}>{errors.metatitle}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">Meta Description</label>
                    </div>
                    <div className="col-md-9">
                      <textarea
                        onChange={this.onChange}
                        value={metadescription}
                        error={errors.metadescription}
                        name="metadescription"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.metadescription,
                        })}
                      />
                      <span style={{ color: "red" }}>
                        {errors.metadescription}
                      </span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">Meta Keywords</label>
                    </div>
                    <div className="col-md-9">
                      <textarea
                        onChange={this.onChange}
                        value={metakeywords}
                        error={errors.metakeywords}
                        name="metakeywords"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.metakeywords,
                        })}
                      />
                      <span style={{ color: "red" }}>{errors.metakeywords}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="social_link1">Twitter URL</label>
                    </div>
                    <div className="col-md-6">
                      <input
                        onChange={this.onChange}
                        value={social_link1}
                        name="social_link1"
                        type="text"
                        error={errors.social_link1}
                        className={classnames("form-control", {
                          invalid: errors.social_link1,
                        })}
                      />
                      <span style={{ color: "red" }}>{errors.social_link1}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="social_link1">Medium URL</label>
                    </div>
                    <div className="col-md-6">
                      <input
                        onChange={this.onChange}
                        value={social_link2}
                        name="social_link2"
                        type="text"
                        error={errors.social_link2}
                        className={classnames("form-control", {
                          invalid: errors.social_link2,
                        })}
                      />
                      <span style={{ color: "red" }}>{errors.social_link2}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="social_link1">Telegram Link</label>
                    </div>
                    <div className="col-md-6">
                      <input
                        onChange={this.onChange}
                        value={social_link3}
                        name="social_link3"
                        type="text"
                        error={errors.social_link3}
                        className={classnames("form-control", {
                          invalid: errors.social_link3,
                        })}
                      />
                      <span style={{ color: "red" }}>{errors.social_link3}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="social_link1">Facebook URL</label>
                    </div>
                    <div className="col-md-6">
                      <input
                        onChange={this.onChange}
                        value={social_link4}
                        name="social_link4"
                        type="text"
                        error={errors.social_link4}
                        className={classnames("form-control", {
                          invalid: errors.social_link4,
                        })}
                      />
                      <span style={{ color: "red" }}>{errors.social_link4}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="social_link1">Linkedin URL</label>
                    </div>
                    <div className="col-md-6">
                      <input
                        onChange={this.onChange}
                        value={social_link5}
                        name="social_link5"
                        type="text"
                        error={errors.social_link5}
                        className={classnames("form-control", {
                          invalid: errors.social_link5,
                        })}
                      />
                      <span style={{ color: "red" }}>{errors.social_link5}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <br />
                      <label htmlFor="query_image">Promotion Image</label>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="file"
                        accept="image/x-png,image/gif,image/jpeg"
                        onChange={this.handleChangefile1}
                      />
                      <img width="100px" />
                      {this.state.query_image_pro ? (
                        <img
                          className="img-fluid proofThumb mb-3"
                          src={this.state.curimage_pro}
                        />
                      ) : (
                        <img
                          className="img-fluid proofThumb mb-3"
                          src={
                            imageurl + "/images/blogImages/" + promotion_image
                          }
                        />
                      )}
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">AltTag</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={alttag_pro}
                        error={errors.alttag_pro}
                        name="alttag_pro"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.alttag_pro,
                        })}
                      />
                      <span style={{ color: "red" }}>{errors.alttag_pro}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">Slug</label>
                    </div>
                    <div className="col-md-9">
                      <textarea
                        onChange={this.onChange}
                        value={slug}
                        error={errors.slug}
                        name="slug"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.slug,
                        })}
                      />

                      <span style={{ color: "red" }}>{errors.slug}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">Date</label>
                    </div>
                    <div className="col-md-9">
                      <div>
                        <DatePicker
                          selected={date}
                          onChange={this.handleDate}
                          dateFormat="MMMM d, yyyy"
                          className={classnames("form-control", {
                            invalid: errors.date
                          })}
                          dateFormat="MMMM d, yyyy h:mm aa"
                          showTimeSelect
                        />
                        <span style={{ color: "red" }}>{errors.date}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="password2">Status</label>
                    </div>
                    <div className="col-md-9">

                      <Form.Control
                        as="select"
                        custom
                        name={"status"}
                        id={"status"}
                        value={status}
                        onChange={this.handleStatus}
                      >

                        {statusOptions &&
                          statusOptions.length > 0 &&
                          statusOptions.map((item, key) => {
                            return (
                              <option key={key} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                      </Form.Control>
                      <span style={{ color: "red" }}>{errors.status}</span>
                    </div>
                  </div>

                
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  form="update-subcategory"
                  type="submit"
                  className="btn btn-primary"
                >
                  Update Article
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ArticleupdateModal.propTypes = {
  updatearticle: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { updatearticle })(
  withRouter(ArticleupdateModal)
);
