import React, { Component, Fragment } from "react";
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ContactUpdateModal from "../partials/ContactUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import keys from "../../actions/config";
const url = keys.baseUrl;
class Chat extends Component {
    constructor(props) {
        super(props);

        this.columns = [

            {
                key: "userName",
                text: "Name",
                className: "userName",
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        (record.userId.name != '') ? record.userId.name : (record.userId.email.split("@"))[0]
                    );
                }
            },
            {
                key: "message",
                text: "Message",
                className: "message",
                align: "left",
                sortable: false
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Cms",
            no_data_text: 'No Enquires found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
                _id: '',
                name: '',
                email: '',
                mobilenumber: '',
                message: '',
                reply: ''
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        axios
            .get(url + "api/chat-data")
            .then(res => {
                console.log(res, 'res');
                this.setState({ records: res.data })
            })
            .catch()
    }

    editRecord(record) {
        console.log(record, 'record');
        this.setState({ currentRecord: record });
    }

    deleteRecord(record) {
        console.log(record);
        axios
            .post(url + "api/chat-delete", { _id: record._id })
            .then(res => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <>

                <ContactUpdateModal record={this.state.currentRecord} />
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <h3 className="mt-2 text-secondary">Chats List</h3>
                        <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            onPageChange={this.pageChange.bind(this)}
                        />
                    </div>
                </div>
                <ToastContainer />

            </>
        );
    }

}

Chat.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(withRouter(Chat));
