import React from "react";
import classnames from "classnames";
import { Modal } from "react-bootstrap";
import $ from 'jquery';
import { Editor } from "@tinymce/tinymce-react";
import fileObjectUrl from '../../lib/fileObjectUrl';

// import action
import { updateLaunchpadCms } from "../../actions/cmsActions";
import config from '../../config';
import axios from "axios";
// import lib
import { toastAlert } from "../../lib/toastAlert";
import { CKEditor } from "ckeditor4-react";
import keys from "../../config/index";

const url = keys.API_URL;
// const url = keys.baseUrl;
const imageurl = keys.API_URL;
const initialFormValue = {
  id: "",
  identifier: "",
  title: "",
  content: "",
  image: "",
  metatitle: "",
  metadescription: "",
  metakeywords: "",
  metalink: "",
  
};

class CmsUpdateModal extends React.Component {
  constructor(props) {
    super();
    this.state = {
      formValue: initialFormValue,
    //   imageUrl: this.props.image,

      errors: {},
    };
    this.handleEditorChange = this.handleEditorChange.bind(this);
    $("#update-lanchpadcms-modal").find(".text-danger").hide()
  }

  componentWillReceiveProps(nextProps) {
    const { record } = nextProps;
    if (record&& typeof(record)!="undefined") {
      console.log("recordrecordrecordrecord",record.content)
      this.setState({
        formValue: {
          id: record._id,
          identifier: record.identifier,
          title: record.title,
          content: record.content,
        //   image: record.image,
          imageUrl: config.API_URL+"/images/currency/"+nextProps.record.image,

          metatitle: record.metatitle,
          metadescription: record.metadescription,
          metakeywords: record.metakeywords,
          metalink: record.metalink,

        },
      });
    }


      // console.log(nextProps)
   
  }

  handleChangeefile = (event) => {
    if(event.target.files[0]){
        var filesize= event.target.files[0].size
        this.setState({
          curimage: URL.createObjectURL(event.target.files[0]),
          [event.target.name]: event.target.files[0],
        });
    }

    if(event.target.name=="image"){
        this.setState({imageUrl:fileObjectUrl(event.target.files[0])})
    }
    else if(event.target.name=="whitePaper"){
        this.setState({whitePaperUrl:fileObjectUrl(event.target.files[0])})
    }
};


  handleChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let formData = { ...this.state.formValue, ...{ [name]: value } };
    this.setState({ formValue: formData });
  };

  // handleEditorChange(content, editor) {
  //     let formData = { ...this.state.formValue, ...{ 'content': content } };
  //     this.setState({ formValue: formData });
  // }

  // handleEditorChange(e) {
  //   let formData = {
  //     ...this.state.formValue,
  //     ...{ content: e.editor.getData() },
  //   };
  //   this.setState({ formValue: formData });
  // }

  
  handleEditorChange(content, editor) {
      console.log("handleEditorChange",content)
    this.setState({ content });
  }

  handleFileChange = (event) => {
    this.setState({ image: event.target.files });
  };

  handleClose = () => {
    const { onHide } = this.props;
    onHide();
    this.setState({ formValue: initialFormValue, errors: {} });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      id,
      identifier,
      title,
      content,
      image,
      metakeywords,
      metatitle,
      metadescription,
      metalink
    } = this.state.formValue;
    const { fetchData } = this.props;

    const formData = new FormData();
    formData.append("id", id);
    formData.append("identifier", identifier);
    formData.append("title", title);
    formData.append("content", this.state.content);
    formData.append("metatitle", metatitle);
    formData.append("metadescription", metadescription);
    if(typeof(this.state.image)!="undefined")
    formData.append("image", this.state.image);

    formData.append("metakeywords", metakeywords);
    formData.append("metalink", metalink);


    // for (const key of Object.keys(image)) {
    //     formData.append('file', image[key])
    // }

    let reqData = {
      id,
      identifier,
      title,
      content,
      metatitle,
      metadescription,
      metakeywords,
      metalink
    };

    const { status, loading, message, error } = await updateLaunchpadCms(formData);
    if (status == "success") {
      toastAlert("success", message, "updateCms");
    //   fetchData();

  window.location.reload("")
      this.handleClose();
    } else {
      if (error) {
        this.setState({ errors: error });
      }
      toastAlert("error", message, "updateCms");

  window.location.reload("")
    }


  };

  render() {
    const {
      identifier,
      title,
      content,
      metakeywords,
      metadescription,
      metatitle,
      metalink
    } = this.state.formValue;
    const { errors } = this.state;
    const { isShow } = this.props;

    return (


        <div>
        <div className="modal fade" id="update-lanchpadcms-modal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Update Category</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
              <form noValidate onSubmit={this.onCmsUpdate} id="update-cms">
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Identifier</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={identifier}
                    name="identifier"
                    type="text"
                    error={errors.identifier}
                    className={classnames("form-control", {
                      invalid: errors.identifier,
                    })}
                    readOnly
                  />
                  <span className="text-danger">{errors.identifier}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="identifier">Page Name</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={title}
                    error={errors.title}
                    name="title"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.title,
                    })}
                  />
                  <span className="text-danger">{errors.title}</span>
                </div>
              </div>
              <div className="row mt-2">
                                  <div className="col-md-3">
                                  <label htmlFor="minimum">Image Upload</label>
                                  </div>
                                  <div className="col-md-9">
                                  <input
                                        type="file"
                                        accept="image/x-png,image/gif,image/jpeg"
                                        onChange={this.handleChangeefile}
                                        name="image"
                                        id="exampleInputFile"
                                        aria-describedby="fileHelp"
                                      />
                                      <span className="text-danger">{errors.image}</span>
                                     <img src={this.state.imageUrl} style={ { 'width': '106px'} } /> 
                                  </div>
                                </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="content">Content</label>
                </div>
                <div className="col-md-9">
                  {/* <Editor apiKey='5vk89nvvi2zckrb2lp2ctyyolewhq1v3pzdiwb7at68h40a5'
                                        initialValue={content}
                                        value={content}
                                        onEditorChange={this.handleEditorChange}
                                        init={{
                                            height: 500,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar:
                                                'undo redo code | formatselect | bold italic backcolor | \
                                               alignleft aligncenter alignright alignjustify | \
                                               bullist numlist outdent indent | removeformat | help'
                                        }}
                                    /> */}
                  {/* <CKEditor
                    config={{
                      extraAllowedContent: "div(*)",
                      allowedContent: true,
                      height: 500,
                    }}
                    initData={content}
                    onChange={this.handleEditorChange}
                  /> */}
                    <Editor
                        apiKey="5vk89nvvi2zckrb2lp2ctyyolewhq1v3pzdiwb7at68h40a5"
                        // initialValue="<p>This is the initial content of the editor</p>"
                        initialValue={this.state.formValue.content}
                        onEditorChange={this.handleEditorChange}
                        init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                          
                          // images_upload_url: "postAcceptor.php",

                          /* we override default upload handler to simulate successful upload*/

                          images_upload_handler: function (
                            blobInfo,
                            success,
                            failure
                          ) {
                            let data = new FormData();
                            data.append(
                              "file",
                              blobInfo.blob(),
                              blobInfo.filename()
                            );
                            axios
                              .post(url + "/api/tinyimgupload", data)

                              .then(function (res) {
                                console.log(res, "kr resss");
                                success(
                                  url + "/images/tinymce/" + res.data.result
                                );
                              })
                              .catch(function (err) {
                                failure("HTTP Error: " + err.message);
                              });
                          },

                          menubar:
                            "file edit view insert format tools table tc help",

                            toolbar:
                            'undo redo code | formatselect | bold italic backcolor | \
                           alignleft aligncenter alignright alignjustify | \
                           bullist numlist outdent indent | removeformat | help'
                    }}
                      />
                  <span className="text-danger">{errors.content}</span>
                </div>
              </div>

              {/* <div className="row mt-2">
                                <div className="col-md-3">
                                    <br />
                                    <label htmlFor="image">Image</label>
                                </div>
                                <div className="col-md-9">
                                    <input type="file" multiple onChange={this.handleFileChange}
                                    />

                                </div>
                            </div> */}

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="identifier">Meta Title</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={metatitle}
                    error={errors.metatitle}
                    name="metatitle"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.metatitle,
                    })}
                  />
                  <span className="text-danger">{errors.metatitle}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="identifier">Redirection Link</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={metalink}
                    error={errors.metalink}
                    name="metalink"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.metalink,
                    })}
                  />
                  <span className="text-danger">{errors.metalink}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="identifier">Meta Description</label>
                </div>
                <div className="col-md-9">
                  <textarea
                    onChange={this.handleChange}
                    value={metadescription}
                    error={errors.metadescription}
                    name="metadescription"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.metadescription,
                    })}
                  />
                  <span className="text-danger">{errors.metadescription}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="identifier">Meta Keywords</label>
                </div>
                <div className="col-md-9">
                  <textarea
                    onChange={this.handleChange}
                    value={metakeywords}
                    error={errors.metakeywords}
                    name="metakeywords"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.metakeywords,
                    })}
                  />
                  <span className="text-danger">{errors.metakeywords}</span>
                </div>
              </div>
            </form>
                    <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                onClick={this.handleSubmit}
                  form="update-subcategory"
                  type="submit"
                  className="btn btn-primary"
                >
                  Update launchpad CMS
                </button>
              </div>
            </div>
            </div>
            </div>
            </div>
            </div>
   
    );
  }
}


export default CmsUpdateModal;
