let baseUrl;

if (process.env.REACT_APP_MODE === "production") {
  baseUrl = "https://producationapi.mudra.exchange/"; //demo
 } else if (process.env.REACT_APP_MODE === "development") {
 baseUrl = "https://mudraapi.wealwin.com/"; //demo
 }else{
   baseUrl = "http://localhost:2053/";
 }


module.exports = {
  baseUrl: baseUrl,
  imageUrl: baseUrl,
};
