// import package
import React from "react";
import classnames from "classnames";

// import component
import { withRouter } from "react-router-dom";
import * as moment from "moment";
// import action
import { getMessage, replyMsg, closeTicket } from "../../actions/supportAction";
import config from "../../config/index";
// import lib
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty";

const initialFormValue = {
  message: "",
};

class SupportReply extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: {},
      msgConversation: [],
      formValue: initialFormValue,
      errors: {},
      loader: false,
    };
  }

  componentDidMount() {
    this.fetchTicktMessage();
  }

  async fetchTicktMessage() {
    try {
      let reqData = {
        ticketId: this.props.match.params.id,
      };
      this.setState({ loader: true });
      const { status, loading, result } = await getMessage(reqData);
      console.log("cancelMarketOrder", result);
      this.setState({ loader: loading });
      if (status == "success") {
        this.setState({ records: result, msgConversation: result.reply });
      }
    } catch (err) { }
  }

  handleChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let formData = { ...this.state.formValue, ...{ [name]: value } };
    this.setState({ formValue: formData });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { formValue, records } = this.state;
    if (!formValue.message) {
      toastAlert("error", "Please enter message", "replyMsg");
      return;
    }
    this.setState({ loader: true });
    let reqData = {
      ticketId: records._id,
      receiverId: records.userId,
      message: formValue.message,
    };
    try {
      const { status, loading, message, error, result } = await replyMsg(
        reqData
      );
      this.setState({ loader: loading });
      if (status == "success") {
        toastAlert("success", message, "replyMsg");
        this.setState({
          formValue: initialFormValue,
          msgConversation: result.reply,
        });
      } else {
        toastAlert("error", message, "replyMsg");
      }
    } catch (err) {
      this.setState({ loader: false });
    }
  };

  handleClose = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure to close the ticket?") == true) {
      const { formValue, records } = this.state;

      this.setState({ loader: true });

      let reqData = {
        ticketId: records._id,
        receiverId: records.userId,
      };
      try {
        const { status, loading, message, error, result } = await closeTicket(
          reqData
        );
        this.setState({ loader: loading });
        console.log(
          "closeTicketcloseTicket",
          status,
          loading,
          message,
          error,
          result
        );
        if (status == "success") {
          toastAlert("success", message, "replyMsg");
          this.props.history.push("/support");
        } else {
          toastAlert("error", message, "replyMsg");
        }
      } catch (err) {
        this.setState({ loader: false });
      }
    }
  };

  render() {
    const { errors, records, msgConversation, loader } = this.state;
    const { message } = this.state.formValue;

    return (
      <div id="page-content-wrapper" className="support_tic_wrap">
        <div className="container-fluid">
          <h3 className="mt-2 text-secondary">Support Ticket</h3>

          {/* <div className="row rounded-lg overflow-hidden shadow"> */}
          {/* Chat Box*/}
          <div className="px-4 py-5 chat-box bg-white">
            {!isEmpty(records) &&
              msgConversation &&
              msgConversation.length > 0 &&
              msgConversation.map((item, key) => {
                console.log("itemitemitemitemitem", item);
                if (item.senderId == records.adminId) {
                  return (
                    <div className="media w-50 ml-auto mb-3">
                      <div className="media-body">
                        <div className="bg-primary rounded py-2 px-3 mb-2">
                          <p className="text-small mb-0 text-white">
                            {item.message}
                          </p>
                        </div>
                        <p className="small text-muted">
                          {moment(item.createdAt).format("DD-MM-YYYY")}
                        </p>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="media w-50 mb-3">
                      <img
                        src="https://bootstrapious.com/i/snippets/sn-chat/avatar.svg"
                        alt="user"
                        width={50}
                        className="rounded-circle"
                      />
                      <div className="media-body ml-3">
                        <div className="bg-light rounded py-2 px-3 mb-2">
                          <p className="text-small mb-0 text-muted">
                            {item.message}
                          </p>
                          {item && item.file && (
                            <p className="text-small mb-0 text-muted">
                              <a
                                href={
                                  config.API_URL +
                                  "/images/support/" +
                                  item.file
                                }
                                target="_blank"
                              >
                                {item.file} View file
                              </a>
                            </p>
                          )}
                        </div>
                        <p className="small text-muted">
                          {moment(item.createdAt).format("DD-MM-YYYY")}
                        </p>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
          {
            records && records.status == "open" &&
            <form className="bg-light">
              <div className="input-group">
                <textarea
                  type="text"
                  placeholder="Type a message"
                  aria-describedby="button-addon2"
                  className="form-control rounded-0 border-0 py-4 bg-light"
                  name="message"
                  value={message}
                  onChange={this.handleChange}
                />
                <div className="input-group-append">
                  <div className="support_tic_file">
                    <input type="file" />
                    {/* <button className="btn btn-link">
                    <i className="fa fa-link" aria-hidden="true" />
                  </button> */}
                  </div>
                  <button
                    onClick={this.handleSubmit}
                    disabled={loader}
                    className="btn btn-link"
                  >
                    {" "}
                    <i className="fa fa-paper-plane" />
                  </button>
                  <button
                    onClick={this.handleClose}
                    disabled={loader}
                    className="btn btn-link"
                  >
                    {" "}
                    <i className="fa fa-times" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </form>}

          {/* <small>Allowed Only types jpg, jpeg, png, pdf or mp4</small> */}
          {/* <br /> */}
          {/* </div> */}
        </div>
      </div>
    );
  }
}

export default withRouter(SupportReply);
