import React, { Component, Fragment } from "react";
import ReactDatatable from '@ashvin27/react-datatable';
import moment from 'moment'
// import component
import CoinWithdrawModal from '../partials/CoinWithdrawModal';
import FiatWithdrawModal from '../partials/FiatWithdrawModal';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
// import action
import { getWithdrawList } from '../../actions/walletAction'
import { Link } from "react-router-dom";
class Withdraw extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "createdAt",
                text: "Created date",
                className: "created_date",
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <p>{moment(record.createdAt).format("DD-MM-YYYY HH:mm:ss")}</p>
                        </Fragment>
                    )
                }
            },
            {
                key: "toaddress",
                text: "To Address/Account",
                className: "toaddress",
                align: "left",
                sortable: false,
                width: 200,
                cell: record => {
                    if (record.paymentType == 'fiat_withdraw') {
                        return record.bankDetail && record.bankDetail.accountNo ? record.bankDetail.accountNo : ''
                    } if (record.paymentType == 'coin_withdraw') {
                        return record.toaddress
                    }
                }
            },
            {
                key: "currencySymbol",
                text: "Currency",
                className: "currency",
                align: "left",
                sortable: false
            },
            {
                key: "paymentType",
                text: "Payment Type",
                className: "currency",
                align: "left",
                sortable: false
            },
            {
                key: "email",
                text: "Email",
                className: "user_id",
                align: "left",
                sortable: false,
                width: 200,
            },
            {
                key: "phoneNo",
                text: "Phone No",
                className: "currency",
                align: "left",
                sortable: false
            },
            {
                key: "actualAmount",
                text: "Transfer Amount",
                className: "amount",
                align: "left",
                sortable: false
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: false
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    if (record.paymentType == "coin_withdraw") {
                        return (
                            <Fragment>
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => this.setState({ 'coinFormModal': true, 'withdrawRecord': record })}
                                    style={{ marginRight: '5px' }}>
                                    <i className="fa fa-check"></i>
                                </button>
                            </Fragment>
                        );
                    } else if (record.paymentType == "fiat_withdraw") {
                        return (
                            <Fragment>
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => this.setState({ 'fiatFormModal': true, 'withdrawRecord': record })}
                                    style={{ marginRight: '5px' }}>
                                    <i className="fa fa-check"></i>
                                </button>
                            </Fragment>
                        );
                    }
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 500],
            filename: "Withdraw",
            no_data_text: 'No Withdraw found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            search: '',
            page: 1,
            limit: 10,
            count: 0,
            loader: false,
            coinFormModal: false,
            fiatFormModal: false,
            withdrawRecord: {}
        };

        this.fetchWithdraw = this.fetchWithdraw.bind(this);
        this.refetchWithdraw = this.refetchWithdraw.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.handleCloseCoinForm = this.handleCloseCoinForm.bind(this);
        this.handleCloseFiatForm = this.handleCloseFiatForm.bind(this);
    }

    componentDidMount() {
        const { page, limit } = this.state;
        let reqData = {
            page,
            limit
        }
        this.fetchWithdraw(reqData)
    };

    async fetchWithdraw(reqData) {
        this.setState({ "loader": true })
        try {
            const { status, loading, result } = await getWithdrawList(reqData);
            this.setState({ "loader": loading })
            if (status == 'success') {
                this.setState({ "count": result.count, 'records': result.data })
            }
        } catch (err) { }
    }

    handlePagination(index) {
        let reqData = {
            page: index.page_number,
            limit: index.page_size,
            search: index.filter_value
        }
        this.fetchWithdraw(reqData);
        this.setState({ page: index.page_number, limit: index.page_size, search: index.filter_value })
    }

    handleCloseCoinForm() {
        this.setState({ coinFormModal: false })
    }

    handleCloseFiatForm() {
        this.setState({ fiatFormModal: false })
    }

    refetchWithdraw() {
        const { page, limit } = this.state;
        let reqData = {
            page,
            limit
        }
        this.fetchWithdraw(reqData)
    }

    render() {
        const { loader, count, records, coinFormModal, fiatFormModal, withdrawRecord } = this.state;

        return (
            <>
                <CoinWithdrawModal
                    isShow={coinFormModal}
                    onHide={this.handleCloseCoinForm}
                    record={withdrawRecord}
                    fetchData={this.refetchWithdraw}
                />
                <FiatWithdrawModal
                    isShow={fiatFormModal}
                    onHide={this.handleCloseFiatForm}
                    record={withdrawRecord}
                    fetchData={this.refetchWithdraw}
                />


                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <Link to="/bulkWithdraw">
                            <button

                                className="btn btn-outline-primary float-right mt-3 mr-2"
                            ><FontAwesomeIcon icon={faPlus} /> Bulk Withdraw
                            </button>
                        </Link>
                        <h3 className="mt-2 text-secondary">Withdraw List</h3>
                        <ReactDatatable
                            config={this.config}
                            records={records}
                            columns={this.columns}
                            dynamic={true}
                            total_record={count}
                            loading={loader}
                            onChange={this.handlePagination}
                        />
                    </div>
                </div>
            </>

        );
    }

}

export default withRouter(Withdraw);