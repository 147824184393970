import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { withRouter } from 'react-router-dom';
import keys from "../../actions/config";
const url = keys.baseUrl;

const Layout = ({ Component, ...props }) => {



    return (
        <>
            <Navbar />

            <div className="d-flex" id="wrapper">
                <Sidebar />

                <Component {...props} />
            </div>
        </>
    );


}



export default withRouter(Layout);